import { RecipeView } from '../recipes';

export const ORDER_SPACING = 5000000;

export interface Plan {
  groups: PlanGroup[];
}

export interface PlanGroup {
  dayOffset: number;
  date?: Date;
  heading: string;
  subHeading?: string;
  meals: MealView[];
  divider?: boolean;
}

export interface RecipeState {
  scale: string;
  checkedIngredients: { [key: number]: boolean };
  checkedInstructions: { [key: number]: boolean };
}

export interface MealView {
  id: string;
  name?: string;
  displayName: string;
  recipeIds: string[];
  recipes: RecipeView[];
  recipeState: { [key: string]: RecipeState };
  extras: string[];
  planDate?: Date;
  planOrder: number;
  addedToList: boolean;
  dateMade?: Date;
}