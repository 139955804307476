import { AddOutlined, CheckOutlined, CloseOutlined } from '@mui/icons-material';
import { AppBar, Avatar, Box, Button, Collapse, Dialog, DialogContent, Fab, Grow, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Slide, TextField, Toolbar, Tooltip, Typography, Zoom, useMediaQuery, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { forwardRef, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { PageWrapper } from '../components/PageWrapper';
import { ScrollWrapper } from '../components/ScrollWrapper';
import { deleteLeftover, saveLeftover } from '../leftovers';
import { Leftover } from '../leftovers/leftover';
import { getId } from '../shared/identifiers';
import { ReactComponent as NoLeftovers } from '../images/NoLeftovers.svg';

function getDayDifference(first: Date, second: Date): number {
  second.setHours(0, 0, 0, 0);
  first.setHours(0, 0, 0, 0);

  const diffTime = second.getTime() - first.getTime();
  return Math.floor(diffTime / (1000 * 60 * 60 * 24));
}

function getAvatarLabel(dateMade: Date): string {
  const dayDifference = getDayDifference(dateMade, new Date());

  if (dayDifference < 0) return '?';
  if (dayDifference > 99) return '?';

  return `${dayDifference}`;
}

const GrowTransition = forwardRef(function Transition(props: any, ref) {
  return <Grow ref={ref} {...props}>{props.children}</Grow>;
});

const SlideTransition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props}>{props.children}</Slide>;
});

interface LeftoversPageProps {
  leftovers: Leftover[];
}

export default function LeftoversPage({ leftovers }: LeftoversPageProps) {
  const history = useHistory();
  const individualMatch = useRouteMatch<{ id: string }>('/leftovers/:id');
  const id = individualMatch?.params?.id;

  const items = leftovers;

  const sortedItems = [...items];
  sortedItems.sort((a, b) => a.dateMade.getTime() - b.dateMade.getTime());

  const deleteItem = (item: Leftover) => {
    deleteLeftover(item.id);
  };

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('md'));

  function getAvatarColor(dateMade: Date): string {
    const dayDifference = getDayDifference(dateMade, new Date());

    if (dayDifference < 0) return theme.palette.secondary.main;
    if (dayDifference < 4) return theme.palette.success.main;
    if (dayDifference < 7) return theme.palette.warning.main;

    return theme.palette.error.main;
  }

  const [newName, setNewName] = useState<string | undefined>(undefined);
  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [newDate, setNewDate] = useState<Date>(new Date());

  useEffect(() => {
    // Set edit variables when navigating directly to URL.
    if (leftovers.length && id && id !== 'new' && newName === undefined) {
      const leftover = leftovers.find(l => l.id === id);
      if (leftover) {
        setNewName(leftover.name);
        setNewDate(leftover.dateMade);
      }
    }
  }, [leftovers, id, newName]);

  async function submitEdit() {
    if (!(newName ?? '').trim()) {
      setNameError('Name is required');
      return;
    }

    if (id === 'new') {
      await saveLeftover({
        id: getId(),
        name: newName ?? '',
        dateMade: newDate,
      });

      clearValues();
    } else {
      const item = items.find(i => i.id === id);
      if (!item) {
        clearValues();
        return;
      }

      await saveLeftover({
        id: item.id,
        name: newName ?? '',
        dateMade: newDate,
      });

      clearValues();
    }
  }

  function clearValues() {
    setNewName('');
    setNameError(undefined);
    setNewDate(new Date());
    // TODO: This adds /leftovers to the history stack twice
    history.replace('/leftovers');
  }

  function startEdit(item: Leftover) {
    setNewName(item.name);
    setNameError(undefined);
    setNewDate(item.dateMade);
    history.push(`/leftovers/${item.id}`);
  }

  return (
    <PageWrapper>
      {isPhone &&
        <AppBar position="sticky" color="secondary">
          <Toolbar>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              Leftovers
            </Typography>
          </Toolbar>
        </AppBar>
      }
      <Dialog
        fullScreen={isPhone}
        maxWidth="xs"
        fullWidth
        open={!!id}
        onClose={clearValues}
        TransitionComponent={isPhone ? SlideTransition : GrowTransition}
      >
        <AppBar position="sticky" color="secondary">
          <Toolbar>
            <IconButton edge="start" color="inherit" sx={{ mr: 1 }} onClick={clearValues}>
              <CloseOutlined />
            </IconButton>
            <Typography variant="h5" component="div">
              Edit Leftover
            </Typography>
            <Box sx={{ flex: '1 1 auto' }}></Box>
            <Button variant="text" color="primary" onClick={submitEdit}>Save</Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            error={!!nameError}
            helperText={nameError}
            value={newName}
            onChange={e => { setNewName(e.target.value); setNameError(undefined); }}
            label="Title"
            fullWidth
            autoFocus
          />
          <DatePicker
            value={newDate}
            onChange={e => setNewDate(e || new Date())}
            label="Date Made"
            renderInput={(params) => <TextField size="small" margin="normal" {...params} />}
          ></DatePicker>
        </DialogContent>
      </Dialog>
      <ScrollWrapper maxWidth="sm" disableGutters padFab centerItems={!sortedItems.length}>
        {isPhone &&
          <Zoom in unmountOnExit>
            <Fab
              color="primary" variant="extended"
              sx={{ position: 'fixed', bottom: 70, right: 15, zIndex: 1050 }}
              onClick={() => history.push("/leftovers/new")}
            >
              <AddOutlined sx={{ mr: 1 }} />
              Create
            </Fab>
          </Zoom>
        }
        {sortedItems.length ?
          <List>
            <TransitionGroup>
              {sortedItems.map(item =>
                <Collapse key={`items-${item.id}`}>
                  <ListItem
                    // TODO: Add confirmation dialog to delete
                    secondaryAction={<IconButton edge="end" color="success" onClick={() => deleteItem(item)}><CheckOutlined /></IconButton>}
                    disablePadding
                  >
                    <ListItemButton onClick={() => startEdit(item)}>
                      <Tooltip title={`Made ${item.dateMade.toLocaleDateString()}`}>
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              borderWidth: '1px',
                              borderStyle: 'solid',
                              borderColor: getAvatarColor(item.dateMade),
                              color: getAvatarColor(item.dateMade),
                              bgcolor: 'transparent'
                            }}
                          >{getAvatarLabel(item.dateMade)}</Avatar>
                        </ListItemAvatar>
                      </Tooltip>
                      <ListItemText primary={item.name} secondary={item.mealName || 'Added Manually'} />
                    </ListItemButton>
                  </ListItem>
                </Collapse>
              )}
            </TransitionGroup>
          </List>
          :
          <Box sx={{ textAlign: 'center', marginLeft: 1, marginRight: 1 }}>
            <NoLeftovers />
            <Typography gutterBottom variant="h2" component="div">
              <Typography variant="h2" component="span" sx={{ color: theme => theme.palette.primary.main, fontWeight: '400' }}>Goodbye</Typography> to stench
            </Typography>
            <Typography gutterBottom variant="body2" color="text.secondary">
              Leftovers from meals will show up here.
            </Typography>
          </Box>
        }
      </ScrollWrapper>
    </PageWrapper>
  );
}