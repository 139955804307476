import { Box, Container, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { EmailAuthProvider, getAuth } from 'firebase/auth';
import { auth } from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import "./Login.css";
import { ReactComponent as MacLogo } from '../images/MacLogo.svg';

const uiConfig = {
  credentialHelper: auth.CredentialHelper.NONE,
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
  ],
};

export default function Login() {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const isPhone = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    const ui = auth.AuthUI.getInstance() ?? new auth.AuthUI(getAuth());
    ui.start('#firebaseui-auth-container', uiConfig);
  }, []);

  useEffect(() =>
    getAuth().onAuthStateChanged((user: any) => {
      setUser(user);
      setLoading(false);
    })
  );

  return (user && !isLoading) ?
    <Redirect to="/meals" /> :
    <Grid container direction={isPhone ? 'column' : 'row'} sx={{ height: '100%' }}>
      <Grid item
        xs={5} md={7} xl={8}
        sx={{
          background: theme => theme.palette.mode === 'dark' ? '#262530' : theme.palette.secondary.main,
          display: 'grid',
          placeContent: 'center',
          justifyItems: 'center',
        }}
      >
        <Box sx={{ borderRadius: '50%', background: theme => theme.palette.background.paper, marginBottom: 1 }}>
          <MacLogo />
          {/* <img src="/maclogo.svg" alt="Appetiz logo"></img> */}
        </Box>
        <Typography variant="h1">appetīz</Typography>
        <Typography variant="subtitle1">meal planner and recipe tracker</Typography>
      </Grid>
      <Grid item
        xs={7} md={5} xl={4}
      >
        <Container
          maxWidth="sm"
          sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
          <div style={{ display: (isLoading ? 'none' : 'block') }} id="firebaseui-auth-container"></div>
        </Container>
      </Grid>
    </Grid>
}
