import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow, Slide, useMediaQuery, useTheme } from '@mui/material';
import { forwardRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import RecipeEdit from '../../components/RecipeEdit';
import RecipeView from '../../components/RecipeView';
import { deleteRecipe, RecipeView as Recipe, saveRecipe } from '../../recipes';
import { useScale } from '../../shared/hooks/useScale';
import { useToggleList } from '../../shared/hooks/useToggleList';

export interface RecipeDetailProps {
  recipe?: Recipe;
  recipes: Recipe[];
  tags: string[];
  active: boolean;
  onClose: () => void;
}

const GrowTransition = forwardRef(function Transition(props: any, ref) {
  return <Grow ref={ref} {...props}>{props.children}</Grow>;
});

const SlideTransition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props}>{props.children}</Slide>;
});

export default function RecipeDetail({ recipe, recipes, tags, active, onClose }: RecipeDetailProps) {
  const recipeInit: Recipe = {
    id: '',
    name: '',
    parsedIngredients: [],
    parsedInstructions: [],
    ingredients: '',
    instructions: '',
    notes: '',
    yield: '',
    yieldLabel: '',
    prepTime: '',
    prepUnit: 1,
    cookTime: '',
    cookUnit: 1,
    extraTime: '',
    extraUnit: 1,
    author: '',
    source: '',
    temperature: 0,
    isMainDish: false,
    tags: [],
    history: [],
    linkedRecipeIds: [],
    sides: [],
  };

  const currRecipe = recipe ?? recipeInit;
  const history = useHistory();

  const [editing, setEditing] = useState(false);
  const [closing, setClosing] = useState(false);
  const [cancelling, setCancelling] = useState(false);
  const [modified, setModified] = useState(false);

  function save(recipe: Recipe) {
    saveRecipe(recipe).then(() => setEditing(false));
  }

  function deleteInternal() {
    deleteRecipe(currRecipe.id).then(() => history.push('/recipes'));
  }

  function beginDiscardChanges() {
    if (modified) {
      setCancelling(true);
    } else {
      confirmExit();
    }
  }

  function beginCloseModal() {
    if (editing && modified) {
      setClosing(true);
    } else {
      onClose();
    }
  }

  function confirmExit() {
    setEditing(false);
    setModified(false);

    if (closing) {
      setClosing(false);
      onClose();
    }

    if (cancelling) {
      setCancelling(false);
    }
  }

  function cancelExit() {
    setCancelling(false);
    setClosing(false);
  }

  const [isIngredientChecked, toggleIngredient] = useToggleList(currRecipe.parsedIngredients.length);
  const [isInstructionChecked, toggleInstruction] = useToggleList(currRecipe.parsedInstructions.length);
  const [standardScale, customScale, currentScale, setCurrentScale] = useScale();

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <Dialog
        fullScreen={!isDesktop}
        maxWidth="xl"
        fullWidth
        open={active}
        onClose={beginCloseModal}
        TransitionComponent={isPhone ? SlideTransition : GrowTransition}
        sx={{ '.MuiDialog-paper': { height: '100%' } }}
      >
        {editing ?
          <RecipeEdit
            recipe={currRecipe}
            recipes={recipes}
            tags={tags}
            save={save}
            deleteRecipe={deleteInternal}
            cancelEditing={beginDiscardChanges}
            changed={setModified}
          /> :
          <RecipeView
            recipe={currRecipe}
            recipes={recipes}
            startEditing={() => setEditing(true)}
            isIngredientChecked={isIngredientChecked}
            toggleIngredient={toggleIngredient}
            isInstructionChecked={isInstructionChecked}
            toggleInstruction={toggleInstruction}
            standardScale={standardScale}
            customScale={customScale}
            currentScale={currentScale}
            setCurrentScale={setCurrentScale}
          />}
      </Dialog>
      <Dialog
        open={cancelling || closing}
        onClose={cancelExit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to stop editing?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Any changes will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelExit} autoFocus>Cancel</Button>
          <Button color="error" onClick={confirmExit}>Discard</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

