let wakeLock: WakeLockSentinel | null = null;

export function setWakeLock(): () => void {
  if ('wakeLock' in navigator && !wakeLock) {
    navigator.wakeLock.request('screen').then(s => wakeLock = s);
  }

  const listener = async () => {
    if (wakeLock && document.visibilityState === 'visible') {
      wakeLock = await navigator.wakeLock.request('screen');
    }
  };
  document.addEventListener('visibilitychange', listener);

  return () => {
    wakeLock?.release().then(() => wakeLock = null);
    document.removeEventListener('visibilitychange', listener);
  };
}
