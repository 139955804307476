import { DBSchema, IDBPDatabase, openDB } from 'idb';
import { FirestoreList } from '../lists/firestore';
import { FirestoreMeal } from '../meals/firestore';
import { FirestoreRecipe } from '../recipes/firestore';
import { FirestoreWorkspace } from '../workspaces/firestore';

export enum Stores {
  WORKSPACES = 'workspaces',
  RECIPES = 'recipes',
  MEALS = 'meals',
  LISTS = 'lists',
};

interface MealPlannerDBV1 extends DBSchema {
  recipes: {
    value: FirestoreRecipe;
    key: string;
    indexes: { 'by-workspace': string };
  },
}

interface MealPlannerDBV2 extends DBSchema {
  recipes: {
    value: FirestoreRecipe;
    key: string;
    indexes: { 'by-workspace': string };
  },
  meals: {
    value: FirestoreMeal;
    key: string;
    indexes: { 'by-workspace': string };
  }
}

interface MealPlannerDBV3 extends DBSchema {
  recipes: {
    value: FirestoreRecipe;
    key: string;
    indexes: { 'by-workspace': string };
  },
  meals: {
    value: FirestoreMeal;
    key: string;
    indexes: { 'by-workspace': string };
  },
  workspaces: {
    value: FirestoreWorkspace;
    key: string;
  },
}

interface MealPlannerDBV4 extends DBSchema {
  recipes: {
    value: FirestoreRecipe;
    key: string;
    indexes: { 'by-workspace': string };
  },
  meals: {
    value: FirestoreMeal;
    key: string;
    indexes: { 'by-workspace': string };
  },
  workspaces: {
    value: FirestoreWorkspace;
    key: string;
  },
  lists: {
    value: FirestoreList;
    key: string;
    indexes: { 'by-workspace': string };
  },
}

export type LocalDb = IDBPDatabase<MealPlannerDBV4>;

export const db = openDB<MealPlannerDBV4>('mealPlanner', 4, {
  upgrade(db, oldVersion) {
    const v1 = db as unknown as IDBPDatabase<MealPlannerDBV1>;
    const v2 = db as unknown as IDBPDatabase<MealPlannerDBV2>;
    const v3 = db as unknown as IDBPDatabase<MealPlannerDBV3>;

    if (oldVersion < 1) {
      const recipeStore = v1.createObjectStore(Stores.RECIPES);
      recipeStore.createIndex('by-workspace', 'workspaceId');
    }

    if (oldVersion < 2) {
      const mealStore = v2.createObjectStore(Stores.MEALS);
      mealStore.createIndex('by-workspace', 'workspaceId');
    }

    if (oldVersion < 3) {
      v3.createObjectStore(Stores.WORKSPACES);
    }

    if (oldVersion < 4) {
      const listStore = db.createObjectStore(Stores.LISTS);
      listStore.createIndex('by-workspace', 'workspaceId');
    }
  }
});