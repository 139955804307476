import { useState } from 'react';
import { Fraction } from '../../parser/amounts';
import { parseFraction } from '../../parser/parse-fraction';
import { prettifyFraction } from '../../parser/test-helpers';

export interface Scale {
  fraction: Fraction;
  display: string;
  isActive: boolean;
}

const defaultScales: Fraction[] = [
  [1, 4],
  [1, 2],
  [1, 1],
  [2, 1],
  [3, 1],
];

export function useScale(): [Scale[], Scale, Scale, (scale: string) => void] {
  const [currentScale, setCurrentScale] = useState<Fraction>([1, 1]);

  const defaultOptions: Scale[] = defaultScales.map(f => ({
    fraction: f,
    display: prettifyFraction(f),
    isActive: currentScale[0] === f[0] && currentScale[1] === f[1],
  }));

  const setScale = (scale: string) => {
    const fraction = parseFraction(scale);

    if (fraction[0] === 0 || fraction[1] === 0) {
      setCurrentScale([1, 1]);
    } else {
      setCurrentScale(fraction);
    }
  };

  const isCustom = !defaultOptions.some(x => x.isActive);

  const customScale = {
    fraction: currentScale,
    display: isCustom ? prettifyFraction(currentScale) : '...',
    isActive: isCustom,
  };

  const scale = {
    fraction: currentScale,
    display: prettifyFraction(currentScale),
    isActive: true,
  };

  return [defaultOptions, customScale, scale, setScale];
}