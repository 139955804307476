import { Box, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { PageWrapper } from '../components/PageWrapper';
import { ScrollWrapper } from '../components/ScrollWrapper';
import { AstNode } from '../parser/beta/ast';
import { compile } from '../parser/beta/compiler';
import { NormalizingTransformer } from '../parser/beta/normalizing-transformer';
import { StringEmitter } from '../parser/beta/string-emitter';
import { IngredientEmitter } from '../parser/beta/ingredient-emitter';
import IngredientView from '../components/IngredientView';

function TreeNode({ node, padding }: { node: AstNode | undefined, padding: number }): JSX.Element {
  if (!node) return <></>;

  if ('children' in node) {
    return <>
      <Box ml={padding}>{node.type}</Box>
      {node.children.map(c => <TreeNode node={c} padding={padding + 2} />)}
    </>;
  }

  return <Box ml={padding}>{node.type}: <b>{node.value}</b></Box>;
}

export function CompilerPlayground() {
  const [text, setText] = useState('');

  const compiled = compile(text, [new NormalizingTransformer()], { emit(node) { return node; }, default() { return undefined; } });
  const pretty = compile(text, [new NormalizingTransformer()], new StringEmitter());
  const ingredient = compile(text, [new NormalizingTransformer()], new IngredientEmitter());

  return (
    <PageWrapper>
      <ScrollWrapper maxWidth="md" padTop>
        <Stack gap={2}>
          <TextField size="small" value={text} onChange={e => setText(e.target.value)} />
          <Box>
            <TreeNode node={compiled} padding={0} />
          </Box>
          {pretty}
          <IngredientView ingredient={ingredient} scale={[1, 1]} />
        </Stack>
      </ScrollWrapper>
    </PageWrapper>
  );
}