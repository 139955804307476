import { CloseOutlined } from '@mui/icons-material';
import { AppBar, Box, Button, Checkbox, Dialog, DialogContent, Divider, FormControlLabel, FormGroup, Grow, IconButton, Slide, TextField, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { forwardRef, useState } from 'react';
import { saveLeftover } from '../../leftovers';
import { deleteMeal, MealView } from '../../meals';
import { addHistory, RecipeView } from '../../recipes';
import { getId } from '../../shared/identifiers';

export interface CompleteMealProps {
  onClose: () => void;
  deletingMeal?: MealView;
  recipes: RecipeView[];
}

const GrowTransition = forwardRef(function Transition(props: any, ref) {
  return <Grow ref={ref} {...props}>{props.children}</Grow>;
});

const SlideTransition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props}>{props.children}</Slide>;
});

export default function MealPlan({ onClose, deletingMeal, recipes }: CompleteMealProps) {
  const [dateMade, setDateMade] = useState<Date | undefined>();
  const [hadLeftovers, setHadLeftovers] = useState(false);
  const [recipeLeftovers, setRecipeLeftovers] = useState<string[]>([]);
  const [extraLeftovers, setExtraLeftovers] = useState<string[]>([]);

  function deleteMealInternal() {
    if (!deletingMeal?.id) return;

    deleteMeal(deletingMeal.id).then(onClose);
  }

  function toggleMealLeftover(id: string) {
    if (mealLeftoverChecked(id)) {
      const leftovers = [...recipeLeftovers];
      leftovers.splice(recipeLeftovers.indexOf(id), 1);
      setRecipeLeftovers(leftovers);
    } else {
      const leftovers = [...recipeLeftovers];
      leftovers.push(id);
      setRecipeLeftovers(leftovers);
    }
  }

  function toggleExtraLeftover(name: string) {
    if (extraLeftoverChecked(name)) {
      const leftovers = [...extraLeftovers];
      leftovers.splice(extraLeftovers.indexOf(name), 1);
      setExtraLeftovers(leftovers);
    } else {
      const leftovers = [...extraLeftovers];
      leftovers.push(name);
      setExtraLeftovers(leftovers);
    }
  }

  function mealLeftoverChecked(id: string): boolean {
    return recipeLeftovers.includes(id);
  }

  function extraLeftoverChecked(name: string): boolean {
    return extraLeftovers.includes(name);
  }

  // TODO: Consider moving all this logic into a pub/sub pattern.
  async function completeMealInternal() {
    if (!deletingMeal) {
      return;
    }

    await deleteMeal(deletingMeal.id);

    const date = dateMade ?? deletingMeal.planDate ?? new Date();

    for (const id of recipeLeftovers) {
      const recipe = recipes.find(r => r.id === id);
      if (!recipe) continue;

      await saveLeftover({
        id: getId(),
        name: recipe.name,
        mealName: deletingMeal.displayName,
        dateMade: date,
      });
    }

    for (const extra of extraLeftovers) {
      await saveLeftover({
        id: getId(),
        name: extra,
        mealName: deletingMeal.displayName,
        dateMade: date,
      });
    }

    if (hadLeftovers) {
      await saveLeftover({
        id: getId(),
        name: deletingMeal.displayName,
        mealName: deletingMeal.displayName,
        dateMade: date,
      });
    }

    for (const recipeId of deletingMeal.recipeIds) {
      await addHistory(recipeId, {
        date,
        meal: deletingMeal.displayName,
        hadLeftovers: recipeLeftovers.includes(recipeId),
        scale: deletingMeal.recipeState[recipeId]?.scale ?? '1',
      });
    }

    setDateMade(undefined);
    setHadLeftovers(false);
    setRecipeLeftovers([]);
    setExtraLeftovers([]);

    onClose();
  }

  const isPhone = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <Dialog
      open={!!deletingMeal}
      onClose={onClose}
      fullScreen={isPhone}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      TransitionComponent={isPhone ? SlideTransition : GrowTransition}
    >
      <AppBar position="sticky" color="secondary">
        <Toolbar>
          <IconButton edge="start" color="inherit" sx={{ mr: 1 }} onClick={onClose}>
            <CloseOutlined />
          </IconButton>
          <Typography variant="h5" component="div">
            {deletingMeal?.displayName}
          </Typography>
          <Box sx={{ flex: '1 1 auto' }}></Box>
          <Button variant="text" color="primary" onClick={completeMealInternal}>Complete</Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        {!deletingMeal?.dateMade &&
          <>
            <DatePicker
              value={dateMade ?? deletingMeal?.planDate ?? null}
              onChange={date => setDateMade(date ?? new Date())}
              label="Date Made"
              renderInput={(params) => <TextField size="small" {...params} />}
            ></DatePicker>
            <Divider sx={{ mt: 3, mb: 1 }}>Leftovers</Divider>
            {!(deletingMeal?.recipeIds.length ?? true) && !(deletingMeal?.extras.length ?? true) &&

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={hadLeftovers}
                      onChange={(_, value) => setHadLeftovers(value)}
                    />
                  }
                  label="Meal had leftovers"
                />
              </FormGroup>
            }
            <FormGroup>
              {deletingMeal?.recipeIds.map(id => {
                const recipe = recipes.find(r => r.id === id);
                return recipe ?
                  <FormControlLabel
                    key={`leftovers-${id}`}
                    control={
                      <Checkbox
                        checked={mealLeftoverChecked(id)}
                        onChange={() => toggleMealLeftover(id)}
                      />
                    }
                    label={recipe.name}
                  />
                  : <></>;
              })}
            </FormGroup>
            <FormGroup>
              {deletingMeal?.extras.map((extra, index) =>
                <FormControlLabel
                  key={`leftovers-extra-${index}`}
                  control={
                    <Checkbox
                      checked={extraLeftoverChecked(extra)}
                      onChange={() => toggleExtraLeftover(extra)}
                    />
                  }
                  label={extra}
                />
              )}
            </FormGroup>
          </>
        }
        {!deletingMeal?.dateMade &&
          <Button
            variant="outlined"
            color="error"
            onClick={deleteMealInternal}
            sx={{ mt: 3 }}
          >
            Delete Meal
          </Button>
        }
      </DialogContent>
    </Dialog>
  );
}