import { LocalDb, Stores } from "../shared/database";
import { EventBusProducer, Events } from '../shared/events';
import { FirestoreMeal, MealFirestoreClient } from "./firestore";
import { MealView, RecipeState } from "./meal";


export class MealRepository {
  constructor(
    private client: MealFirestoreClient,
    private producer: EventBusProducer,
    private db: Promise<LocalDb>,
  ) { }

  public async setLocal(meals: FirestoreMeal[]): Promise<void> {
    if (!meals.length) {
      return;
    }

    const tx = (await this.db).transaction(Stores.MEALS, 'readwrite');
    await Promise.all([
      ...meals.map(m => tx.store.put(m, m.id)),
      tx.done,
    ]);
    this.producer.emit(Events.PLAN_CHANGED);
  }

  public async deleteLocal(mealIds: string[]): Promise<void> {
    if (!mealIds.length) {
      return;
    }

    const tx = (await this.db).transaction(Stores.MEALS, 'readwrite');
    await Promise.all([
      ...mealIds.map(id => tx.store.delete(id)),
      tx.done,
    ]);
    this.producer.emit(Events.PLAN_CHANGED);
  }

  public async getMeals(workspaceId: string): Promise<MealView[]> {
    const index = (await this.db).transaction(Stores.MEALS).store.index('by-workspace');
    const meals = await index.getAll(workspaceId);
    meals.sort((a, b) => (a.displayName ?? 'Untitled Meal').localeCompare(b.displayName ?? 'Untitled Meal'));
    return meals.map(r => this.mapMealFromDatabase(r));
  }

  public async setDate(workspaceId: string, mealId: string, order: number, dayOffset?: number): Promise<void> {
    return this.client.setDate(workspaceId, mealId, order, dayOffset);
  }

  public async updateIngredient(workspaceId: string, mealId: string, recipeId: string, index: number, checked: boolean): Promise<void> {
    return this.client.updateIngredient(workspaceId, mealId, recipeId, index, checked);
  }

  public async updateInstruction(workspaceId: string, mealId: string, recipeId: string, index: number, checked: boolean): Promise<void> {
    return this.client.updateInstruction(workspaceId, mealId, recipeId, index, checked);
  }

  public async save(workspaceId: string, meal: MealView): Promise<void> {
    const firebaseMeal: FirestoreMeal = {
      id: meal.id,
      name: meal.name,
      displayName: meal.displayName,
      recipeIds: meal.recipeIds,
      recipeState: meal.recipeState,
      extras: meal.extras,
      planOrder: meal.planOrder,
      addedToList: meal.addedToList,
      updated: new Date(),
    };

    if (meal.planDate) {
      firebaseMeal.planDate = meal.planDate;
    }

    if (meal.dateMade) {
      firebaseMeal.dateMade = meal.dateMade;
    }

    this.client.saveMeal(workspaceId, firebaseMeal);
  }

  public delete(workspaceId: string, mealId: string): Promise<void> {
    return this.client.deleteMeal(workspaceId, mealId);
  }

  private mapMealFromDatabase(meal: FirestoreMeal): MealView {
    const state = Object.entries(meal.recipeState ?? {})
      .map<[string, RecipeState]>(([k, v]) => [k, { scale: '1', checkedIngredients: {}, checkedInstructions: {}, ...v } as RecipeState])
      .reduce((a, v) => { a[v[0]] = v[1]; return a; }, {} as { [key: string]: RecipeState });

    return {
      id: meal.id,
      name: meal.name,
      displayName: meal.displayName || meal.name || 'Untitled Meal',
      recipeIds: meal.recipeIds,
      recipes: [],
      recipeState: state,
      extras: meal.extras,
      planDate: meal.planDate,
      planOrder: meal.planOrder,
      addedToList: meal.addedToList ?? false,
      dateMade: meal.dateMade,
    };
  }
}