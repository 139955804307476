import { db as localDb } from "../shared/database";
import { db as firestore } from "../shared/firebase";
import { Unsubscribe, unwrapPromise } from '../shared/subscriptions';
import { MealView, ORDER_SPACING, Plan, PlanGroup } from './meal';
import { MealFirestoreClient } from "./firestore";
import { MealRepository } from "./repository";
import { EventBusConsumer, EventBusProducer, Events } from '../shared/events';
import { ActiveWorkspaceFirestoreClient } from '../workspaces/firestore';
import { getDate, getDayNumber, getDayDiff, getDayName } from '../shared/dates';
export type { MealView } from './meal';

type Callback = (plan: Plan) => void;

const repository = new MealRepository(
  new MealFirestoreClient(firestore),
  new EventBusProducer(),
  localDb,
);

const consumer = new EventBusConsumer();
const producer = new EventBusProducer();

const workspaceClient = new ActiveWorkspaceFirestoreClient(producer);

export function subscribeToPlan(callback: Callback): Unsubscribe {
  return unwrapPromise(cb => subscribeInternal(cb), callback);
}

async function subscribeInternal(callback: Callback): Promise<() => void> {
  return consumer.subscribe(Events.PLAN_CHANGED, async () => {
    const workspaceId = await workspaceClient.getActiveId();

    if (workspaceId) {
      const meals = await repository.getMeals(workspaceId);
      callback({ groups: groupMeals(meals) });
    }
  });
}

function groupMeals(meals: MealView[]) {
  const NUM_DAYS = 8;
  const past: PlanGroup = { dayOffset: -2, heading: 'Past', meals: [], divider: true };
  const next: PlanGroup = { dayOffset: -1, heading: 'Next', meals: [] };
  const days: PlanGroup[] = [...Array(NUM_DAYS).keys()].map(index => ({
    dayOffset: index,
    date: getDate(index),
    heading: index === 0 ? 'Today' : getDayName(index),
    subHeading: getDayNumber(index),
    meals: [],
    divider: index === NUM_DAYS - 1,
  }));

  for (const meal of meals) {
    if (meal.planDate) {
      const dayDiff = getDayDiff(new Date(), meal.planDate);

      if (dayDiff < 0) {
        past.meals.push(meal);
      } else if (dayDiff < NUM_DAYS) {
        days[dayDiff].meals.push(meal);
      } else {
        next.meals.push(meal);
      }
    } else {
      next.meals.push(meal);
    }
  }

  next.meals.sort((a, b) => a.planOrder - b.planOrder);
  for (const day of days) {
    day.meals.sort((a, b) => a.planOrder - b.planOrder);
  }

  if (past.meals.length) {
    return [past, ...days, next];
  }

  return [...days, next];
}

export async function setDate(mealId: string, order: number, dayOffset?: number): Promise<void> {
  const workspaceId = await workspaceClient.getActiveId();

  if (workspaceId) {
    return repository.setDate(workspaceId, mealId, order, dayOffset);
  }
}

export async function saveMeal(meal: MealView): Promise<void> {
  const workspaceId = await workspaceClient.getActiveId();

  if (workspaceId) {
    const planOrder = await getPlanOrder(meal.planDate, workspaceId);
    meal.planOrder = meal.planOrder || planOrder;

    return repository.save(workspaceId, meal);
  }
}

export async function updateIngredient(mealId: string, recipeId: string, index: number, checked: boolean): Promise<void> {
  const workspaceId = await workspaceClient.getActiveId();

  if (workspaceId) {
    return repository.updateIngredient(workspaceId, mealId, recipeId, index, checked);
  }
}

export async function updateInstruction(mealId: string, recipeId: string, index: number, checked: boolean): Promise<void> {
  const workspaceId = await workspaceClient.getActiveId();

  if (workspaceId) {
    return repository.updateInstruction(workspaceId, mealId, recipeId, index, checked);
  }
}

async function getPlanOrder(planDate: Date | undefined, workspaceId: string) {
  const offset = planDate ? getDayDiff(new Date(), planDate) : -1;

  const cachedMeals = await repository.getMeals(workspaceId);
  const groups = groupMeals(cachedMeals);
  const meals = groups.find(g => g.dayOffset === offset)?.meals;
  return (meals?.length ? Math.max(...meals.map(m => m.planOrder)) : 0) + ORDER_SPACING;
}

export async function deleteMeal(mealId: string): Promise<void> {
  const workspaceId = await workspaceClient.getActiveId();

  if (workspaceId) {
    return repository.delete(workspaceId, mealId);
  }
}
