import { Unit, Units } from "./units";

interface UnitConversion {
  from: Unit;
  to: Unit;
  multiplier: number;
  isExact: boolean;
}

export const UnitConversions: UnitConversion[] = [
  {
    from: Units.quart,
    to: Units.gallon,
    multiplier: 4,
    isExact: true,
  },
  {
    from: Units.pint,
    to: Units.quart,
    multiplier: 2,
    isExact: true,
  },
  {
    from: Units.cup,
    to: Units.pint,
    multiplier: 2,
    isExact: true,
  },
  {
    from: Units.tablespoon,
    to: Units.cup,
    multiplier: 16,
    isExact: true,
  },
  {
    from: Units.tablespoon,
    to: Units.fluidOunce,
    multiplier: 2,
    isExact: true,
  },
  {
    from: Units.teaspoon,
    to: Units.tablespoon,
    multiplier: 3,
    isExact: true,
  },
  {
    from: Units.dash,
    to: Units.teaspoon,
    multiplier: 8,
    isExact: true,
  },
  {
    from: Units.pinch,
    to: Units.teaspoon,
    multiplier: 16,
    isExact: true,
  },
  {
    from: Units.ounce,
    to: Units.pound,
    multiplier: 16,
    isExact: true,
  },
];
