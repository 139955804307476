import { InfoOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

export interface HelpTextProps {
  title: string;
}

export function HelpText({ title }: HelpTextProps) {
  return (
    <Tooltip title={title}>
      <InfoOutlined color="disabled" fontSize="inherit" />
    </Tooltip>
  );
}