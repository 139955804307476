import { AddOutlined, CloseOutlined, DragIndicatorOutlined, EventAvailableOutlined, ListAltOutlined, SortByAlphaOutlined } from "@mui/icons-material";
import { AppBar, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, TextareaAutosize, Theme, Toolbar, Tooltip, Typography, Zoom, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { useHistory, useLocation } from 'react-router-dom';
import { AddToListDialog } from '../../components/AddToListDialog';
import { PageWrapper } from '../../components/PageWrapper';
import { ScrollWrapper } from '../../components/ScrollWrapper';
import { ReactComponent as NoListItems } from '../../images/NoListItems.svg';
import { ShoppingList, ShoppingListItem, addToList, saveList } from '../../lists';
import { saveMeal } from '../../meals';
import { MealView, Plan } from '../../meals/meal';
import { RecipeView } from '../../recipes';
import { updateStaples } from '../../staples';
import { StapleSet } from '../../staples/staple';

export interface ShoppingListViewProps {
  lists: ShoppingList[];
  plan: Plan;
  recipes: RecipeView[];
  staples: StapleSet;
}

export default function ShoppingListView({ lists, plan, recipes, staples }: ShoppingListViewProps) {
  const listInit: ShoppingList = {
    id: 'default',
    title: 'Default List',
    items: [],
  };

  const [list, setList] = useState<ShoppingList>(listInit);

  const indexedItems = list.items.map<[ShoppingListItem, number]>((x, i) => [x, i]);
  const uncheckedItems = indexedItems.filter(x => !x[0].checked);
  const checkedItems = indexedItems.filter(x => x[0].checked);

  const sortedItems = [...uncheckedItems];
  sortedItems.sort((a, b) => a[0].title.toLowerCase().localeCompare(b[0].title.toLowerCase()));

  useEffect(() => {
    const defaultList = lists.find(l => l.id === 'default');
    defaultList && setList(defaultList);
  }, [lists]);

  const reorder = (items: ShoppingListItem[], startIndex: number, endIndex: number): ShoppingListItem[] => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd({ source, destination }: DropResult) {
    if (!destination) {
      return;
    }

    const items = reorder(list.items, source.index, destination.index);
    setList({ ...list, items });
    saveList({ ...list, items });
  }

  const handleToggle = (item: ShoppingListItem) => () => {
    const newItem = { ...item, checked: !item.checked };
    const newItems = [...list.items];

    const index = list.items.indexOf(item);
    newItems.splice(index, 1, newItem);

    setList({ ...list, items: newItems });
    saveList({ ...list, items: newItems });
  };

  const addItem = () => {
    const newItems = [...list.items];
    newItems.push({ title: '', checked: false });

    setList({ ...list, items: newItems });
    saveList({ ...list, items: newItems });
    setCursorPosition([list.items.length, 0]);
  };

  const deleteItem = (index: number) => {
    const newItems = [...list.items];
    newItems.splice(index, 1);

    setList({ ...list, items: newItems });
    saveList({ ...list, items: newItems });
    setActiveItem(null);
    setCursorPosition(null);
  };

  const deleteChecked = () => {
    const newItems = list.items.filter(x => !x.checked);

    setList({ ...list, items: newItems });
    saveList({ ...list, items: newItems });
    setActiveItem(null);
    setCursorPosition(null);
    setDeleting(false);
  };

  const [cursorPosition, setCursorPosition] = useState<[number, number] | null>(null);
  const [activeItem, setActiveItem] = useState<number | null>(null);
  const [hoverItem, setHoverItem] = useState<number | null>(null);

  const handleFocus = (index: number, isFocused: boolean) => {
    if (isFocused) {
      setActiveItem(index);
    } else if (activeItem === index) {
      setTimeout(() => setActiveItem(null), 200);
    }

    if (!isFocused) {
      saveList(list);
    }
  }

  const handleHover = (index: number, isHovered: boolean) => {
    if (isHovered) {
      setHoverItem(index);
    } else if (hoverItem === index) {
      setHoverItem(null);
    }
  }

  const handleEdit = (index: number, event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newItems = list.items.flatMap((item, i) => {
      if (i !== index) {
        return item;
      }

      if (event.target.value.includes('\n')) {
        setCursorPosition([i + 1, 0]);
      }
      return event.target.value.split('\n').map(s => ({ title: s, checked: item.checked }));
    });

    setList({ ...list, items: newItems });
  }

  const handleKeyDown = (eventIndex: number, event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const cursorAtStart = itemsRef.current[eventIndex]?.selectionStart === 0;
    const cursorAtEnd = itemsRef.current[eventIndex]?.selectionEnd === list.items[eventIndex].title.length;
    const hasRange = itemsRef.current[eventIndex]?.selectionStart !== itemsRef.current[eventIndex]?.selectionEnd;

    if (hasRange) {
      return;
    }

    if (event.key === 'ArrowUp' && eventIndex > 0 && cursorAtStart) {
      const prev = list.items.slice().reverse().find((item, index) => index >= list.items.length - eventIndex && !item.meal);

      if (prev) {
        const index = list.items.indexOf(prev);
        setCursorPosition([index, prev.title.length]);
      }

      event.preventDefault();
    }

    if (event.key === 'ArrowDown' && eventIndex < list.items.length && cursorAtEnd) {
      const next = list.items.find((item, index) => index > eventIndex && !item.meal);

      if (next) {
        const index = list.items.indexOf(next);
        setCursorPosition([index, 0]);
      }

      event.preventDefault();
    }

    if (event.key === 'Backspace' && cursorAtStart) {
      // TODO: Go to last available item
      const newItems = list.items.reduce((acc, curr, index) => {
        const prev = acc[acc.length - 1];
        if (eventIndex === index && prev && !prev.meal) {
          setCursorPosition([index - 1, prev.title.length]);
          prev.title += curr.title;
        } else {
          acc.push(curr);
        }
        return acc;
      }, [] as ShoppingListItem[]);

      setList({ ...list, items: newItems });
      saveList({ ...list, items: newItems });

      event.preventDefault();
    }

    if (event.key === 'Delete' && cursorAtEnd) {
      // TODO: Go to next available item
      const newItems = list.items.reduce((acc, curr, index) => {
        const prev = acc[acc.length - 1];
        if (index === eventIndex + 1 && !curr.meal) {
          setCursorPosition([index - 1, prev.title.length]);
          acc[acc.length - 1].title += curr.title;
        } else {
          acc.push(curr);
        }
        return acc;
      }, [] as ShoppingListItem[]);

      setList({ ...list, items: newItems });
      saveList({ ...list, items: newItems });

      event.preventDefault();
    }
  }

  // Weird ref tracking code
  const itemsRef = useRef<Array<HTMLTextAreaElement | null>>([]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, list.items.length);

    if (cursorPosition) {
      const [row, column] = cursorPosition;
      itemsRef.current[row]?.focus();
      itemsRef.current[row]?.setSelectionRange(column, column);
      setCursorPosition(null);
    }
  }, [list.items, cursorPosition]);

  const isPhone = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  const theme = useTheme();

  const history = useHistory();
  const location = useLocation();
  const shopping = location.pathname.endsWith('plan');

  const [sorted, setSorted] = useState(false);

  function onListSave(items: ShoppingListItem[], meals: MealView[], staples: string[]) {
    addToList(items);
    for (const meal of meals) {
      saveMeal({ ...meal, addedToList: true });
    }
    updateStaples(items.map(i => i.title), staples);

    history.push('/shop');
  }

  const meals = plan.groups.flatMap(g => g.meals);

  const [deleting, setDeleting] = useState(false);

  return (
    <PageWrapper>
      {isPhone &&
        <AppBar position="sticky" color="secondary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <Typography variant="h5">
              Shopping List
            </Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Stack direction="row" gap={2}>
              <Tooltip title={sorted ? 'Hide Suggestions' : 'Show Suggestions'}>
                <IconButton color="inherit" onClick={() => setSorted(!sorted)}>
                  {sorted ? <ListAltOutlined /> : <SortByAlphaOutlined />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Add meals to shopping list">
                <IconButton color="inherit" onClick={() => history.push('/shop/plan')}>
                  <EventAvailableOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          </Toolbar>
        </AppBar>
      }
      {
        isPhone && !sorted ?
          <Zoom in unmountOnExit>
            <Fab
              color="primary"
              variant="extended"
              sx={{ position: 'fixed', bottom: 70, right: 15, zIndex: 1050 }}
              onClick={addItem}
            >
              <AddOutlined sx={{ mr: 1 }} />
              Add Item
            </Fab>
          </Zoom>
          : ''
      }
      <AddToListDialog
        open={shopping}
        onCancel={() => history.push('/shop')}
        onSave={onListSave}
        meals={meals}
        recipes={recipes}
        staples={staples}
      />
      <ScrollWrapper maxWidth="sm" padTop={!isPhone} disableGutters padFab={isPhone} centerItems={!list.items.length}>
        {list.items.length ?
          sorted
            ?
            <List>
              {sortedItems.map(([listItem, index]) =>
                <ListItem
                  key={`list-item-${index}`}
                  sx={{
                    background: theme => theme.palette.background.default,
                    boxShadow: 0,
                    borderRadius: 1,
                  }}
                  dense
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="end"
                      onChange={handleToggle(listItem)}
                      checked={listItem.checked}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography variant="body2">{listItem.title}<i style={{ color: theme.palette.text.secondary }}>{listItem.parenthetical}</i></Typography>}
                    secondary={!!listItem.meal && <span>{!!listItem.meal.amount && <>{listItem.meal.amount} - </>}{listItem.meal.recipeTitle}</span>}
                    sx={{ '& .MuiListItemText-primary': { display: 'flex' } }}
                  />
                </ListItem>
              )}
            </List>
            :
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={list.id}>
                {(provided, snapshot) =>
                  <List ref={provided.innerRef}>
                    {uncheckedItems.map(([listItem, index], i) =>
                      <Draggable key={`list-item-${index}`} draggableId={`${index}`} index={i}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <ListItem
                              sx={{
                                background: theme => theme.palette.background.default,
                                // color: theme => listItem.meal ? theme.palette.text.secondary : theme.palette.text.primary,
                                boxShadow: snapshot.isDragging ? 1 : 0,
                                borderRadius: 1,
                              }}
                              onMouseEnter={() => handleHover(index, true)}
                              onMouseLeave={() => handleHover(index, false)}
                              dense
                              secondaryAction={(activeItem === index || hoverItem === index) &&
                                <IconButton onClick={() => deleteItem(index)}><CloseOutlined /></IconButton>
                              }
                            >
                              <ListItemIcon sx={{ minWidth: '74px' }}>
                                <Stack direction="row" sx={{ alignItems: 'center' }}>
                                  <Stack {...provided.dragHandleProps}><DragIndicatorOutlined /></Stack>
                                  <Checkbox
                                    edge="end"
                                    onChange={handleToggle(listItem)}
                                    checked={listItem.checked}
                                  />
                                </Stack>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  listItem.meal ?
                                    <Typography variant="body2">{listItem.title}<i style={{ color: theme.palette.text.secondary }}>{listItem.parenthetical}</i></Typography> :
                                    <TextareaAutosize
                                      ref={el => itemsRef.current[index] = el}
                                      value={listItem.title}
                                      onChange={e => handleEdit(index, e)}
                                      onKeyDown={e => handleKeyDown(index, e)}
                                      onFocus={() => handleFocus(index, true)}
                                      onBlur={() => handleFocus(index, false)}
                                      style={{
                                        border: 'none',
                                        outline: 'none',
                                        padding: 0,
                                        background: 'transparent',
                                        font: 'inherit',
                                        fontSize: 'inherit',
                                        color: 'inherit',
                                        width: '100%',
                                        resize: 'none'
                                      }}
                                    />
                                }
                                secondary={!!listItem.meal && <span>{!!listItem.meal.amount && <>{listItem.meal.amount} - </>}{listItem.meal.recipeTitle}</span>}
                                sx={{ '& .MuiListItemText-primary': { display: 'flex' } }}
                              />
                            </ListItem>
                          </div>
                        )}
                      </Draggable>
                    )}
                    {provided.placeholder}
                  </List>
                }
              </Droppable>
              {!isPhone &&
                <ListItem dense>
                  <ListItemButton onClick={addItem} sx={{ borderRadius: 1 }}>
                    <ListItemIcon sx={{ ml: '17px', minWidth: '44px' }}><AddOutlined /></ListItemIcon>
                    <ListItemText primary="Add Item" />
                  </ListItemButton>
                </ListItem>
              }
            </DragDropContext>
          :
          <Box sx={{ textAlign: 'center', marginLeft: 1, marginRight: 1 }}>
            <NoListItems />
            <Typography gutterBottom variant="h2" component="div">
              Make groceries <Typography variant="h2" component="span" sx={{ color: theme => theme.palette.primary.main, fontWeight: '400' }}>easy</Typography>
            </Typography>
            <Typography gutterBottom variant="body2" color="text.secondary">
              Add items or planned meals to begin.
            </Typography>
            {!isPhone && <Button sx={{ mt: 1 }} onClick={addItem}>Add Item</Button>}
          </Box>
        }
        <Dialog
          open={deleting}
          onClose={() => setDeleting(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm Clear Checked Items"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              All checked items will be permanently deleted.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleting(false)} autoFocus>Cancel</Button>
            <Button color="error" onClick={deleteChecked}>Clear</Button>
          </DialogActions>
        </Dialog>
        {!!checkedItems.length && <>
          <Stack direction="row" alignItems="center" mt={3} ml={2} mr={2}>
            <Typography variant="h5" margin={0}>Checked Items</Typography>
            <Box sx={{ flex: '1 1 auto' }}></Box>
            <Button onClick={() => setDeleting(true)}>Clear</Button>
          </Stack>
          <List>
            {checkedItems.map(([listItem, index]) =>
              <ListItem
                key={`list-item-${index}`}
                sx={{
                  background: theme => theme.palette.background.default,
                  borderRadius: 1,
                }}
                onMouseEnter={() => handleHover(index, true)}
                onMouseLeave={() => handleHover(index, false)}
                dense
                secondaryAction={(activeItem === index || hoverItem === index) &&
                  <IconButton onClick={() => deleteItem(index)}><CloseOutlined /></IconButton>
                }
              >
                <ListItemIcon>
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(listItem)}
                    checked={listItem.checked}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body2">{listItem.title}<i style={{ color: theme.palette.text.secondary }}>{listItem.parenthetical}</i></Typography>
                  }
                  secondary={!!listItem.meal && <span>{!!listItem.meal.amount && <>{listItem.meal.amount} - </>}{listItem.meal.recipeTitle}</span>}
                  sx={{ '& .MuiListItemText-primary': { display: 'flex' } }}
                />
              </ListItem>
            )}
          </List>
        </>}
      </ScrollWrapper>
    </PageWrapper >
  );
}