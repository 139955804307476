import { Amount, Fraction } from "./amounts";
import { parseFraction } from "./parse-fraction";
import { getUnitStrings, Unit, unitFromString } from "./units";

export interface NumberMatch {
  minAmount: string[];
  maxAmount: string[];
  rest: string;
}

export interface UnitSizeMatch {
  result?: { fraction: string; unit: string; }
  rest: string;
}

export interface UnitMatch {
  unit?: Unit,
  rest: string,
}

export interface AdditiveRangeMatch {
  results: { fraction: string, unit: string }[],
  rest: string,
}

const singleNumberRegex = '([\\u00B9\\u00B2-\\u00B3\\u2074-\\u2079]+[/\\u2044][\\u2081-\\u2089]+|\\d+\\s*[\\u00B9\\u00B2-\\u00B3\\u2074-\\u2079]+[/\\u2044][\\u2081-\\u2089]+|(?:[\\u00BC-\\u00BE]|[\\u2150-\\u215E])|\\d+\\s*(?:[\\u00BC-\\u00BE]|[\\u2150-\\u215E])|\\d+\\s+and\\s+\\d+\\/\\d+|\\d+\\s+\\d+\\/\\d+|\\d+\\/\\d+|\\d*\\.\\d+|\\d+)';

export function matchNumbers(text: string): NumberMatch {
  const rangeMatch = text.match(`^${singleNumberRegex}\\s*(-|\\sto\\s|\\sor\\s)\\s*${singleNumberRegex}\\s*`);

  if (rangeMatch) {
    return {
      minAmount: [rangeMatch[1]],
      maxAmount: [rangeMatch[3]],
      rest: text.replace(rangeMatch[0], ''),
    };
  }

  const singleMatch = text.match(`^${singleNumberRegex}\\s*`);
  return singleMatch
    ? { minAmount: [singleMatch[1]], maxAmount: [singleMatch[1]], rest: text.replace(singleMatch[0], '') }
    : { minAmount: [], maxAmount: [], rest: text };
}

export function matchUnitSize(text: string): UnitSizeMatch {
  const { insensitive, sensitive } = getUnitStrings();

  const insensitiveMatch = text.match(new RegExp(`^(?:[xX\\u00D7\\u2A2F]\\s*)?\\(?${singleNumberRegex}(?:\\s*|-)(${insensitive.join('|')})\\.?\\)?\\s+`, 'i'));
  if (insensitiveMatch) {
    return {
      result: { fraction: insensitiveMatch[1], unit: insensitiveMatch[2] },
      rest: text.replace(insensitiveMatch[0], ''),
    }
  }

  const sensitiveMatch = text.match(`^(?:[xX\\u00D7\\u2A2F]\\s*)?\\(?${singleNumberRegex}(?:\\s*|-)(${sensitive.join('|')})\\.?\\)?\\s+`);
  if (sensitiveMatch) {
    return {
      result: { fraction: sensitiveMatch[1], unit: sensitiveMatch[2] },
      rest: text.replace(sensitiveMatch[0], ''),
    }
  }

  return { rest: text };
}

export function matchUnit(text: string): UnitMatch {
  const { insensitive, sensitive } = getUnitStrings();

  const insensitiveMatch = text.match(new RegExp(`^(${insensitive.join('|')})\\.?\\s+`, 'i'));
  if (insensitiveMatch) {
    return { unit: unitFromString(insensitiveMatch[1]), rest: text.replace(insensitiveMatch[0], '') }
  }

  const sensitiveMatch = text.match(`^(${sensitive.join('|')})\\.?\\s+`);
  if (sensitiveMatch) {
    return { unit: unitFromString(sensitiveMatch[1]), rest: text.replace(sensitiveMatch[0], '') }
  }

  return { rest: text };
}

export function matchAdditiveRange(text: string): AdditiveRangeMatch | undefined {
  const separatorMatch = text.match(/\s*(?:\+|\splus\s)\s*/);

  if (separatorMatch) {
    const { insensitive, sensitive } = getUnitStrings();

    const [first, ...rest] = text.split(separatorMatch[0]);
    const second = rest.join();

    const firstSensitiveMatch = first.match(`^${singleNumberRegex}\\s*(${sensitive.join('|')})\\.?$`);
    const firstInsensitiveMatch = first.match(new RegExp(`^${singleNumberRegex}\\s*(${insensitive.join('|')})\\.?$`, 'i'));

    const secondSensitiveMatch = second.match(`^${singleNumberRegex}\\s*(${sensitive.join('|')})\\.?\\s+`);
    const secondInsensitiveMatch = second.match(new RegExp(`^${singleNumberRegex}\\s*(${insensitive.join('|')})\\.?\\s+`, 'i'));

    const firstMatch = firstSensitiveMatch ?? firstInsensitiveMatch;
    const secondMatch = secondSensitiveMatch ?? secondInsensitiveMatch;

    if (firstMatch && secondMatch) {
      return {
        results: [
          { fraction: firstMatch[1], unit: firstMatch[2] },
          { fraction: secondMatch[1], unit: secondMatch[2] },
        ],
        rest: text.replace(firstMatch[0], '').replace(separatorMatch[0], '').replace(secondMatch[0], ''),
      };
    }
  }

  return undefined;
}

export function parseAmounts(text: string): [minAmount: Amount[], maxAmount: Amount[], rest: string] {
  const additiveRange = matchAdditiveRange(text);

  if (additiveRange) {
    return [
      additiveRange.results.map(({ fraction: amount, unit }) => ({ fraction: parseFraction(amount), unit: unitFromString(unit) })),
      additiveRange.results.map(({ fraction: amount, unit }) => ({ fraction: parseFraction(amount), unit: unitFromString(unit) })),
      additiveRange.rest,
    ];
  }

  const numberMatch = matchNumbers(text);
  const unitSizeMatch = matchUnitSize(numberMatch.rest);
  const unitMatch = matchUnit(unitSizeMatch.rest);
  const unitSize = convertunitSize(unitSizeMatch.result);

  if (unitMatch.unit && !numberMatch.minAmount.length) {
    return [
      [{ fraction: [1, 1], unit: unitMatch.unit, unitSize }],
      [{ fraction: [1, 1], unit: unitMatch.unit, unitSize }],
      unitMatch.rest,
    ]
  }

  return [
    numberMatch.minAmount.map(s => ({ fraction: parseFraction(s), unit: unitMatch.unit, unitSize })),
    numberMatch.maxAmount.map(s => ({ fraction: parseFraction(s), unit: unitMatch.unit, unitSize })),
    unitMatch.rest,
  ];
}

function convertunitSize(result: { fraction: string, unit: string } | undefined): { fraction: Fraction, unit?: Unit } | undefined {
  if (!result) {
    return undefined;
  }

  return { fraction: parseFraction(result.fraction), unit: unitFromString(result.unit) };
}
