import { LocalOfferOutlined } from '@mui/icons-material';
import { AppBar, Box, List, ListItem, ListItemButton, ListItemText, ListSubheader, Theme, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PageWrapper } from '../../components/PageWrapper';
import { ScrollWrapper } from '../../components/ScrollWrapper';
import { RecipeView } from '../../recipes/recipe';

export interface TagDetailProps {
  recipes: RecipeView[];
}

export default function TagDetail({ recipes }: TagDetailProps) {
  const [tagRecipes, setTagRecipes] = useState<RecipeView[]>([]);
  const tag = useParams<{ tag: string }>().tag;

  useEffect(() => {
    setTagRecipes(recipes.filter(r => r.tags.map(t => t.toLowerCase()).includes(tag.toLowerCase())));
  }, [recipes, tag]);

  const history = useHistory();
  const isPhone = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

  return (
    <PageWrapper>
      {isPhone &&
        <AppBar position="sticky" color="secondary">
          <Toolbar>
            <Typography variant="h5" component="div">
              Tag: "{tag}"
            </Typography>
          </Toolbar>
        </AppBar>
      }
      <ScrollWrapper maxWidth="sm" disableGutters>
        {tagRecipes.length
          ? <List
            subheader={!isPhone &&
              <ListSubheader component="div" id="nested-list-subheader">
                Tag: "{tag}"
              </ListSubheader>
            }
          >
            {tagRecipes.map(recipe =>
              <ListItem disablePadding>
                <ListItemButton onClick={() => history.push(`/recipes/${recipe.id}`)}>
                  <ListItemText primary={recipe.name} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
          : <Box sx={{ textAlign: 'center', marginLeft: 1, marginRight: 1 }}>
            <LocalOfferOutlined sx={{ fontSize: 100, color: 'text.secondary' }} />
            <Typography gutterBottom variant="h2" component="div">
              No recipes tagged with "{tag}"
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Tags can be added to a recipe by editing it adding text to the "Tag" section.
            </Typography>
          </Box>
        }
      </ScrollWrapper>
    </PageWrapper>
  );
}