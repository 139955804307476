import { RecipeView } from '../recipes';

export function getTags(recipes: RecipeView[]): string[] {
  const tags = new Set<string>();

  for (const recipe of recipes) {
    for (const tag of recipe.tags) {
      tags.add(tag);
    }
  }

  const array = Array.from(tags);
  array.sort();
  return array;
}
