import { getAuth } from 'firebase/auth';
import { collection, doc, getDocs, onSnapshot, query, Unsubscribe, where } from 'firebase/firestore';
import { FirestoreLeftover } from '../leftovers/firestore';
import { EventBusProducer, Events } from '../shared/events';
import { Collections, db } from '../shared/firebase';
import { FirestoreStapleSet } from '../staples/firestore';
import { WorkspaceRepository } from './repository';
import { WorkspaceView } from './workspace';

const ACTIVE_WORKSPACE_KEY = 'activeWorkspaceId';

export interface FirestoreWorkspace {
  id: string;
  name: string;
  mealPlanIds: string[];
  leftovers?: { [key: string]: FirestoreLeftover };
  staples?: FirestoreStapleSet;
}

export class WorkspaceFirestoreClient {
  constructor(private repository: WorkspaceRepository) { }

  public listen(id: string): Unsubscribe {
    const workspaceQuery = doc(db, Collections.WORKSPACE, id);

    return onSnapshot(workspaceQuery, async (snapshot) => {
      await this.repository.setWorkspace({
        id: snapshot.id,
        ...snapshot.data(),
      } as unknown as FirestoreWorkspace);
    });
  }
}

export class ActiveWorkspaceFirestoreClient {
  constructor(private producer: EventBusProducer) { }

  public async getActiveId(): Promise<string | undefined> {
    const cachedId = localStorage.getItem(ACTIVE_WORKSPACE_KEY);
    if (cachedId) return cachedId;

    const defaultId = await this.getDefaultId();
    if (!defaultId) return undefined;

    this.setActiveId(defaultId);
    return defaultId;
  }

  public setActiveId(id: string): void {
    localStorage.setItem(ACTIVE_WORKSPACE_KEY, id);
    this.producer.emit(Events.ACTIVE_WORKSPACE_CHANGED);
  }

  public resetActiveId(): void {
    localStorage.removeItem(ACTIVE_WORKSPACE_KEY);
    this.producer.emit(Events.ACTIVE_WORKSPACE_CHANGED);
  }

  public async getDefaultId(): Promise<string | undefined> {
    const userId = await new Promise<string | undefined>((res, rej) => {
      const unsubscribe = getAuth().onAuthStateChanged(user => {
        unsubscribe();
        res(user?.uid);
      }, rej);
    });

    if (!userId) return undefined;

    const workspaceQuery = query(
      collection(db, Collections.WORKSPACE),
      where('userId', '==', userId),
      where('isDefault', '==', true),
    );

    return new Promise<string>((res, rej) => {
      const unsubscribe = onSnapshot(workspaceQuery,
        snapshot => {
          unsubscribe();
          res(snapshot.docs[0].id);
        },
        rej
      );
    });
  }

  public async getWorkspaces(): Promise<WorkspaceView[]> {
    const userId = await new Promise<string | undefined>((res, rej) => {
      const unsubscribe = getAuth().onAuthStateChanged(user => {
        unsubscribe();
        res(user?.uid);
      }, rej);
    });

    if (!userId) return [];

    const ownWorkspacesQuery = query(
      collection(db, Collections.WORKSPACE),
      where('userId', '==', userId),
    );

    const sharedWorkspacesQuery = query(
      collection(db, Collections.WORKSPACE),
      where('editors', 'array-contains', userId),
    );

    const results = await Promise.all([getDocs(ownWorkspacesQuery), getDocs(sharedWorkspacesQuery)]);

    return results
      .flatMap(x => x.docs)
      .map(x => ({ id: x.id, name: x.data().name }));
  }
}