import { Amount, Fraction } from "./amounts";
import { Ingredient } from "./parse-ingredient";

// TODO: Unit test functionality and move out of test code.

export function prettifyIngredient(ingredient: Ingredient): string {
  let res = prettifyRange(ingredient.minAmount, ingredient.maxAmount);
  if (ingredient.item) {
    res += ` ${ingredient.item}`;
  }
  if (ingredient.parenthetical) {
    res += ingredient.parenthetical;
  }
  return res;
}

export function prettifyRange(minAmount: Amount[], maxAmount: Amount[]): string {

  if (minAmount.length === maxAmount.length) {
    const noRange = minAmount.every((min, i) => {
      const max = maxAmount[i];
      return min.fraction[0] === max.fraction[0]
        && min.fraction[1] === max.fraction[1]
        && min.unit === max.unit;
    });

    if (noRange) {
      return prettifyAmounts(minAmount);
    }
  }

  if (minAmount.length > 1 || minAmount.length !== maxAmount.length || minAmount[0].unit !== maxAmount[0].unit) {
    return prettifyAmounts(minAmount) + ' - ' + prettifyAmounts(maxAmount);
  }

  return prettifyFraction(minAmount[0].fraction) + ' - ' + prettifyAmount(maxAmount[0]);
}

export function prettifyAmounts(amounts: Amount[]): string {
  return amounts.map(prettifyAmount).join(' plus ');
}

export function prettifyAmount(amount: Amount): string {
    let unitSize = '';
    if (amount.unitSize) {
      unitSize = ` ⨯ ${prettifyFraction(amount.unitSize.fraction)} ${amount.unitSize.unit?.singular}`;
    }

    if (amount.unit) {
      if ((amount.fraction[0] / amount.fraction[1]) > 1) {
        return `${prettifyFraction(amount.fraction)}${unitSize} ${amount.unit.plural}`;
      }

      return `${prettifyFraction(amount.fraction)}${unitSize} ${amount.unit.singular}`;
    }

    return `${prettifyFraction(amount.fraction)}${unitSize}`;
}

export function prettifyFraction(fraction: Fraction): string {
  const [numerator, denominator] = fraction;
  if (Number.isInteger(numerator)) {
    if (denominator === 1) {
      return numerator.toString();
    } else if (numerator > denominator) {
      return `${Math.floor(numerator / denominator)} ${getUnicodeFraction(numerator % denominator, denominator)}`;
    } else {
      return getUnicodeFraction(numerator, denominator);
    }

  } else {
    return (Math.round(numerator * 100) / 100).toString();
  }
}

interface FractionIndexer {
  [key: number]: {
    [key: number]: string | undefined
  } | undefined
}

const unicodeFractions: FractionIndexer = {
  1: { 2: '½', 3: '⅓', 4: '¼', 5: '⅕', 6: '⅙', 7: '⅐', 8: '⅛', 9: '⅑', 10: '⅒' },
  2: { 3: '⅔', 5: '⅖' },
  3: { 4: '¾', 5: '⅗', 8: '⅜' },
  4: { 5: '⅘' },
  5: { 6: '⅚', 8: '⅝' },
  7: { 8: '⅞' },
}

const unicodeSuperscripts: string[] = [
  '⁰',
  '¹',
  '²',
  '³',
  '⁴',
  '⁵',
  '⁶',
  '⁷',
  '⁸',
  '⁹',
];

const unicodeSubscripts: string[] = [
  '₀',
  '₁',
  '₂',
  '₃',
  '₄',
  '₅',
  '₆',
  '₇',
  '₈',
  '₉',
];

function getSuperscript(numerator: number): string {
  return numerator.toString()
    .split('')
    .map(n => parseInt(n))
    .map(n => unicodeSuperscripts[n])
    .join('');
}

function getSubscript(denominator: number): string {
  return denominator.toString()
    .split('')
    .map(n => parseInt(n))
    .map(n => unicodeSubscripts[n])
    .join('');
}

function getUnicodeFraction(numerator: number, denominator: number): string {
  if (!Number.isInteger(numerator) || !Number.isInteger(denominator)) {
    return (numerator / denominator).toString();
  }

  return unicodeFractions[numerator]?.[denominator] ?? `${getSuperscript(numerator)}⁄${getSubscript(denominator)}`;
}