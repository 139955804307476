import { useTheme } from '@mui/material';
import { Fraction } from '../parser/amounts';
import { Ingredient, scaleIngredient } from '../parser/parse-ingredient';
import { prettifyRange } from '../parser/test-helpers';
import pluralize from 'pluralize';

interface IngredientViewProps {
  ingredient: Ingredient;
  scale: Fraction;
  simplified?: boolean;
}

export default function IngredientView({ ingredient, scale, simplified = false }: IngredientViewProps) {
  const scaledIngredient = scaleIngredient(ingredient, scale);
  const amounts = prettifyRange(scaledIngredient.minAmount, scaledIngredient.maxAmount);
  const tryPluralize = scaledIngredient.maxAmount[0] && !scaledIngredient.maxAmount[0].unit;
  const pluralized = tryPluralize
    ? pluralize(ingredient.item, Math.ceil(scaledIngredient.maxAmount[0].fraction[0] / scaledIngredient.maxAmount[0].fraction[1]))
    : ingredient.item;

  const theme = useTheme();

  if (simplified) {
    return (
      <span>
        {amounts} {pluralized}
        <i style={{ color: theme.palette.text.secondary }}>{scaledIngredient.parenthetical ?? ''}</i>
      </span>
    );
  }

  return (
    <span>
      <b>
        {amounts}
      </b> {pluralized}
      <i style={{ color: theme.palette.text.secondary }}>{scaledIngredient.parenthetical ?? ''}</i>
    </span>
  );
}