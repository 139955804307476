export type Unsubscribe = () => void;

type Callback<T> = (data: T) => void;
type AsyncCallback<T> = (data: T) => Promise<void>;
type SubscribeFunction<T> = (callback: AsyncCallback<T>) => Promise<Unsubscribe>;

export function unwrapPromise<T>(subscribeFn: SubscribeFunction<T>, callback: Callback<T>): Unsubscribe {
  let unsubscribed: boolean = false;
  let internalUnsubscribe: Unsubscribe = () => unsubscribed = true;
  const internalCallback: AsyncCallback<T> = async (data) => callback(data);

  subscribeFn(internalCallback).then(unsubscribe => {
    if (unsubscribed) {
      unsubscribe();
    } else {
      internalUnsubscribe = unsubscribe;
    }
  });

  return () => internalUnsubscribe();
}
