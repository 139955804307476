import { LocalOfferOutlined } from '@mui/icons-material';
import { AppBar, Box, List, ListItem, ListItemButton, ListItemText, ListSubheader, Theme, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { PageWrapper } from '../../components/PageWrapper';
import { ScrollWrapper } from '../../components/ScrollWrapper';

export interface TagListProps {
  tags: string[];
}

export default function TagList({ tags }: TagListProps) {
  const history = useHistory();
  const isPhone = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

  return (
    <PageWrapper>
      {isPhone &&
        <AppBar position="sticky" color="secondary">
          <Toolbar>
            <Typography variant="h5" component="div">
              Tags
            </Typography>
          </Toolbar>
        </AppBar>
      }
      <ScrollWrapper maxWidth="sm" disableGutters>
        {tags.length
          ? <List
            subheader={!isPhone &&
              <ListSubheader component="div" id="nested-list-subheader">
                Tags
              </ListSubheader>
            }
          >
            {tags.map(tag =>
              <ListItem disablePadding>
                <ListItemButton onClick={() => history.push(`/tags/${tag.toLowerCase()}`)}>
                  <ListItemText primary={tag} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
          : <Box sx={{ textAlign: 'center', marginLeft: 1, marginRight: 1 }}>
            <LocalOfferOutlined sx={{ fontSize: 100, color: 'text.secondary' }} />
            <Typography gutterBottom variant="h2" component="div">
              No tags found!
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Tags can be added to a recipe by editing it adding text to the "Tag" section.
            </Typography>
          </Box>
        }
      </ScrollWrapper>
    </PageWrapper>
  );
}