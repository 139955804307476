import { db as localDb } from "../shared/database";
import { EventBusConsumer, EventBusProducer, Events } from '../shared/events';
import { db as firestore } from "../shared/firebase";
import { unwrapPromise } from '../shared/subscriptions';
import { ActiveWorkspaceFirestoreClient } from '../workspaces/firestore';
import { StapleFirestoreClient } from './firestore';
import { StapleRepository } from './repository';
import { StapleSet } from './staple';

const consumer = new EventBusConsumer();
const producer = new EventBusProducer();
const workspaceClient = new ActiveWorkspaceFirestoreClient(producer);
const repository = new StapleRepository(
  localDb,
  new StapleFirestoreClient(firestore)
);

type Callback = (staples: StapleSet) => void;

export function subscribeToStaples(callback: Callback): () => void {
  return unwrapPromise(cb => subscribeInternal(cb), callback);
}

async function subscribeInternal(callback: Callback): Promise<() => void> {
  return consumer.subscribe(Events.STAPLES_CHANGED, async () => {
    const workspaceId = await workspaceClient.getActiveId();

    if (workspaceId) {
      const staples = await repository.getStaples(workspaceId);
      callback(staples);
    }
  });
}

export async function updateStaples(checkedItems: string[], uncheckedItems: string[]) {
  const workspaceId = await workspaceClient.getActiveId();

  if (workspaceId) {
    await repository.update(workspaceId, checkedItems, uncheckedItems);
  }
}