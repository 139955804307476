import { Autocomplete, TextField, ListItem, ListItemText, List, ListSubheader, Chip, Grid } from '@mui/material';
import { SearchResult, searchRecipes } from '../shared/search';
import SearchHighlight from './SearchHighlight';
import { RecipeView } from '../recipes/recipe';
import { AddCircleOutlined } from '@mui/icons-material';

export interface RecipeSelectProps {
  value: SearchResult[];
  recipes: RecipeView[];
  recipeIds: string[];
  hiddenRecipeIds?: string[];
  onChange: (value: SearchResult[]) => void;
  size?: 'small' | 'medium';
}

export function RecipeSelect({ value, recipes, recipeIds, hiddenRecipeIds = [], onChange, size = 'medium' }: RecipeSelectProps) {

  const suggestedRecipes = [...recipeIds, ...value.map(x => x.id)]
    // Join to recipes
    .map(id => recipes.find(x => x.id === id))
    .filter(x => !!x)
    .map(x => x as RecipeView)
    // Flatten linked recipes
    .flatMap(x => x.linkedRecipeIds)
    .map(id => recipes.find(x => x.id === id))
    .filter(x => !!x)
    .map(x => x as RecipeView)
    // Filter out already selected items
    .filter(x => !recipeIds.includes(x.id))
    .filter(x => !value.map(x => x.id).includes(x.id))
    .map(x => ({ id: x.id, title: x.name }))
    // Filter out duplicates
    .filter((x, i, self) => self.findIndex(y => y.id === x.id) === i);

  return (
    <>
      <Autocomplete
        multiple
        value={value}
        filterSelectedOptions
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        autoHighlight
        filterOptions={(_, params) => {
          const { inputValue } = params;
          return searchRecipes(inputValue, recipes.filter(x => !hiddenRecipeIds.includes(x.id)));
        }}
        options={searchRecipes('', recipes.filter(x => !hiddenRecipeIds.includes(x.id)))}
        groupBy={(option) => option.group?.label ?? option.title[0].toUpperCase()}
        getOptionLabel={(option) => option.title}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField margin="normal" {...params} />}
        renderOption={(props, option, state) =>
          <ListItem dense {...props} key={option.id}>
            <ListItemText
              primary={<SearchHighlight text={option.title} searchTerm={state.inputValue} />}
              secondary={<SearchHighlight text={option.subtitle} searchTerm={state.inputValue} />}
            />
          </ListItem>
        }
        renderGroup={(params) =>
          <List
            key={params.key}
            subheader={<ListSubheader>{params.group}</ListSubheader>}
            disablePadding
          >
            {params.children}
          </List>
        }
        onChange={(_, value) => onChange(value)}
        size={size}
      />
      <Grid container spacing={1}>
        {suggestedRecipes.map(recipe =>
          <Grid item key={`suggestion-${recipe.id}`}>
            <Chip
              deleteIcon={<AddCircleOutlined />}
              label={recipe.title}
              onDelete={() => onChange([...value, recipe])}
              size={size}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}