import { UnitConversions } from "./unit-conversions";
import { Unit } from "./units";

export type Fraction = [numerator: number, denominator: number];

export interface Amount {
  fraction: Fraction;
  unitSize?: { fraction: Fraction, unit?: Unit };
  unit?: Unit;
}

export function scaleAmount(amounts: Amount[], scale: Fraction): Amount[] {
  return simplifyAmount(
    amounts.map(a => ({
      fraction: multiplyFractions(a.fraction, scale),
      unit: a.unit,
      unitSize: a.unitSize,
    }))
  );
}

export function simplifyAmount(amounts: Amount[]): Amount[] {
  return amounts
    .map(({ fraction, unit, unitSize }) => {
      const simplifiedFraction = simplifyFraction(fraction);
      const [numerator, denominator] = simplifiedFraction;
      const isFractional = (numerator / denominator) < 1;

      if (!unit) {
        return { fraction: simplifiedFraction, unitSize };
      }

      if (isFractional) {
        return { ...convertSmaller(simplifiedFraction, unit), unitSize };
      } else {
        return { ...convertLarger(simplifiedFraction, unit), unitSize };
      }
    })
    .reduce((acc, curr) => {
      const copy = [...acc];
      const existingUnitAmount = copy.find(a => a.unit === curr.unit);

      if (existingUnitAmount) {
        const index = copy.indexOf(existingUnitAmount);
        const newUnitAmounts = addAmounts(existingUnitAmount, curr);
        copy.splice(index, 1, ...newUnitAmounts);
      } else {
        copy.push(curr);
      }

      return copy;
    }, [] as Amount[]);
}

function convertLarger(fraction: Fraction, unit: Unit): Amount {
  return convertLargerRecursive(fraction, unit) ?? { fraction, unit };
}

function convertLargerRecursive(fraction: Fraction, unit: Unit): Amount | null {
  const largerConversions = UnitConversions
    .filter(c => c.from === unit)
    .filter(c => c.from.system === c.to.system);

  for (const conversion of largerConversions) {
    const convertedFraction = multiplyFractions(fraction, [1, conversion.multiplier]);

    if (conversion.to.commonDivisors.includes(convertedFraction[1])) {
      return convertLargerRecursive(convertedFraction, conversion.to);
    }
  }

  if (!unit.isCommon) {
    return null;
  }

  return { fraction, unit };
}

function convertSmaller(fraction: Fraction, unit: Unit): Amount {
  const smallerConversions = UnitConversions
    .filter(c => c.to === unit)
    .filter(c => c.from.system === c.to.system);

  if (unit.commonDivisors.includes(fraction[1])) {
    return { fraction, unit };
  }

  for (const conversion of smallerConversions) {
    const convertedFraction = multiplyFractions(fraction, [conversion.multiplier, 1]);

    if (conversion.from.isCommon) {
      return convertSmaller(convertedFraction, conversion.from);
    }
  }

  return { fraction, unit };
}

function addAmounts(a: Amount, b: Amount): Amount[] {
  if (a.unit !== b.unit) {
    return [a, b];
  }

  const multiple = lcm(a.fraction[1], b.fraction[1]);

  const aMultiple = multiple / a.fraction[1];
  const bMultiple = multiple / b.fraction[1];

  return [{
    fraction: simplifyFraction([
      a.fraction[0] * aMultiple + b.fraction[0] * bMultiple,
      a.fraction[1] * aMultiple
    ]),
    unit: a.unit,
  }];
}

function multiplyFractions([n1, d1]: Fraction, [n2, d2]: Fraction) {
  return simplifyFraction([n1 * n2, d1 * d2]);
}

function simplifyFraction([numerator, denominator]: Fraction): Fraction {
  if (numerator === 0) {
    return [0, 1];
  }

  if (numerator < 1) {
    const exponent = numerator.toString().substring(2).length;
    const scale = numerator * (10 ** exponent)
    return simplifyFraction([numerator * scale, denominator * scale]);
  }

  const divisor = gcd(numerator, denominator);
  return [numerator / divisor, denominator / divisor];
}

function gcd(a: number, b: number): number {
  if (b < 0.0000001) {
    return a;
  }

  return gcd(b, Math.floor(a % b));
}

function lcm(a: number, b: number): number {
  return (a * b) / gcd(a, b);
}