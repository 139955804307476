import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import MealEdit from '../../components/MealEdit';
import { GrowTransition, SlideTransition } from '../../components/Transitions';
import { saveMeal } from '../../meals';
import { MealView as Meal } from '../../meals/meal';
import { RecipeView } from '../../recipes';
import { getId } from '../../shared/identifiers';

export interface MealCreateProps {
  recipes: RecipeView[];
  active: boolean;
  onClose: () => void;
}

export default function MealCreate({ recipes, active, onClose }: MealCreateProps) {
  const { state } = useLocation<{ recipeId?: string; planDate?: Date; }>();

  const newMeal: Meal = {
    id: getId(),
    name: '',
    displayName: '',
    recipeIds: state?.recipeId ? [state.recipeId] : [],
    recipes: [],
    recipeState: {},
    extras: [],
    planDate: state?.planDate,
    planOrder: 0,
    addedToList: false,
  };

  const history = useHistory();

  const [closing, setClosing] = useState(false);
  const [modified, setModified] = useState(false);

  function save(meal: Meal) {
    saveMeal(meal).then(() => history.replace('/'));
  }

  function beginCloseModal() {
    if (modified) {
      setClosing(true);
    } else {
      confirmExit();
    }
  }

  function confirmExit() {
    setModified(false);
    setClosing(false);
    onClose();
  }

  function cancelExit() {
    setClosing(false);
  }

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <Dialog
        fullScreen={!isDesktop}
        maxWidth="xl"
        fullWidth
        open={active}
        onClose={beginCloseModal}
        TransitionComponent={isPhone ? SlideTransition : GrowTransition}
        sx={{ '.MuiDialog-paper': { height: '100%' } }}
      >
      <MealEdit meal={newMeal} recipes={recipes} cancelEditing={beginCloseModal} save={save} deleteMeal={confirmExit} changed={setModified} />
      </Dialog>
      <Dialog
        open={closing}
        onClose={cancelExit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to stop editing?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Any changes will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelExit} autoFocus>Cancel</Button>
          <Button color="error" onClick={confirmExit}>Discard</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}