export enum Events {
  ACTIVE_WORKSPACE_CHANGED = "ACTIVE_WORKSPACE_CHANGED",
  WORKSPACE_CHANGED = "WORKSPACE_CHANGED",
  RECIPES_CHANGED = "RECIPES_CHANGED",
  PLAN_CHANGED = "PLAN_CHANGED",
  LISTS_CHANGED = "LISTS_CHANGED",
  LEFTOVERS_CHANGED = "LEFTOVERS_CHANGED",
  STAPLES_CHANGED = "STAPLES_CHANGED",
}

const CHANNEL_NAME = 'events-v1';

export class EventBusProducer {
  private channel: BroadcastChannel = new BroadcastChannel(CHANNEL_NAME);

  public emit(event: Events) {
    this.channel.postMessage(event);
  }
}

export class EventBusConsumer {
  private channel: BroadcastChannel = new BroadcastChannel(CHANNEL_NAME);

  private subs: Array<[Events, () => Promise<void>]> = [];

  constructor() {
    this.channel.onmessage = event => this.subs.forEach(async sub => {
      if (event.data === sub[0]) {
        await sub[1]();
      }
    });
  }

  public async subscribe(event: Events, callback: () => Promise<void>): Promise<() => void> {
    const sub: [Events, () => Promise<void>] = [event, callback];
    this.subs.push(sub);
    await callback();
    return () => this.subs.splice(this.subs.indexOf(sub), 1);
  }
}