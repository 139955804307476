export const getDate = (dayOffset: number): Date => new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * dayOffset);

export const getDayName = (dayOffset: number): string => getDate(dayOffset).toLocaleString('en-US', { weekday: 'short' });

export const getDayNumber = (dayOffset: number): string => getDate(dayOffset).toLocaleString('en-US', { day: 'numeric' });

export const getDayDiff = (now: Date, then: Date): number => {
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const thenDay = new Date(then.getFullYear(), then.getMonth(), then.getDate());
  const timezoneOffsetMs = (today.getTimezoneOffset() - thenDay.getTimezoneOffset()) * 60 * 1000;
  return Math.floor((thenDay.getTime() - today.getTime() + timezoneOffsetMs) / (1000 * 60 * 60 * 24));
};