import { Emitter } from './emitter';
import { parse } from './parser';
import { tokenize } from './tokenizer';
import { Transformer } from './transformer';

export function compile<T>(input: string, transformers: Transformer[], emitter: Emitter<T>): T {
  const tokens = tokenize(input);
  const ast = parse(tokens.filter(x => x.type !== 'whitespace'));

  if (!ast) return emitter.default();

  const transformed = transformers.reduce((acc, curr) => curr.transform(acc), ast);

  return emitter.emit(transformed);
}