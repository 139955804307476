import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MealEdit from '../../components/MealEdit';
import MealView from '../../components/MealView';
import { GrowTransition, SlideTransition } from '../../components/Transitions';
import { deleteMeal, saveMeal } from '../../meals';
import { MealView as Meal } from '../../meals/meal';
import { RecipeView } from '../../recipes';
import { getId } from '../../shared/identifiers';

interface MealDetailProps {
  meals: Meal[];
  recipes: RecipeView[];
  active: boolean;
  onClose: () => void;
}

export default function MealDetail({ meals, recipes, active, onClose }: MealDetailProps) {
  const mealInit: Meal = {
    id: getId(),
    name: '',
    displayName: '',
    recipeIds: [],
    recipes: [],
    recipeState: {},
    extras: [],
    planOrder: 0,
    addedToList: false,
  };

  const id = useParams<{ id: string }>().id;
  const meal = meals.find(m => m.id === id);
  const currMeal = meal
    ? { ...meal, recipes: meal.recipeIds.map(id => recipes.find(r => r.id === id)).filter((r): r is RecipeView => !!r) }
    : mealInit;
    const history = useHistory();
  
    const [editing, setEditing] = useState(false);
    const [closing, setClosing] = useState(false);
    const [cancelling, setCancelling] = useState(false);
    const [modified, setModified] = useState(false);
  
    function save(meal: Meal) {
      saveMeal(meal).then(() => setEditing(false));
    }
  
    function deleteInternal() {
      deleteMeal(currMeal.id).then(() => history.push('/'));
    }
  
    function beginDiscardChanges() {
      if (modified) {
        setCancelling(true);
      } else {
        confirmExit();
      }
    }
  
    function beginCloseModal() {
      if (editing && modified) {
        setClosing(true);
      } else {
        setEditing(false);
        setModified(false);
        onClose();
      }
    }
  
    function confirmExit() {
      setEditing(false);
      setModified(false);
  
      if (closing) {
        setClosing(false);
        onClose();
      }
  
      if (cancelling) {
        setCancelling(false);
      }
    }
  
    function cancelExit() {
      setCancelling(false);
      setClosing(false);
    }
  

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <Dialog
        fullScreen={!isDesktop}
        maxWidth="xl"
        fullWidth
        open={active}
        onClose={beginCloseModal}
        TransitionComponent={isPhone ? SlideTransition : GrowTransition}
        sx={{ '.MuiDialog-paper': { height: '100%' } }}
      >
        {editing ?
          <MealEdit meal={currMeal} recipes={recipes} save={save} deleteMeal={deleteInternal} cancelEditing={beginDiscardChanges} changed={setModified} /> :
          <MealView meal={currMeal} recipes={recipes} startEditing={() => setEditing(true)} />}
      </Dialog>
      <Dialog
        open={cancelling || closing}
        onClose={cancelExit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to stop editing?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Any changes will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelExit} autoFocus>Cancel</Button>
          <Button color="error" onClick={confirmExit}>Discard</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}