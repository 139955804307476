export interface UnitLabelDetail {
  value: string;
  isCaseSensitive: boolean;
}
export type UnitLabel = string | UnitLabelDetail;

interface System {
  useDecimals: boolean;
}

export const Systems: { [key: string]: System } = {
  US: { useDecimals: false },
  METRIC: { useDecimals: true },
  OTHER: { useDecimals: false },
}

enum MeasurementType { WEIGHT, VOLUME, OTHER };

export interface Unit {
  singular: string;
  plural: string;
  short: string;
  otherLabels: UnitLabel[];
  system: System;
  measurementType: MeasurementType;
  commonDivisors: number[];
  isCommon: boolean;
}

export function unitFromString(text: string): Unit | undefined {
  const preservedCase = text.replace('.', '');
  const normalizedCase = preservedCase.toLowerCase();
  for (const unit of Object.values(Units)) {
    if (
      [unit.singular, unit.plural, unit.short, ...unit.otherLabels.filter(a => typeof a === 'string')].includes(normalizedCase)
      || unit.otherLabels.filter(a => typeof a !== 'string').map(a => (a as UnitLabelDetail).value).includes(preservedCase)
    ) {
      return unit;
    }
  }
}

export const Units: { [key: string]: Unit } = {
  gallon: {
    singular: 'gallon',
    plural: 'gallons',
    short: 'gallon',
    otherLabels: ['gal'],
    system: Systems.US,
    measurementType: MeasurementType.VOLUME,
    commonDivisors: [1],
    isCommon: false,
  },
  quart: {
    singular: 'quart',
    plural: 'quarts',
    short: 'quart',
    otherLabels: ['qt', 'qts'],
    system: Systems.US,
    measurementType: MeasurementType.VOLUME,
    commonDivisors: [1],
    isCommon: false,
  },
  pint: {
    singular: 'pint',
    plural: 'pints',
    short: 'pint',
    otherLabels: ['pt'],
    system: Systems.US,
    measurementType: MeasurementType.VOLUME,
    commonDivisors: [1],
    isCommon: false,
  },
  cup: {
    singular: 'cup',
    plural: 'cups',
    short: 'cup',
    otherLabels: ['c'],
    system: Systems.US,
    measurementType: MeasurementType.VOLUME,
    commonDivisors: [1, 2, 3, 4],
    isCommon: true,
  },
  fluidOunce: {
    singular: 'fluid ounce',
    plural: 'fluid ounces',
    short: 'fl oz',
    otherLabels: [],
    system: Systems.US,
    measurementType: MeasurementType.VOLUME,
    commonDivisors: [1],
    isCommon: false,
  },
  tablespoon: {
    singular: 'tablespoon',
    plural: 'tablespoons',
    short: 'tbsp',
    otherLabels: [{ value: 'T', isCaseSensitive: true }, 'tb', 'tbs', 'tbls'],
    system: Systems.US,
    measurementType: MeasurementType.VOLUME,
    commonDivisors: [1, 2],
    isCommon: true,
  },
  teaspoon: {
    singular: 'teaspoon',
    plural: 'teaspoons',
    short: 'tsp',
    otherLabels: [{ value: 't', isCaseSensitive: true }],
    system: Systems.US,
    measurementType: MeasurementType.VOLUME,
    commonDivisors: [1, 2, 3, 4, 6, 8],
    isCommon: true,
  },
  dash: {
    singular: 'dash',
    plural: 'dashes',
    short: 'dash',
    otherLabels: ['ds'],
    system: Systems.US,
    measurementType: MeasurementType.VOLUME,
    commonDivisors: [1],
    isCommon: false,
  },
  pinch: {
    singular: 'pinch',
    plural: 'pinches',
    short: 'pinch',
    otherLabels: ['pn'],
    system: Systems.US,
    measurementType: MeasurementType.VOLUME,
    commonDivisors: [1],
    isCommon: true,
  },
  pound: {
    singular: 'pound',
    plural: 'pounds',
    short: 'lb',
    otherLabels: ['lbs'],
    system: Systems.US,
    measurementType: MeasurementType.WEIGHT,
    commonDivisors: [1, 2],
    isCommon: true,
  },
  ounce: {
    singular: 'ounce',
    plural: 'ounces',
    short: 'oz',
    otherLabels: [],
    system: Systems.US,
    measurementType: MeasurementType.WEIGHT,
    commonDivisors: [1],
    isCommon: true,
  },
  liter: {
    singular: 'liter',
    plural: 'liters',
    short: 'l',
    otherLabels: ['litre', 'litres', 'ltr', 'ltrs', 'lit'],
    system: Systems.METRIC,
    measurementType: MeasurementType.VOLUME,
    commonDivisors: [1],
    isCommon: true,
  },
  mililiter: {
    singular: 'mililiter',
    plural: 'mililiters',
    short: 'ml',
    otherLabels: ['mililitre', 'mililitres'],
    system: Systems.METRIC,
    measurementType: MeasurementType.VOLUME,
    commonDivisors: [1],
    isCommon: true,
  },
  gram: {
    singular: 'gram',
    plural: 'grams',
    short: 'g',
    otherLabels: [],
    system: Systems.METRIC,
    measurementType: MeasurementType.WEIGHT,
    commonDivisors: [1],
    isCommon: true,
  },
  can: {
    singular: 'can',
    plural: 'cans',
    short: 'can',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  jar: {
    singular: 'jar',
    plural: 'jars',
    short: 'jar',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  bottle: {
    singular: 'bottle',
    plural: 'bottles',
    short: 'bottle',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  box: {
    singular: 'box',
    plural: 'boxes',
    short: 'box',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  carton: {
    singular: 'carton',
    plural: 'cartons',
    short: 'carton',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  container: {
    singular: 'container',
    plural: 'containers',
    short: 'container',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  clove: {
    singular: 'clove',
    plural: 'cloves',
    short: 'clove',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  bunch: {
    singular: 'bunch',
    plural: 'bunches',
    short: 'bunch',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  slice: {
    singular: 'slice',
    plural: 'slices',
    short: 'slice',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  package: {
    singular: 'package',
    plural: 'packages',
    short: 'pkg',
    otherLabels: ['pkgs'],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  tub: {
    singular: 'tub',
    plural: 'tubs',
    short: 'tub',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  packet: {
    singular: 'packet',
    plural: 'packets',
    short: 'pkt',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  tray: {
    singular: 'tray',
    plural: 'trays',
    short: 'tray',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  square: {
    singular: 'square',
    plural: 'squares',
    short: 'sq',
    otherLabels: ['sqs'],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  cube: {
    singular: 'cube',
    plural: 'cubes',
    short: 'cube',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  loaf: {
    singular: 'loaf',
    plural: 'loaves',
    short: 'loaf',
    otherLabels: ['loafs'],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  head: {
    singular: 'head',
    plural: 'heads',
    short: 'head',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  bar: {
    singular: 'bar',
    plural: 'bars',
    short: 'bar',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  small: {
    singular: 'smōl',
    plural: 'smōl',
    short: 'sm',
    otherLabels: ['small'],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  medium: {
    singular: 'medium',
    plural: 'medium',
    short: 'md',
    otherLabels: ['med'],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
  large: {
    singular: 'large',
    plural: 'large',
    short: 'lg',
    otherLabels: [],
    system: Systems.OTHER,
    measurementType: MeasurementType.OTHER,
    commonDivisors: [1],
    isCommon: true,
  },
};

function computeUnitStrings(): { insensitive: string[], sensitive: string[] } {
  const insensitiveUnits = new Set<string>();
  const sensitiveUnits = new Set<string>();

  for (const unit of Object.values(Units)) {
    insensitiveUnits.add(unit.singular);
    insensitiveUnits.add(unit.plural);
    insensitiveUnits.add(unit.short);

    for (const abbreviation of unit.otherLabels) {
      if (typeof abbreviation === 'string') {
        insensitiveUnits.add(abbreviation);
      } else if (!abbreviation.isCaseSensitive) {
        insensitiveUnits.add(abbreviation.value);
      } else {
        sensitiveUnits.add(abbreviation.value);
      }
    }
  }

  return { sensitive: Array.from(sensitiveUnits), insensitive: Array.from(insensitiveUnits) };
}

const unitStrings = computeUnitStrings();

export function getUnitStrings(): { insensitive: string[], sensitive: string[] } {
  return unitStrings;
}

