import { Container, Typography } from '@mui/material';

export default function NotFound() {
  return (
    <Container maxWidth="md" sx={{ mt: 3, textAlign: 'center' }}>
      <Typography variant="h1">404</Typography>
      <Typography variant="subtitle1">This page doesn't seem to exist.</Typography>
    </Container>
  );
}
