import { AccountCircleOutlined, AddOutlined, AutoFixHighOutlined, CalendarMonthOutlined, DownloadOutlined, EventAvailableOutlined, KeyboardArrowDownOutlined, KitchenOutlined, LibraryBooksOutlined, SettingsOutlined, ShoppingCartOutlined } from '@mui/icons-material';
import { AppBar, BottomNavigation, BottomNavigationAction, Box, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Theme, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { PrivateRoute } from './components/PrivateRoute';
import { subscribeToLeftovers } from './leftovers';
import { Leftover } from './leftovers/leftover';
import { ShoppingList, subscribeToLists } from './lists';
import { subscribeToPlan } from './meals';
import { Plan } from './meals/meal';
import { AccountPage, LeftoversPage, Login, MealPlan, RecipeList, SharedRecipe, ShoppingListView } from './pages';
import { CompilerPlayground } from './pages/CompilerPlayground';
import NotFound from './pages/NotFound';
import TagDetail from './pages/recipe/TagDetail';
import TagList from './pages/recipe/TagList';
import { RecipeView, subscribeToRecipes } from './recipes';
import { getTags } from './shared/tags';
import { subscribeToStaples } from './staples';
import { StapleSet } from './staples/staple';
import { listenToWorkspace } from './workspaces';

export interface InsideRouterProps {
  theme: Theme;
}

export function InsideRouter({ theme }: InsideRouterProps) {
  const [recipes, setRecipes] = useState<RecipeView[]>([]);
  const [plan, setPlan] = useState<Plan>({ groups: [] });
  const [lists, setLists] = useState<ShoppingList[]>([]);
  const [leftovers, setLeftovers] = useState<Leftover[]>([]);
  const [staples, setStaples] = useState<StapleSet>({});
  const tags = getTags(recipes);

  useEffect(() => subscribeToRecipes(setRecipes), []);
  useEffect(() => subscribeToPlan(setPlan), []);
  useEffect(() => subscribeToLists(setLists), []);
  useEffect(() => subscribeToLeftovers(setLeftovers), []);
  useEffect(() => subscribeToStaples(setStaples), []);
  useEffect(() => listenToWorkspace(), []);

  const isPhone = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  // TODO: Make this respond with router.
  const pathname = window.location.pathname;
  const basePathname = pathname.substr(0, pathname.indexOf('/', 1)) || pathname;
  const [currentPage, setCurrentPage] = useState(basePathname);

  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const create = (route: string) => {
    history.push(route);
    handleClose();
  };

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}>
      <Switch>
        <Route path="/" exact>
          <Login />
        </Route>
        <Route path="*">
          {!isPhone &&
            <AppBar position="relative" color="secondary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
              <Toolbar variant={isDesktop ? 'regular' : 'dense'}>
                <Typography variant="h6" component="div" sx={{ mr: 4 }} color="primary">appetīz</Typography>
                <Button to="/meals" component={Link} color="inherit">Plan</Button>
                <Button to="/recipes" component={Link} color="inherit">Recipes</Button>
                <Button to="/leftovers" component={Link} color="inherit">Leftovers</Button>
                <Button to="/shop" component={Link} color="inherit">Shop</Button>
                <Box sx={{ marginLeft: 4 }}>
                  <Switch>
                    <Route path="/meals">
                      <Button
                        variant="contained"
                        startIcon={<CalendarMonthOutlined />}
                        endIcon={<KeyboardArrowDownOutlined />}
                        onClick={handleClick}
                      >Plan Meal</Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuList disablePadding>
                          <MenuItem onClick={() => create('/meals/new')}>
                            <ListItemIcon><AddOutlined fontSize="small" /></ListItemIcon>
                            <ListItemText>Create New</ListItemText>
                          </MenuItem>
                          <MenuItem onClick={() => create('/meals/suggest')}>
                            <ListItemIcon><AutoFixHighOutlined fontSize="small" /></ListItemIcon>
                            <ListItemText>Suggest Recipes</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Route>
                    <Route path="/recipes">
                      <Button
                        variant="contained"
                        startIcon={<LibraryBooksOutlined />}
                        endIcon={<KeyboardArrowDownOutlined />}
                        onClick={handleClick}
                      >Add Recipe</Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuList disablePadding>
                          <MenuItem onClick={() => create('/recipes/new')}>
                            <ListItemIcon><AddOutlined fontSize="small" /></ListItemIcon>
                            <ListItemText>Create New</ListItemText>
                          </MenuItem>
                          <MenuItem onClick={() => create('/recipes/import')}>
                            <ListItemIcon><DownloadOutlined fontSize="small" /></ListItemIcon>
                            <ListItemText>Import from URL</ListItemText>
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Route>
                    <Route path="/leftovers">
                      <Button variant="contained" startIcon={<KitchenOutlined />} onClick={() => create('/leftovers/new')}>Record Leftover</Button>
                    </Route>
                    <Route path="/shop">
                      <Button variant="contained" startIcon={<EventAvailableOutlined />} onClick={() => create('/shop/plan')}>Add Meals</Button>
                    </Route>
                  </Switch>
                </Box>
                <Box sx={{ flexGrow: 1 }}></Box>
                <IconButton
                  size="medium"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  to="/account"
                  component={Link}
                >
                  <AccountCircleOutlined />
                </IconButton>
              </Toolbar>
            </AppBar>
          }
          <Box sx={{ flex: '1 1 auto', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            <Switch>
              <PrivateRoute path="/meals" exact>
                <MealPlan plan={plan} recipes={recipes} lists={lists} />
              </PrivateRoute>
              <PrivateRoute path="/recipes/new">
                <RecipeList recipes={recipes} tags={tags} />
              </PrivateRoute>
              <PrivateRoute path="/recipes/import">
                <RecipeList recipes={recipes} tags={tags} />
              </PrivateRoute>
              <PrivateRoute path="/recipes/:id">
                <RecipeList recipes={recipes} tags={tags} />
              </PrivateRoute>
              <PrivateRoute path="/recipes">
                <RecipeList recipes={recipes} tags={tags} />
              </PrivateRoute>
              <PrivateRoute path="/meals/new">
                <MealPlan plan={plan} recipes={recipes} lists={lists} />
              </PrivateRoute>
              <PrivateRoute path="/meals/suggest">
                <MealPlan plan={plan} recipes={recipes} lists={lists} />
              </PrivateRoute>
              <PrivateRoute path="/meals/:id">
                <MealPlan plan={plan} recipes={recipes} lists={lists} />
              </PrivateRoute>
              <PrivateRoute path="/shop">
                <ShoppingListView lists={lists} plan={plan} recipes={recipes} staples={staples} />
              </PrivateRoute>
              <PrivateRoute path="/shop/plan">
                <ShoppingListView lists={lists} plan={plan} recipes={recipes} staples={staples} />
              </PrivateRoute>
              <PrivateRoute path="/leftovers">
                <LeftoversPage leftovers={leftovers} />
              </PrivateRoute>
              <PrivateRoute path="/leftovers/new">
                <LeftoversPage leftovers={leftovers} />
              </PrivateRoute>
              <PrivateRoute path="/leftovers/:id">
                <LeftoversPage leftovers={leftovers} />
              </PrivateRoute>
              <PrivateRoute path="/account">
                <AccountPage />
              </PrivateRoute>
              <PrivateRoute path="/tags/:tag">
                <TagDetail recipes={recipes} />
              </PrivateRoute>
              <PrivateRoute path="/tags">
                <TagList tags={tags} />
              </PrivateRoute>
              <Route path="/playground">
                <CompilerPlayground />
              </Route>
              <Route path="/share/recipe/:id">
                <SharedRecipe />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Box>
          {isPhone &&
            <Paper elevation={3}>
              <BottomNavigation
                showLabels
                value={currentPage}
                onChange={(event, newValue) => {
                  setCurrentPage(newValue);
                }}
              >
                <BottomNavigationAction label="Plan" to="/meals" value="/meals" component={Link} icon={<CalendarMonthOutlined />} />
                <BottomNavigationAction label="Leftovers" to="/leftovers" value="/leftovers" component={Link} icon={<KitchenOutlined />} />
                <BottomNavigationAction label="Recipes" to="/recipes" value="/recipes" component={Link} icon={<LibraryBooksOutlined />} />
                <BottomNavigationAction label="Shop" to="/shop" value="/shop" component={Link} icon={<ShoppingCartOutlined />} />
                <BottomNavigationAction label="Settings" to="/account" value="/account" component={Link} icon={<SettingsOutlined />} />
              </BottomNavigation>
            </Paper>
          }
        </Route>
      </Switch>
    </Box>
  );
}