import { AddOutlined, CheckOutlined, CloseOutlined, DownloadOutlined, LibraryBooksOutlined, SearchOutlined } from '@mui/icons-material';
import { AppBar, Box, Button, Chip, Dialog, DialogContent, Fab, IconButton, InputAdornment, List, ListItemButton, ListItemText, ListSubheader, OutlinedInput, Stack, TextField, Toolbar, Typography, Zoom, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, useState } from 'react';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { PageWrapper } from '../../components/PageWrapper';
import { ScrollWrapper } from '../../components/ScrollWrapper';
import SearchHighlight from '../../components/SearchHighlight';
import { GrowTransition, SlideTransition } from '../../components/Transitions';
import { ReactComponent as NoRecipes } from '../../images/NoRecipes.svg';
import { RecipeView } from '../../recipes';
import { importRecipe } from '../../shared/functions';
import { searchAndGroupRecipes } from '../../shared/search';
import RecipeCreate from './RecipeCreate';
import RecipeDetail from './RecipeDetail';

export interface RecipeListProps {
  recipes: RecipeView[];
  tags: string[];
}

export default function RecipeList({ recipes, tags }: RecipeListProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [mainDishFilter, setMainDishFilter] = useState(false);
  const [neverMadeFilter, setNeverMadeFilter] = useState(false);
  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(e?.target.value);
  }

  function getGroups() {
    const filteredRecipes = recipes
      .filter(r => !mainDishFilter || r.isMainDish)
      .filter(r => !neverMadeFilter || !r.history.length);
    return searchAndGroupRecipes(searchTerm, filteredRecipes);
  }

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();
  const individualMatch = useRouteMatch<{ id: string }>('/recipes/:id');
  const id = individualMatch?.params?.id;

  const paramsString = useLocation().search;
  const params = new URLSearchParams(paramsString);

  // TODO: Attempt to extract URL more safely.
  const [importUrl, setImportUrl] = useState(params.get('url') || params.get('text') || params.get('title') || '');
  const [importing, setImporting] = useState(false);
  const [importError, setImportError] = useState('');

  const startImport = async () => {
    if (!importUrl) {
      setImportError('URL is required');
      return;
    }

    setImporting(true);

    try {
      const recipe = await importRecipe(importUrl);

      if (recipe) {
        setImportUrl('');
        history.replace('/recipes/new', { recipe });
      } else {
        setImportError('No recipe found at URL');
      }
    } catch (err: any) {
      if (err.code === 'functions/invalid-argument') {
        setImportError('Invalid URL');
      } else {
        setImportError('And unknown error occurred');
      }
    } finally {
      setImporting(false);
    }
  };

  const cancelImport = () => {
    setImporting(false);
    setImportUrl('');
    setImportError('');
    history.goBack();
  };

  return (
    <PageWrapper>
      {isPhone &&
        <AppBar position="sticky" color="secondary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1, flexShrink: 0, mr: 3 }}>
              Recipes
            </Typography>
            <OutlinedInput
              size="small"
              sx={{
                background: 'rgba(0,0,0,0.03)',
                '&.Mui-focused': { background: 'rgba(0,0,0,0.05)' },
                '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
              }}
              value={searchTerm}
              onChange={handleSearch}
              endAdornment={
                searchTerm
                  ? <InputAdornment position="end"><IconButton onClick={() => setSearchTerm('')} edge="end"><CloseOutlined /></IconButton></InputAdornment>
                  : <InputAdornment position="end"><SearchOutlined /></InputAdornment>
              }
            />
            <IconButton
              sx={{ ml: 1 }}
              onClick={() => history.push('/recipes/import')}
            >
              <DownloadOutlined />
            </IconButton>
          </Toolbar>
        </AppBar>
      }
      {
        isPhone ?
          <Zoom in unmountOnExit>
            <Fab
              color="primary"
              variant="extended"
              sx={{ position: 'fixed', bottom: 70, right: 15, zIndex: 1050 }}
              onClick={() => history.push('/recipes/new')}
            >
              <AddOutlined sx={{ mr: 1 }} />
              Create
            </Fab>
          </Zoom>
          : ''
      }
      <RecipeCreate recipes={recipes} tags={tags} active={id === 'new'} onClose={() => history.replace('/recipes')} />
      <Dialog
        fullScreen={isPhone}
        maxWidth="xs"
        fullWidth
        open={id === 'import'}
        TransitionComponent={isPhone ? SlideTransition : GrowTransition}
        onClose={cancelImport}
      >
        <AppBar position="sticky" color="secondary">
          <Toolbar>
            <IconButton edge="start" color="inherit" sx={{ mr: 1 }} onClick={cancelImport}>
              <CloseOutlined />
            </IconButton>
            <Typography variant="h5" component="div">
              Import Recipe
            </Typography>
            <Box sx={{ flex: '1 1 auto' }}></Box>
            <Button variant="text" color="primary" onClick={startImport} disabled={importing}>Import</Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <TextField
            value={importUrl}
            onChange={e => setImportUrl(e.target.value)}
            onFocus={() => setImportError('')}
            label="Recipe URL"
            fullWidth
            autoFocus
            disabled={importing}
            error={!!importError}
            helperText={importError}
          />
        </DialogContent>
      </Dialog>
      <ScrollWrapper disableGutters maxWidth="sm" padFab centerItems={!recipes.length}>
        {recipes.length ?
          <>
            {!isPhone &&
              <Stack direction="row" spacing={1} alignItems="center" sx={{ paddingLeft: 1, paddingRight: 1, paddingBottom: 1, position: 'sticky', top: '1em', zIndex: 10 }}>
                <TextField
                  size="small"
                  variant="outlined"
                  placeholder="Find Recipes"
                  value={searchTerm}
                  onChange={handleSearch}
                  InputProps={{
                    endAdornment: searchTerm
                      ? <InputAdornment position="end"><IconButton onClick={() => setSearchTerm('')} edge="end"><CloseOutlined /></IconButton></InputAdornment>
                      : <InputAdornment position="end"><SearchOutlined /></InputAdornment>
                  }}
                  fullWidth
                  sx={{ '& .MuiOutlinedInput-root': { background: theme.palette.background.paper } }}
                />
              </Stack>
            }
            <Stack direction="row" gap={1} sx={{ mt: 3, ml: 1 }}>
              <Chip
                size="small"
                icon={mainDishFilter ? <CheckOutlined /> : undefined}
                variant={mainDishFilter ? 'filled' : 'outlined'}
                label="Main Dish"
                onClick={() => setMainDishFilter(!mainDishFilter)}
              />
              <Chip
                size="small"
                icon={neverMadeFilter ? <CheckOutlined /> : undefined}
                variant={neverMadeFilter ? 'filled' : 'outlined'}
                label="Never Made"
                onClick={() => setNeverMadeFilter(!neverMadeFilter)}
              />
            </Stack>
            <RecipeDetail recipe={recipes.find(r => r.id === id)} recipes={recipes} tags={tags} active={!!id && !['new', 'import'].includes(id)} onClose={() => history.goBack()} />
            <List>
              {getGroups().map(({ label, results }) =>
                <Fragment key={`list-header-${label}`}>
                  <ListSubheader disableSticky>{label}</ListSubheader>
                  {results.map(result =>
                    <Fragment key={`recipe-${result.id}`}>
                      <ListItemButton to={`/recipes/${result.id}`} component={Link}>
                        <ListItemText
                          primary={<SearchHighlight text={result.title} searchTerm={searchTerm} />}
                          secondary={result.subtitle && <SearchHighlight text={result.subtitle} searchTerm={searchTerm} />}
                        />
                      </ListItemButton>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </List>
            {!recipes.length &&
              <Box sx={{ textAlign: 'center', marginLeft: 1, marginRight: 1 }}>
                <LibraryBooksOutlined sx={{ fontSize: 100, color: 'text.secondary' }} />
                <Typography gutterBottom variant="h2" component="div">
                  You have no recipes.
                </Typography>
                <Typography gutterBottom variant="body2" color="text.secondary">
                  Try adding one with the "create" button.
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Once you've created a recipe, you can plan it as part of a meal and have ingredients automatically added to your shopping list.
                </Typography>
              </Box>
            }
          </>
          :
          <Box sx={{ textAlign: 'center', marginLeft: 1, marginRight: 1 }}>
            <NoRecipes />
            <Typography gutterBottom variant="h2" component="div">
              Recipes that <Typography variant="h2" component="span" sx={{ color: theme => theme.palette.primary.main, fontWeight: '400' }}>scale</Typography>
            </Typography>
            <Typography gutterBottom variant="body2" color="text.secondary">
              Add or import a recipe to begin.
            </Typography>
          </Box>
        }
      </ScrollWrapper>
    </PageWrapper >
  );
}