import { initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectAuthEmulator, getAuth } from 'firebase/auth';

function getAuthDomain() {
  var domain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? '';
  var match = domain.match(/https?:\/\/(.+):\d+/);
  if (match) {
    return match[1];
  }

  return domain;
}

const appConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: getAuthDomain(),
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export enum Collections { WORKSPACE = 'workspaces', SHARED_RECIPES = 'sharedRecipes' };
export enum WorkspaceCollections { RECIPES = 'recipes', MEALS = 'mealPlans', LISTS = 'lists' };

export interface FirestoreDate { seconds: number }

export const app = initializeApp(appConfig);
export const db = getFirestore(app);
const auth = getAuth();

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  connectAuthEmulator(auth, process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ?? '', { disableWarnings: true });

  const [host, port] = (process.env.REACT_APP_FIREBASE_DATABASE_URL ?? '').split(':');
  connectFirestoreEmulator(db, host, +port);
}

