import { Ingredient } from '../parse-ingredient';
import { unitFromString } from '../units';
import { AstInternalNode, AstLeafNode, AstNode } from './ast';
import { Emitter } from './emitter';
import { IntermediateType } from './grammar';
import { TokenType } from './token';

function internal(node: AstNode | undefined, type: IntermediateType): AstInternalNode | undefined {
  return (node as AstInternalNode)?.children.find(x => x.type === type) as AstInternalNode;
}

function leaf(node: AstNode | undefined, type: TokenType): string | undefined {
  return ((node as AstInternalNode)?.children.find(x => x.type === type) as AstLeafNode)?.value;
}

function leaves(node: AstNode | undefined, types: TokenType[]): string[] {
  return (node as AstInternalNode)?.children
    .filter(x => types.includes(x.type as TokenType))
    .map(x => (x as AstLeafNode).value)
    ?? [];
}

function value(node: AstNode | undefined, types: IntermediateType[], type: TokenType): string | undefined {
  if (!types.length) {
    return leaf(node, type);
  }
  
  const newNode = internal(node, types[0]);
  return value(newNode, types.slice(1), type);
}

function values(node: AstNode | undefined, types: IntermediateType[], type: TokenType[]): string[] {
  if (!types.length) {
    return leaves(node, type);
  }
  
  const newNode = internal(node, types[0]);
  return values(newNode, types.slice(1), type);
}

export class IngredientEmitter implements Emitter<Ingredient> {
  public emit(node: AstNode): Ingredient {
    const number = +(value(node, ['amount'], 'number') ?? 1);
    const unitSizeAmount = value(node, ['full unit', 'unit size'], 'number');
    const unitSizeUnit = values(node, ['full unit', 'unit size', 'unit'], ['whole unit', 'unit fragment']).join(' ');
    const units = values(node, ['full unit', 'unit'], ['whole unit', 'unit fragment']).join(' ');
    const ingredient = values(node, ['full ingredient'], ['preparation', 'ingredient'])?.join(' ') ?? '';

    return {
      minAmount: [{ fraction: [number, 1], unit: unitFromString(units), unitSize: unitSizeAmount ? { fraction: [+unitSizeAmount, 1], unit: unitFromString(unitSizeUnit)} : undefined }],
      maxAmount: [{ fraction: [number, 1], unit: unitFromString(units), unitSize: unitSizeAmount ? { fraction: [+unitSizeAmount, 1], unit: unitFromString(unitSizeUnit)} : undefined }],
      item: ingredient,
      isHeader: false,
    };
  }

  public default(): Ingredient {
    return {
      minAmount: [{ fraction: [0, 1] }],
      maxAmount: [{ fraction: [0, 1] }],
      item: '',
      isHeader: false,
    };
  }
}