import { Fraction } from "./amounts";

const unicodeFractions: { [key: string]: Fraction } = {
  '½': [1, 2],
  '⅓': [1, 3],
  '⅔': [2, 3],
  '¼': [1, 4],
  '¾': [3, 4],
  '⅕': [1, 5],
  '⅖': [2, 5],
  '⅗': [3, 5],
  '⅘': [4, 5],
  '⅙': [1, 6],
  '⅚': [5, 6],
  '⅐': [1, 7],
  '⅛': [1, 8],
  '⅜': [3, 8],
  '⅝': [5, 8],
  '⅞': [7, 8],
  '⅑': [1, 9],
  '⅒': [1, 10],
}

const unicodeNumbers: { [key: string]: number } = {
  '⁰': 0,
  '¹': 1,
  '²': 2,
  '³': 3,
  '⁴': 4,
  '⁵': 5,
  '⁶': 6,
  '⁷': 7,
  '⁸': 8,
  '⁹': 9,
  '₀': 0,
  '₁': 1,
  '₂': 2,
  '₃': 3,
  '₄': 4,
  '₅': 5,
  '₆': 6,
  '₇': 7,
  '₈': 8,
  '₉': 9,
  'π': 3.14,
}

export function parseFraction(raw: string): Fraction {
  const trimmed = raw.trim().replace('and', '');
  if (!trimmed) {
    return [0, 1];
  }

  let whole = 0;
  let num = 0;
  let den = 1;

  let fractionString = trimmed;

  const mixedMatch = fractionString.match(`\\d+[${Object.keys(unicodeFractions).join('')}]`);
  if (mixedMatch) {
    const pos = fractionString.length - 1;
    fractionString = [fractionString.slice(0, pos), ' ', fractionString.slice(pos)].join('');
  }

  const whitespaceMatch = fractionString.match(/\s+/);
  if (whitespaceMatch) {
    let wholeString = '';
    [wholeString, fractionString] = fractionString.split(whitespaceMatch[0]);
    whole = parseNumber(wholeString);
  }

  const slashMatch = fractionString.match(/[/|\u2044]/);
  if (slashMatch) {
    const [numString, denString] = fractionString.split(slashMatch[0]);

    num = parseNumber(numString);
    den = parseNumber(denString);
  }

  if (!num) {
    const unicodeFraction = unicodeFractions[fractionString];

    if (unicodeFraction) {
      [num, den] = unicodeFraction;
    }
  }

  if (!num) {
    const fraction = parseCommonFraction(fractionString);

    if (fraction) {
      [num, den] = fraction;
    }
  }

  if (!num) {
    num = parseNumber(fractionString);
  }

  return [(whole * den) + num, den];
}

function parseCommonFraction(text: string): Fraction | undefined {
  const decimal = parseNumber(text) || 0;
  const whole = Math.floor(decimal);
  const fracPart = decimal % 1;

  for (let den = 2; den <= 32; den *= 2) {
    for (let num = 1; num < den; num++) {
      if (fracPart === num / den) {
        return [(whole * den) + num, den];
      }
    }
  }
}

function parseNumber(text: string): number {
  let res = '';
  for (const letter of text) {
    if (unicodeNumbers[letter] !== undefined) {
      res += unicodeNumbers[letter];
    } else {
      res += letter;
    }
  }

  return parseFloat(res) || 0;
}
