import { useState } from 'react';

export function useToggleList(size: number): [(index: number) => boolean, (index: number) => void] {
  const [checked, setChecked] = useState<boolean[]>(Array(size).fill(false));

  const isChecked = (index: number) => {
    return checked[index] ?? false;
  };

  const toggle = (index: number) => {
    setChecked(checked => {
      const currentIndex = checked[index];
      const newChecked = [...checked];
  
      newChecked[index] = !currentIndex;
  
      return newChecked;
    });
  };

  return [isChecked, toggle];
}