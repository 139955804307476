import { arrayRemove, arrayUnion, collection, doc, Firestore, getDoc, onSnapshot, query, runTransaction, serverTimestamp, setDoc, Unsubscribe, updateDoc, where } from "firebase/firestore";
import { Collections, FirestoreDate, WorkspaceCollections } from "../shared/firebase";
import { RecipeHistoryEntry } from './recipe';
import { RecipeRepository } from './repository';

const LAST_CACHE_SYNC_KEY = 'lastSync-recipes';

export interface FirestoreRecipe {
  id: string;
  name: string;
  ingredients: string;
  instructions: string;
  yield: string | number;
  yieldLabel: string;
  prepTime: string | number;
  prepUnit?: number;
  cookTime: string | number;
  cookUnit?: number;
  extraTime: string | number;
  extraUnit?: number;
  notes: string;
  author: string;
  source: string;
  temperature: number;
  tags: string[];
  isMainDish: boolean;
  updated: Date;
  deleted?: boolean;
  history?: FirestoreRecipeHistoryEntry[];
  linkedRecipeIds?: string[];
  sides?: string[];
}

export interface FirestoreRecipeHistoryEntry {
  date: FirestoreDate; 
  meal: string;
  hadLeftovers: boolean;
  scale: string;
}


export class RecipeFirestoreListener {
  constructor(
    private firestore: Firestore,
    private repository: RecipeRepository,
  ) { }

  public listen(workspaceId: string): Unsubscribe {
    const recipes = collection(this.firestore, Collections.WORKSPACE, workspaceId, WorkspaceCollections.RECIPES);
    const recipeQuery = query(recipes, where('updated', '>=', new Date(localStorage.getItem(`${LAST_CACHE_SYNC_KEY}-${workspaceId}`) || '2000-01-01')));

    return onSnapshot(recipeQuery, async (snapshot) => {
      localStorage.setItem(`${LAST_CACHE_SYNC_KEY}-${workspaceId}`, new Date().toUTCString());

      const recipes = snapshot.docs
        .map(doc => ({ id: doc.id, workspaceId, ...doc.data() } as unknown as FirestoreRecipe));

      if (!recipes.length) {
        return;
      }

      await this.repository.setLocal(recipes);
    });
  }
}

export class RecipeFirestoreClient {
  constructor(private firestore: Firestore) { }

  public async saveRecipe(workspaceId: string, recipe: FirestoreRecipe): Promise<void> {
    await runTransaction(this.firestore, async transaction => {
      transaction.update(doc(this.firestore, Collections.WORKSPACE, workspaceId), { recipeIds: arrayUnion(recipe.id) });
      transaction.set(doc(this.firestore, Collections.WORKSPACE, workspaceId, WorkspaceCollections.RECIPES, recipe.id), recipe);
    });
  }

  public async deleteRecipe(workspaceId: string, recipeId: string): Promise<void> {
    await runTransaction(this.firestore, async transaction => {
      transaction.update(doc(this.firestore, Collections.WORKSPACE, workspaceId), { recipeIds: arrayRemove(recipeId) });
      transaction.set(doc(this.firestore, Collections.WORKSPACE, workspaceId, WorkspaceCollections.RECIPES, recipeId), { id: recipeId, updated: new Date(), deleted: true });
    });
  }

  public async addHistory(workspaceId: string, recipeId: string, entry: RecipeHistoryEntry): Promise<void> {
    await updateDoc(doc(this.firestore, Collections.WORKSPACE, workspaceId, WorkspaceCollections.RECIPES, recipeId), { history: arrayUnion(entry), updated: serverTimestamp() })
  }

  public async shareRecipe(recipeDoc: FirestoreRecipe): Promise<void> {
    await setDoc(doc(this.firestore, Collections.SHARED_RECIPES, recipeDoc.id), recipeDoc);
  }

  public async getSharedRecipe(id: string): Promise<FirestoreRecipe | undefined> {
    const firebaseRecipe = await getDoc(doc(this.firestore, Collections.SHARED_RECIPES, id));

    if (!firebaseRecipe?.data()) {
      return;
    }

    return { id: firebaseRecipe.id, ...firebaseRecipe.data() } as unknown as FirestoreRecipe;
  }
}