import { AstInternalNode, AstLeafNode, AstNode } from './ast';
import { Transformer } from './transformer';

// TODO: Consider splitting this into multiple, more focused transformers.

// TODO: Add unit merge transformer.
export class NormalizingTransformer implements Transformer {
  public transform(ast: AstInternalNode): AstNode {
    const amount = this.getAmount(ast);
    const unit = this.getFullUnit(ast);
    const ingredient = this.getFullIngredient(ast);

    return {
      type: 'root',
      children: [amount, unit, ingredient]
        .filter(x => x)
        .map(x => x as AstNode),
    };
  }

  private getAmount(node: AstInternalNode): AstNode | undefined {
    const amount = node.children.find(x => x.type === 'amount');
    const unit = this.getFullUnit(node);

    if (amount) {
      return amount;
    }

    if (!unit) {
      return undefined;
    }

    return {
      type: 'amount', children: [
        { type: 'number', value: '1' }
      ]
    };
  }

  private getFullUnit(node: AstInternalNode): AstNode | undefined {
    return node.children.find(x => x.type === 'full unit');
    // TODO: Transform unit amounts.
  }

  private getFullIngredient(node: AstInternalNode): AstNode {
    const fullIngredient = node.children.find(x => x.type === 'full ingredient') as AstInternalNode;

    const preparation = fullIngredient.children.find(x => x.type === 'preparation') as AstLeafNode | undefined;
    const ingredient = fullIngredient.children.find(x => x.type === 'ingredient') as AstLeafNode;

    return {
      'type': 'full ingredient',
      children: [preparation, ingredient]
        .filter(x => x)
        .map(x => x as AstNode),
    };
  }
}