import { db as localDb } from "../shared/database";
import { EventBusConsumer, EventBusProducer, Events } from '../shared/events';
import { db as firestore } from "../shared/firebase";
import { unwrapPromise } from '../shared/subscriptions';
import { ActiveWorkspaceFirestoreClient } from '../workspaces/firestore';
import { RecipeFirestoreClient } from "./firestore";
import { RecipeHistoryEntry, RecipeView, ShareRecipeOptions } from './recipe';
import { RecipeRepository } from "./repository";
export type { RecipeView } from './recipe';

type Callback = (recipes: RecipeView[]) => void;

const repository = new RecipeRepository(
  localDb,
  new EventBusProducer(),
  new RecipeFirestoreClient(firestore),
);

const consumer = new EventBusConsumer();
const producer = new EventBusProducer();

const workspaceClient = new ActiveWorkspaceFirestoreClient(producer);

export function subscribeToRecipes(callback: Callback): () => void {
  return unwrapPromise(cb => subscribeInternal(cb), callback);
}

async function subscribeInternal(callback: Callback): Promise<() => void> {
  return consumer.subscribe(Events.RECIPES_CHANGED, async () => {
    const workspaceId = await workspaceClient.getActiveId();

    if (workspaceId) {
      const recipes = await repository.getRecipes(workspaceId);
      callback(recipes);
    }
  });
}

export async function saveRecipe(recipe: RecipeView): Promise<void> {
  const workspaceId = await workspaceClient.getActiveId();

  if (workspaceId) {
    return repository.save(workspaceId, recipe);
  }
}

export async function addHistory(recipeId: string, entry: RecipeHistoryEntry): Promise<void> {
  const workspaceId = await workspaceClient.getActiveId();

  if (workspaceId) {
    return repository.addHistory(workspaceId, recipeId, entry);
  }
}

export async function deleteRecipe(recipeId: string): Promise<void> {
  const workspaceId = await workspaceClient.getActiveId();

  if (workspaceId) {
    return repository.delete(workspaceId, recipeId);
  }
}

export async function shareRecipe(recipe: RecipeView, options: ShareRecipeOptions = {}): Promise<string> {
  const id = await repository.shareRecipe(recipe, options);
  return `${window.location.protocol}//${window.location.host}/share/recipe/${id}`;
}

export async function getSharedRecipe(id: string): Promise<RecipeView | undefined> {
  return repository.getSharedRecipe(id);
}
