import { LocalDb, Stores } from '../shared/database';
import { EventBusProducer, Events } from '../shared/events';
import { FirestoreList, FirestoreListItem, ListFirestoreClient } from './firestore';
import { ShoppingList } from './list';

export class ListRepository {
  constructor(
    private db: Promise<LocalDb>,
    private producer: EventBusProducer,
    private client: ListFirestoreClient,
  ) { }

  public async setLocal(lists: FirestoreList[]): Promise<void> {
    const tx = (await this.db).transaction(Stores.LISTS, 'readwrite');
    await Promise.all([
      ...lists.map(l => tx.store.put(l, l.id)),
      tx.done,
    ]);
    this.producer.emit(Events.LISTS_CHANGED);
  }

  public async getLists(workspaceId: string): Promise<ShoppingList[]> {
    const index = (await this.db).transaction(Stores.LISTS).store.index('by-workspace');
    const lists = await index.getAll(workspaceId);
    return lists.map(l => this.mapListFromDatabase(l));
  }

  private mapListFromDatabase(list: FirestoreList): ShoppingList {
    return {
      id: list.id,
      title: list.title,
      items: list.items.map(item => ({
        title: item.title,
        parenthetical: item.parenthetical,
        checked: item.checked,
        meal: item.mealData ? {
          mealId: item.mealData.mealId,
          recipeTitle: item.mealData.recipeTitle,
          amount: item.mealData.amount,
        } : undefined,
      }))
    };
  }

  public async save(workspaceId: string, list: ShoppingList): Promise<void> {
    const firestoreList: FirestoreList = {
      id: list.id,
      title: list.title,
      items: list.items.map(item => {
        const res: FirestoreListItem = {
          title: item.title,
          checked: item.checked,
        };

        if (item.meal) {
          res.mealData = {
            mealId: item.meal.mealId,
            recipeTitle: item.meal.recipeTitle,
            amount: item.meal.amount,
          }
        };

        if (item.parenthetical) {
          res.parenthetical = item.parenthetical;
        }

        return res;
      }),
      updated: new Date(),
    };

    this.client.save(workspaceId, firestoreList);
  }
}