import { getAuth, signOut } from '@firebase/auth';
import { AppBar, Box, Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Skeleton, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { PageWrapper } from '../components/PageWrapper';
import { ScrollWrapper } from '../components/ScrollWrapper';
import { WorkspaceView, getWorkspaces, resetActiveWorkspace, setActiveWorkspace, subscribeToActiveWorkspace } from '../workspaces';

export default function AccountPage() {
  const [user, setUser] = useState<any>(null);
  const [active, setActive] = useState<WorkspaceView>({} as WorkspaceView);
  const [workspaces, setWorkspaces] = useState<WorkspaceView[]>([]);

  useEffect(() => getAuth().onAuthStateChanged(setUser), []);
  useEffect(() => subscribeToActiveWorkspace(setActive), []);
  useEffect(() => { getWorkspaces().then(setWorkspaces) }, []);

  function logOut() {
    signOut(getAuth()).then(resetActiveWorkspace);
  }

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <PageWrapper>
      {isPhone &&
        <AppBar position="sticky" color="secondary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <Typography variant="h5">
              Settings
            </Typography>
          </Toolbar>
        </AppBar>
      }
      <ScrollWrapper maxWidth="sm" padTop>
        <Stack spacing={3}>
          <Box>
            <Typography variant="h3">Account</Typography>
            <Typography variant="subtitle1">{user?.email ?? <Skeleton />}</Typography>
            <Button onClick={logOut}>Sign Out</Button>
          </Box>
          <Divider variant="middle" />
          <Box>
            <Typography variant="h3">Workspaces</Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="workspace"
                name="radio-buttons-group"
                value={active?.id ?? ''}
                onChange={(_, value) => setActiveWorkspace(value)}
              >
                {workspaces.map(workspace =>
                  <FormControlLabel
                    key={workspace.id}
                    value={workspace?.id ?? ''}
                    control={<Radio />}
                    label={workspace?.name ?? ''}
                  />
                )}
              </RadioGroup>
            </FormControl>
          </Box>
        </Stack>
      </ScrollWrapper>
    </PageWrapper>
  );
}
