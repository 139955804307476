import { LocalDb, Stores } from '../shared/database';
import { FirestoreStapleSet, StapleFirestoreClient } from './firestore';
import { StapleSet } from './staple';

export class StapleRepository {
  constructor(private db: Promise<LocalDb>, private client: StapleFirestoreClient) { }

  public async getStaples(workspaceId: string): Promise<StapleSet> {
    const workspace = await (await this.db).get(Stores.WORKSPACES, workspaceId);
    const staples = workspace?.staples ?? {};

    return this.mapFromDatabase(staples);
  }

  public async update(workspaceId: string, checkedItems: string[], uncheckedItems: string[]): Promise<void> {
    const staples = await this.getStaples(workspaceId);
    const normalizedChecked = new Set(checkedItems.map(i => i.trim().toLowerCase()));
    const normalizedUnchecked = new Set(uncheckedItems.map(i => i.trim().toLowerCase()));

    for (const item of normalizedUnchecked) {
      const newWeight = (staples[item]?.weight ?? 0) + 1;
      staples[item] = { weight: Math.min(newWeight, 5) };
    }

    for (const item of normalizedChecked) {
      const newWeight = (staples[item]?.weight ?? 0) - 1;

      if (newWeight > 0) {
        staples[item] = { weight: newWeight };
      } else {
        delete staples[item];
      }
    }

    return this.client.updateStaples(workspaceId, this.mapToDatabase(staples));
  }

  private mapFromDatabase(staples: FirestoreStapleSet): StapleSet {
    return Object.fromEntries(
      Object.entries(staples)
        .map(([key, value]) => [key, { weight: value.weight }])
    );
  }

  private mapToDatabase(staples: StapleSet): FirestoreStapleSet {
    return Object.fromEntries(
      Object.entries(staples)
        .map(([key, value]) => [key, { weight: value.weight }])
    );
  }
}