import { ExpandMoreOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MealView, saveMeal, updateIngredient, updateInstruction } from '../meals';
import { parseFraction } from '../parser/parse-fraction';
import { RecipeView as Recipe } from '../recipes';
import { useScale } from '../shared/hooks/useScale';
import { useToggleList } from '../shared/hooks/useToggleList';
import RecipeView from './RecipeView';

interface MealRecipeViewProps {
  recipe: Recipe;
  recipes: Recipe[];
  meal: MealView;
}

export function MealRecipeView({ recipe, recipes, meal }: MealRecipeViewProps) {
  const [isIngredientChecked, toggleIngredient] = useToggleList(recipe.parsedIngredients.length);
  const [isInstructionChecked, toggleInstruction] = useToggleList(recipe.parsedInstructions.length);
  const [standardScale, customScale, currentScale, setCurrentScale] = useScale();

  const theme = useTheme();

  const setCurrentScaleWrapper = (scale: string) => {
    // TODO: Not this, probably.
    meal.recipeState[recipe.id] = { ...meal.recipeState[recipe.id], scale };

    setCurrentScale(scale);
    saveMeal(meal);
  };

  const toggleIngredientWrapper = (index: number) => {
    toggleIngredient(index);
    updateIngredient(meal.id, recipe.id, index, !isIngredientChecked(index));
  };

  const toggleInstructionWrapper = (index: number) => {
    toggleInstruction(index);
    updateInstruction(meal.id, recipe.id, index, !isInstructionChecked(index));
  };

  useEffect(() => {
    const localFraction = currentScale.fraction;
    const remoteFraction = parseFraction(meal.recipeState[recipe.id]?.scale ?? '1');

    if (localFraction[0] !== remoteFraction[0] || localFraction[1] !== remoteFraction[1]) {
      setCurrentScale(meal.recipeState[recipe.id].scale);
    }
  }, [meal, recipe, setCurrentScale, currentScale]);

  useEffect(() => {
    for (let i = 0; i < recipe.parsedIngredients.length; i++) {
      const localState = isIngredientChecked(i);
      const remoteState = meal.recipeState[recipe.id]?.checkedIngredients?.[i] ?? false;

      if (localState !== remoteState) {
        toggleIngredient(i);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meal, recipe]);

  useEffect(() => {
    for (let i = 0; i < recipe.parsedInstructions.length; i++) {
      const localState = isInstructionChecked(i);
      const remoteState = meal.recipeState[recipe.id]?.checkedInstructions?.[i] ?? false;

      if (localState !== remoteState) {
        toggleInstruction(i);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipe, meal]);

  return (
    <Grid item xs={12} key={`meal-embedded-${recipe.id}`}>
      <Accordion defaultExpanded sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          sx={{
            backgroundColor: theme => theme.palette.background.default,
            padding: 0
          }}
          expandIcon={<ExpandMoreOutlined />}
        >
          <Typography variant="h3">
            <Link
              to={`/recipes/${recipe.id}`}
              style={{ textDecoration: 'none', color: theme.palette.text.primary }}
            >{recipe.name}</Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: theme => theme.palette.background.default,
            padding: 0
          }}
        >
          <RecipeView
            recipe={recipe}
            recipes={recipes}
            isIngredientChecked={isIngredientChecked}
            toggleIngredient={toggleIngredientWrapper}
            isInstructionChecked={isInstructionChecked}
            toggleInstruction={toggleInstructionWrapper}
            standardScale={standardScale}
            customScale={customScale}
            currentScale={currentScale}
            setCurrentScale={setCurrentScaleWrapper}
            embedded
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}