import { deleteField, doc, Firestore, setDoc } from 'firebase/firestore';
import { Collections } from '../shared/firebase';

export interface FirestoreLeftover {
  id: string;
  name: string;
  mealName?: string;
  dateMade: { seconds: number };
}

export class LeftoverFirestoreClient {
  constructor(private firestore: Firestore) { }

  public async save(workspaceId: string, leftover: FirestoreLeftover): Promise<void> {
    setDoc(doc(this.firestore, Collections.WORKSPACE, workspaceId), { leftovers: {[leftover.id]: leftover } }, { merge: true });
  }

  public async delete(workspaceId: string, leftoverId: string): Promise<void> {
    setDoc(doc(this.firestore, Collections.WORKSPACE, workspaceId), { leftovers: {[leftoverId]: deleteField()} }, { merge: true });
  }
}