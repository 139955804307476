import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { InsideRouter } from './InsideRouter';

// TODO: Add offline detection / disable edits.

export default function AppComponent() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme =
    createTheme({
      palette: {
        primary: {
          main: '#7c6cf5'
        },
        secondary: {
          main: '#f1f0f9',
          dark: '#bbb3ff',
        },
        mode: prefersDarkMode ? 'dark' : 'light'
      },
      typography: {
        fontFamily: [
          'Montserrat',
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        h1: {
          fontSize: '3rem'
        },
        h2: {
          fontSize: '2.125rem'
        },
        h3: {
          fontSize: '1.5rem'
        },
        h4: {
          fontSize: '1.25rem'
        },
        h5: {
          fontSize: '1.125rem'
        },
        h6: {
          fontSize: '1rem'
        },
      },
      shape: {
        borderRadius: 6
      },
      components: {
        MuiInputLabel: {
          styleOverrides: {
            root: {
              zIndex: 'auto'
            }
          }
        },
        MuiAppBar: {
          styleOverrides: {
            root: {
              boxShadow: 'none'
            }
          }
        }
      }
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router>
          <InsideRouter theme={theme} />
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
