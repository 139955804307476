import { collection, doc, Firestore, onSnapshot, query, setDoc, Unsubscribe, where } from 'firebase/firestore';
import { Collections, WorkspaceCollections } from '../shared/firebase';
import { ListRepository } from './repository';

const LAST_CACHE_SYNC_KEY = 'lastSync-lists';

export interface FirestoreList {
  id: string;
  title: string;
  items: FirestoreListItem[];
  updated: Date;
}

export interface FirestoreListItem {
  title: string;
  parenthetical?: string;
  checked: boolean;
  mealData?: FirestoreListItemMeal;
}

export interface FirestoreListItemMeal {
  mealId: string;
  recipeTitle: string;
  amount: string;
}

export class ListFirestoreListener {
  constructor(
    private firestore: Firestore,
    private repository: ListRepository,
  ) { }

  public listen(workspaceId: string): Unsubscribe {
    const lists = collection(this.firestore, Collections.WORKSPACE, workspaceId, WorkspaceCollections.LISTS);
    const listQuery = query(lists, where('updated', '>=', new Date(localStorage.getItem(`${LAST_CACHE_SYNC_KEY}-${workspaceId}`) || '2000-01-01')));

    return onSnapshot(listQuery, async (snapshot) => {
      localStorage.setItem(`${LAST_CACHE_SYNC_KEY}-${workspaceId}`, new Date().toUTCString());

      const lists = snapshot.docs
        .map(doc => ({ id: doc.id, workspaceId, ...doc.data() } as unknown as FirestoreList));

      if (!lists.length) {
        return;
      }

      await this.repository.setLocal(lists);
    });
  }
}

export class ListFirestoreClient {
  constructor(private db: Firestore) { }

  public async save(workspaceId: string, list: FirestoreList): Promise<void> {
    const listDoc = doc(this.db, Collections.WORKSPACE, workspaceId, WorkspaceCollections.LISTS, list.id);

    // Update or create if the list doesn't exist.
    await setDoc(listDoc, list, { merge: true });
  }
}