import { useState } from 'react';
import { RecipeView } from '../recipes';
import { Plan } from './meal';
import { getDayDiff } from '../shared/dates';
import pluralize from 'pluralize';

// TODO: Do... something with this file.

export interface Suggestion {
  recipeId: string;
  name: string;
  description: string;
  checked: boolean;
  recipeIds: string[];
  extras: string[];
}

const skippedRecipes: string[] = [];

export function skipRecipe(id: string) {
  skippedRecipes.push(id);
}

let persistentSuggestions: Suggestion[] = [];
const ACTIVE_SUGGESTIONS = 10;

export function useSuggestions(plan: Plan, recipes: RecipeView[]): [
  Suggestion[],
  (suggestion: Suggestion, checked: boolean) => void,
  (suggestion: Suggestion) => void,
  (suggestion: Suggestion, recipeIds: string[]) => void,
  (suggestion: Suggestion, extras: string[]) => void
] {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  if (suggestions !== persistentSuggestions && plan.groups.length && recipes.length) {

    const newSuggestions = [...persistentSuggestions];
    for (let i = newSuggestions.length; i < ACTIVE_SUGGESTIONS; i++) {
      const suggestion = getSuggestion(plan, recipes, newSuggestions);
      if (suggestion) {
        newSuggestions.push(suggestion);
      }
    }

    setSuggestions(newSuggestions);
    persistentSuggestions = newSuggestions;
  }

  const checkSuggestion = (suggestion: Suggestion, checked: boolean) => {
    const index = suggestions.indexOf(suggestion);

    const newSuggestion = { ...suggestion, checked };

    if (!checked) {
      newSuggestion.recipeIds = [];
      newSuggestion.extras = [];
    }

    const newSuggestions = [...suggestions];
    newSuggestions.splice(index, 1, newSuggestion);

    setSuggestions(newSuggestions);
    persistentSuggestions = newSuggestions;
  };

  const removeSuggestion = (suggestion: Suggestion) => {
    const index = suggestions.indexOf(suggestion);

    const newSuggestions = [...suggestions];
    newSuggestions.splice(index, 1);

    skipRecipe(suggestion.recipeId);
    const newSuggestion = getSuggestion(plan, recipes, suggestions);
    if (newSuggestion) newSuggestions.push(newSuggestion);

    setSuggestions(newSuggestions);
    persistentSuggestions = newSuggestions;
  };

  const setRecipes = (suggestion: Suggestion, recipeIds: string[]) => {
    const index = suggestions.indexOf(suggestion);

    const newSuggestions = [...suggestions];
    newSuggestions.splice(index, 1, { ...suggestion, recipeIds });

    setSuggestions(newSuggestions);
    persistentSuggestions = newSuggestions;
  };

  const setExtras = (suggestion: Suggestion, extras: string[]) => {
    const index = suggestions.indexOf(suggestion);

    const newSuggestions = [...suggestions];
    newSuggestions.splice(index, 1, { ...suggestion, extras });

    setSuggestions(newSuggestions);
    persistentSuggestions = newSuggestions;
  };

  return [
    filterSuggestions(plan, persistentSuggestions),
    checkSuggestion,
    removeSuggestion,
    setRecipes,
    setExtras,
  ];
}

function filterSuggestions(plan: Plan, current: Suggestion[]): Suggestion[] {
  return current
    .filter(x => !plan.groups.some(g => g.meals.some(m => m.recipeIds.some(id => id === x.recipeId))));
}

export function getSuggestion(plan: Plan, recipes: RecipeView[], current: Suggestion[]): Suggestion | undefined {
  const filteredRecipes = recipes
    .filter(r => r.isMainDish)
    .filter(r => !skippedRecipes.includes(r.id))
    .filter(r => !current.some(dr => dr.recipeId === r.id))
    .filter(r => !plan.groups.some(g => g.meals.some(m => m.recipeIds.some(id => id === r.id))));
  const suggestion = selectRandom(filteredRecipes);

  if (suggestion) {
    const lastMade = getLastMade(suggestion);

    return {
      recipeId: suggestion.id,
      name: suggestion.name,
      description: getDateLabel(lastMade),
      checked: false,
      recipeIds: [],
      extras: [],
    };
  }
}

const LOG_BASE = 3;
const CUTOFF_MONTHS = 6;

function getLastMade(recipe: RecipeView): Date | undefined {
  const dates = recipe.history.map(x => x.date);
  dates.sort((a, b) => b.getTime() - a.getTime());
  return dates[0];
}

function getWeight(recipe: RecipeView): number {
  const lastMade = getLastMade(recipe) ?? new Date(new Date().setMonth(new Date().getMonth() - CUTOFF_MONTHS));
  const dayDiff = Math.max(1, Math.min(30 * CUTOFF_MONTHS, getDayDiff(lastMade, new Date())));
  return Math.max(1, Math.log(dayDiff) / Math.log(LOG_BASE));
}

function selectRandom(array: RecipeView[]): RecipeView | undefined {
  const weights = array.map(getWeight);

  var totalWeight = weights.reduce((sum, weight) => sum + weight, 0);
  var randomIndex = Math.floor(Math.random() * totalWeight);

  for (var i = 0; i < weights.length; i++) {
    randomIndex -= weights[i];
    if (randomIndex < 0) {
      return array[i];
    }
  }
}

function getDateLabel(lastMade: Date | undefined) {
  if (!lastMade) {
    return 'Never made';
  }

  const dayDiff = getDayDiff(lastMade, new Date());

  if (dayDiff === 0) {
    return 'Made today';
  }

  if (dayDiff === 1) {
    return 'Made yesterday';
  }

  if (dayDiff < 8) {
    return `Made ${dayDiff} ${pluralize('days', dayDiff)} ago`
  }

  if (dayDiff < 29) {
    return `Made ${Math.floor(dayDiff / 7)} ${pluralize('weeks', Math.floor(dayDiff / 7))} ago`
  }

  if (dayDiff < 365) {
    return `Made ${Math.floor(dayDiff / 30)} ${pluralize('months', Math.floor(dayDiff / 30))} ago`
  }

  return `Made ${Math.floor(dayDiff / 365)} ${pluralize('years', Math.floor(dayDiff / 365))} ago`
}
