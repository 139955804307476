import { CloseOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Checkbox, Dialog, DialogContent, Grow, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Slide, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, forwardRef, useEffect, useState } from 'react';
import { ReactComponent as NoPlanIngredients } from '../images/NoPlanIngredients.svg';
import { ShoppingListItem } from '../lists';
import { MealView } from '../meals';
import { Fraction } from '../parser/amounts';
import { parseFraction } from '../parser/parse-fraction';
import { Ingredient, scaleIngredient } from '../parser/parse-ingredient';
import { prettifyRange } from '../parser/test-helpers';
import { RecipeView } from '../recipes';
import { StapleSet } from '../staples/staple';
import IngredientView from './IngredientView';

export interface AddToListDialogProps {
  open: boolean;
  onCancel: () => void;
  onSave: (items: ShoppingListItem[], meals: MealView[], staples: string[]) => void;
  meals: MealView[];
  recipes: RecipeView[];
  staples: StapleSet;
}

interface ListMeal {
  id: string;
  name: string;
  checked: boolean;
  recipes: ListRecipe[];
  extras: ListExtra[];
}

interface ListRecipe {
  id: string;
  name: string;
  items: ListIngredient[];
  scale: Fraction;
}

interface ListIngredient {
  checked: boolean;
  ingredient: Ingredient;
}

interface ListExtra {
  checked: boolean;
  name: string;
}

const GrowTransition = forwardRef(function Transition(props: any, ref) {
  return <Grow ref={ref} {...props}>{props.children}</Grow>;
});

const SlideTransition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props}>{props.children}</Slide>;
});

export function AddToListDialog({ open, onCancel, onSave, meals, recipes, staples }: AddToListDialogProps) {
  const [listMeals, setListMeals] = useState<ListMeal[]>([]);

  useEffect(() => {
    function isStaple(item: string) {
      return (staples[item.trim().toLowerCase()]?.weight ?? 0) >= 3;
    }

    if ((meals.length && recipes.length && !listMeals.length) || !open) {
      const filteredMeals = meals
        .filter(m => m.recipeIds.length || m.extras.filter(e => e.trim()).length);

      const mealsInit = filteredMeals.map<ListMeal>(m => ({
        id: m.id,
        name: m.displayName,
        checked: !m.addedToList && !!m.planDate,
        recipes: m.recipeIds
          .map(id => recipes.find(r => r.id === id))
          .filter(r => r)
          .map(r => r as RecipeView)
          .map<ListRecipe>(r => ({
            id: r.id,
            name: r.name,
            items: r.parsedIngredients.map(i => ({
              checked: !isStaple(i.item),
              ingredient: i,
            })),
            scale: m.recipeState[r.id]?.scale ? parseFraction(m.recipeState[r.id].scale) : [1, 1]
          })),
        extras: m.extras.filter(e => e).map(e => ({
          checked: !isStaple(e),
          name: e,
        })),
      }));

      setListMeals(mealsInit);
    }
  }, [meals, recipes, open, listMeals.length, staples]);

  const toggleMeal = (meal: ListMeal, checked: boolean) => {
    const mealsCopy = [...listMeals];
    const mealCopy = { ...meal, checked };
    const mealIndex = mealsCopy.indexOf(meal);

    mealsCopy.splice(mealIndex, 1, mealCopy);
    setListMeals(mealsCopy);
  };

  const toggleIngredient = (meal: ListMeal, recipe: ListRecipe, ingredient: ListIngredient) => {
    const recipeItemsCopy = [...recipe.items];
    const index = recipeItemsCopy.indexOf(ingredient);

    recipeItemsCopy.splice(index, 1, { ...ingredient, checked: !ingredient.checked });

    const recipeCopy = { ...recipe, items: recipeItemsCopy };
    const mealRecipesCopy = [...meal.recipes];
    const recipeIndex = mealRecipesCopy.indexOf(recipe);

    mealRecipesCopy.splice(recipeIndex, 1, recipeCopy);

    const mealCopy = { ...meal, recipes: mealRecipesCopy };
    const mealsCopy = [...listMeals];
    const mealIndex = mealsCopy.indexOf(meal);

    mealsCopy.splice(mealIndex, 1, mealCopy);

    setListMeals(mealsCopy);
  };

  const toggleExtra = (meal: ListMeal, extra: ListExtra) => {
    const mealExtrasCopy = [...meal.extras];
    const extraIndex = mealExtrasCopy.indexOf(extra);
    const extraCopy = { ...extra, checked: !extra.checked };

    mealExtrasCopy.splice(extraIndex, 1, extraCopy);

    const mealsCopy = [...listMeals];
    const mealIndex = mealsCopy.indexOf(meal);
    const mealCopy = { ...meal, extras: mealExtrasCopy };

    mealsCopy.splice(mealIndex, 1, mealCopy);

    setListMeals(mealsCopy);
  };

  const save = () => {
    const items = listMeals
      .filter(m => m.checked)
      .flatMap(m => {
        const recipeItems = m.recipes
          .flatMap(r => r.items
            .filter(i => !i.ingredient.isHeader)
            .filter(i => i.checked)
            .map<ShoppingListItem>(i => {
              const scaledIngredient = scaleIngredient(i.ingredient, r.scale);

              return {
                title: i.ingredient.item,
                parenthetical: i.ingredient.parenthetical,
                checked: false,
                meal: {
                  mealId: m.id,
                  recipeTitle: r.name,
                  amount: prettifyRange(scaledIngredient.minAmount, scaledIngredient.maxAmount),
                },
              };
            })
          );
        const extraItems = m.extras
          .filter(e => e)
          .filter(e => e.checked)
          .map<ShoppingListItem>(e => ({
            title: e.name,
            checked: false,
            meal: {
              mealId: m.id,
              recipeTitle: `${m.name} (extras)`,
              amount: '',
            },
          }));

        return [...recipeItems, ...extraItems];
      });

    const uncheckedItems = listMeals
      .filter(m => m.checked)
      .flatMap(m => m.recipes
        .flatMap(r => r.items
          .filter(i => !i.ingredient.isHeader)
          .filter(i => !i.checked)
          .map<string>(i => i.ingredient.item)
        )
      );

    const shoppedMeals = listMeals
      .filter(m => m.checked)
      .map(m => meals.find(meal => meal.id === m.id))
      .filter(m => m)
      .map(m => m as MealView);

    onSave(items, shoppedMeals, uncheckedItems);
  };

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      fullScreen={isPhone}
      TransitionComponent={isPhone ? SlideTransition : GrowTransition}
    >
      <AppBar position="sticky" color="secondary">
        <Toolbar>
          <IconButton edge="start" color="inherit" sx={{ mr: 1 }} onClick={onCancel}>
            <CloseOutlined />
          </IconButton>
          <Typography variant="h5" component="div">
            Add Meal Ingredients
          </Typography>
          <Box sx={{ flex: '1 1 auto' }}></Box>
          {!!listMeals.length && <Button variant="text" color="primary" onClick={save}>Save</Button>}
        </Toolbar>
      </AppBar>
      <DialogContent sx={{
        paddingLeft: 0,
        paddingRight: 0,
        display: listMeals.length ? 'initial' : 'grid',
        placeContent: listMeals.length ? 'initial' : 'center'
      }}>
        {listMeals.length ?
          listMeals.map(meal =>
            <Fragment key={`shop-meal-${meal.id}`}>
              <Accordion
                expanded={meal.checked}
                disableGutters
                sx={{
                  boxShadow: 'none',
                  '&::before': { height: 0 }
                }}
              >
                <AccordionSummary
                  sx={{ '.MuiAccordionSummary-content': { margin: 0 } }}
                  onClick={() => toggleMeal(meal, !meal.checked)}
                >
                  <Stack sx={{ alignItems: 'center' }} direction="row">
                    <Checkbox checked={meal.checked} onChange={(_, checked) => toggleMeal(meal, checked)} sx={{ marginRight: 1 }} />
                    <Typography variant="h5">{meal.name}</Typography>
                    <Box sx={{ flexGrow: 1 }} />
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingLeft: 6, paddingTop: 0, paddingBottom: 1, paddingRight: 0 }}>
                  <List dense>
                    <Fragment>
                      {meal.recipes.map(recipe =>
                        <Fragment key={`shop-${recipe.id}`}>
                          <ListSubheader disableGutters disableSticky>{recipe.name}</ListSubheader>
                          {recipe.items.filter(i => !i.ingredient.isHeader).map((item, index) =>
                            <ListItemButton
                              disableGutters
                              key={`shop-${recipe.id}-${index}`}
                              onClick={() => toggleIngredient(meal, recipe, item)}
                            >
                              <ListItemIcon><Checkbox size="small" checked={item.checked} /></ListItemIcon>
                              <ListItemText>
                                <IngredientView ingredient={item.ingredient} scale={recipe.scale} simplified />
                              </ListItemText>
                            </ListItemButton>
                          )}
                        </Fragment>
                      )}
                      {!!meal.extras.length && <ListSubheader disableGutters disableSticky>Extras</ListSubheader>}
                      {meal.extras.map((extra, index) =>
                        <ListItemButton
                          disableGutters
                          key={`shop-${meal.id}-extras-${index}`}
                          onClick={() => toggleExtra(meal, extra)}
                        >
                          <ListItemIcon><Checkbox size="small" checked={extra.checked} /></ListItemIcon>
                          <ListItemText>{extra.name}</ListItemText>
                        </ListItemButton>
                      )}
                    </Fragment>
                  </List>
                </AccordionDetails>
              </Accordion>
            </Fragment>
          )
          :
          <Box sx={{ textAlign: 'center', marginLeft: 1, marginRight: 1 }}>
            <NoPlanIngredients />
            <Typography gutterBottom variant="h2" component="div">
              Nothing to shop for
            </Typography>
            <Typography gutterBottom variant="body2" color="text.secondary">
              Try adding recipes or extras to your meal plan.
            </Typography>
          </Box>
        }
      </DialogContent>
    </Dialog>
  );
}