import { CheckOutlined, SaveOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AppBar, Box, IconButton, Stack, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { useEffect, useMemo, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { PageWrapper } from '../../components/PageWrapper';
import RecipeView from '../../components/RecipeView';
import { ScrollWrapper } from '../../components/ScrollWrapper';
import { getSharedRecipe, RecipeView as Recipe, saveRecipe } from '../../recipes';
import { useScale } from '../../shared/hooks/useScale';
import { useToggleList } from '../../shared/hooks/useToggleList';
import { getId } from '../../shared/identifiers';

export default function SharedRecipe() {
  const recipeInit: Recipe = useMemo<Recipe>(() => ({
    id: '',
    name: '',
    parsedIngredients: [],
    parsedInstructions: [],
    ingredients: '',
    instructions: '',
    notes: '',
    yield: '',
    yieldLabel: '',
    prepTime: '',
    prepUnit: 1,
    cookTime: '',
    cookUnit: 1,
    extraTime: '',
    extraUnit: 1,
    author: '',
    source: '',
    temperature: 0,
    isMainDish: false,
    tags: [],
    history: [],
    linkedRecipeIds: [],
    sides: [],
  }), []);

  const [authenticated, setAuthenticated] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [recipe, setRecipe] = useState<Recipe | undefined>(recipeInit);
  const { id } = useParams<{ id: string }>();

  useEffect(() =>
    getAuth().onAuthStateChanged((user: any) => {
      setAuthenticated(!!user);
    })
  );

  useEffect(() => {
    getSharedRecipe(id).then(r => setRecipe(r));
  }, [id, recipeInit]);

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('md'));

  const [isIngredientChecked, toggleIngredient] = useToggleList(recipe?.parsedIngredients.length ?? 0);
  const [isInstructionChecked, toggleInstruction] = useToggleList(recipe?.parsedInstructions.length ?? 0);
  const [standardScale, customScale, currentScale, setCurrentScale] = useScale();

  if (!recipe) {
    return <Redirect to="/404" />
  }

  function saveInternal() {
    if (recipe) {
      setSaving(true);
      saveRecipe({ ...recipe, id: getId() }).then(() => {
        setSaving(false);
        setSaved(true);
      });
    }
  }

  return (
    <PageWrapper>
      {isPhone &&
        <AppBar position="sticky" color="secondary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <Typography variant="h5">{recipe.name}</Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Tooltip title={saved || saving ? 'Saved' : authenticated ? 'Save to my recipes' : 'Log in to save'}>
              <span>
                <IconButton color="inherit" disabled={saved || !authenticated} onClick={saveInternal}>
                  {saved ? <CheckOutlined /> : <SaveOutlined />}
                </IconButton>
              </span>
            </Tooltip>
          </Toolbar>
        </AppBar>
      }
      <ScrollWrapper maxWidth="lg" disableGutters={!isPhone} padTop={!isPhone}>
        {!isPhone &&
          <Stack direction="row">
            <Box>
              <Typography variant="h2">{recipe.name}</Typography>
              <Typography variant="h5" color={theme.palette.text.secondary}>Shared Recipe</Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box>
              <LoadingButton
                variant="contained"
                startIcon={saved ? <CheckOutlined /> : <SaveOutlined />}
                onClick={saveInternal}
                disabled={saved || !authenticated}
                loading={saving}
              >
                {saved || saving ? 'Saved' : authenticated ? 'Save to my recipes' : 'Log in to save'}
              </LoadingButton>
            </Box>
          </Stack>
        }
        <RecipeView
          recipe={recipe}
          recipes={[]}
          isIngredientChecked={isIngredientChecked}
          toggleIngredient={toggleIngredient}
          isInstructionChecked={isInstructionChecked}
          toggleInstruction={toggleInstruction}
          standardScale={standardScale}
          customScale={customScale}
          currentScale={currentScale}
          setCurrentScale={setCurrentScale}
          embedded
        />
      </ScrollWrapper>
    </PageWrapper>
  );
}

