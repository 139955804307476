import { getAuth } from '@firebase/auth';
import { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';

export function PrivateRoute({ children, ...rest }: { children: React.ReactNode} & RouteProps) {
  const history = useHistory();

  useEffect(() =>
    getAuth().onAuthStateChanged((user: any) => {
      if (!user) {
        history.push('/');
      }
    })
  );

  return (
    <Route {...rest} render={() => children} />
  );
}