import { Box, Breakpoint, Container, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';

interface ScrollWrapperProps {
  maxWidth?: Breakpoint | false;
  disableGutters?: boolean;
  children?: ReactNode;
  padTop?: boolean;
  padFab?: boolean;
  centerItems?: boolean;
}

export function ScrollWrapper({ children, maxWidth, disableGutters, padTop, padFab, centerItems }: ScrollWrapperProps) {
  const isPhone = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <Box sx={{ overflowX: 'auto', flex: '1 1 auto', background: theme => theme.palette.background.default }}>
      <Container
        maxWidth={maxWidth || false}
        disableGutters={disableGutters}
        sx={{ 
          paddingTop: padTop ? 2 : undefined, 
          paddingBottom: (padFab && isPhone) ? 9 : 2, 
          display: centerItems ? 'grid' : 'flex', 
          flexDirection: 'column',
          placeItems: centerItems ? 'center' : 'initial',
          minHeight: '100%',
        }}
      >
        {children}
      </Container>
    </Box>
  );
}