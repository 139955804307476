import { CloseOutlined, DeleteOutlined } from '@mui/icons-material';
import { AppBar, Autocomplete, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, Grow, IconButton, InputAdornment, MenuItem, Select, SelectChangeEvent, Switch, TextField, Toolbar, Typography, createFilterOptions, useMediaQuery, useTheme } from '@mui/material';
import { forwardRef, useState } from 'react';
import { RecipeView as Recipe } from '../recipes';
import { ExtraSelect } from './ExtraSelect';
import { HelpText } from './HelpText';
import { PageWrapper } from './PageWrapper';
import { RecipeSelect } from './RecipeSelect';
import { ScrollWrapper } from './ScrollWrapper';
import { SearchResult } from '../shared/search';

interface RecipeEditProps {
  recipe: Recipe;
  recipes: Recipe[];
  tags: string[];
  cancelEditing: () => void;
  save: (recipe: Recipe) => void;
  deleteRecipe?: () => void;
  changed: (isModified: boolean) => void;
}

interface Tag {
  title: string;
  inputValue?: string;
}

const SPACING = 0;

const filter = createFilterOptions<Tag>();

const Transition = forwardRef(function Transition(props: any, ref) {
  return <Grow direction="up" ref={ref} {...props} />;
});

export default function RecipeEdit({ recipe, recipes, tags, cancelEditing, save, deleteRecipe, changed }: RecipeEditProps) {
  const isPhone = useMediaQuery(useTheme().breakpoints.down('md'));
  const [tempRecipe, setTempRecipe] = useState<Recipe>(recipe);
  const [deleting, setDeleting] = useState(false);
  const currTags: Tag[] = tags.map(t => ({ title: t }));

  function setTemp(newRecipe: Recipe) {
    setTempRecipe(newRecipe);
    const isModified = JSON.stringify(newRecipe) !== JSON.stringify(recipe);
    changed(isModified);
  }

  function setProperty(property: keyof (Recipe), e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const stringValue = e.target.value;
    if (typeof tempRecipe[property] === 'string') {
      setTemp({ ...tempRecipe, [property]: stringValue });
    } else if (typeof tempRecipe[property] === 'number') {
      setTemp({ ...tempRecipe, [property]: +stringValue || 0 });
    } else if (typeof tempRecipe[property] === 'boolean') {
      const target = e.target as HTMLInputElement;
      setTemp({ ...tempRecipe, [property]: target.checked ?? false });
    }
  }

  function setSelectProperty(property: keyof Recipe, e: SelectChangeEvent<number | string>) {
    setTemp({ ...tempRecipe, [property]: e.target.value });
  }

  function setRecipeTags(tags: Tag[]) {
    const stringTags = tags.map(t => t.inputValue ?? t.title);
    stringTags.sort();
    setTemp({ ...tempRecipe, tags: stringTags });
  }

  function setLinkedRecipes(recipes: SearchResult[]) {
    setTemp({ ...tempRecipe, linkedRecipeIds: recipes.map(x => x.id) });
  }

  function setSides(sides: string[]) {
    setTemp({ ...tempRecipe, sides });
  }
  
  return (
    <PageWrapper>
      <AppBar position="sticky" color="secondary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" sx={{ mr: 1 }} onClick={cancelEditing} >
            <CloseOutlined />
          </IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Edit Recipe
          </Typography>
          <Button color="primary" onClick={() => save(tempRecipe)}>Save</Button>
        </Toolbar>
      </AppBar>
      <ScrollWrapper maxWidth="lg" padTop disableGutters={isPhone}>
        <Card variant="outlined" sx={{ borderColor: 'transparent' }}>
          <CardContent>
            <Grid container spacing={SPACING} sx={{ mb: 2 }}>
              <Grid item xs={12} md={6}>
                <TextField value={tempRecipe.name} onChange={e => setProperty('name', e)} label="Recipe Title" variant="outlined" fullWidth></TextField>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={6} md={2}>
                <TextField
                  size="small"
                  label="Yield"
                  value={tempRecipe.yield || ''}
                  onChange={e => setProperty('yield', e)}
                  variant="outlined"
                  InputProps={{ endAdornment: <InputAdornment position="end">{tempRecipe.yieldLabel}</InputAdornment> }}
                  fullWidth />
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  size="small"
                  label="Label"
                  value={tempRecipe.yieldLabel}
                  onChange={e => e.target.value.length < 12 && setProperty('yieldLabel', e)}
                  variant="outlined"
                  fullWidth />
              </Grid>
              <Grid item xs={6} md={2}>
                <TextField
                  size="small"
                  label="Temp"
                  value={tempRecipe.temperature || ''}
                  onChange={e => setProperty('temperature', e)}
                  variant="outlined"
                  InputProps={{ endAdornment: <InputAdornment position="end">°F</InputAdornment> }}
                  fullWidth />
              </Grid>
              <Grid item xs={3} md={1}>
                <TextField
                  size="small"
                  label="Prep"
                  value={tempRecipe.prepTime || ''}
                  onChange={e => setProperty('prepTime', e)}
                  variant="outlined"
                  fullWidth />
              </Grid>
              <Grid item xs={3} md={1}>
                <Select
                  label="Unit"
                  size="small"
                  value={tempRecipe.prepUnit || 1}
                  onChange={e => setSelectProperty('prepUnit', e)}
                  fullWidth
                >
                  <MenuItem value={1}>m</MenuItem>
                  <MenuItem value={2}>h</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={3} md={1}>
                <TextField
                  size="small"
                  label="Cook"
                  value={tempRecipe.cookTime || ''}
                  onChange={e => setProperty('cookTime', e)}
                  variant="outlined"
                  fullWidth />
              </Grid>
              <Grid item xs={3} md={1}>
                <Select
                  label="Unit"
                  size="small"
                  value={tempRecipe.cookUnit || 1}
                  onChange={e => setSelectProperty('cookUnit', e)}
                  fullWidth
                >
                  <MenuItem value={1}>m</MenuItem>
                  <MenuItem value={2}>h</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={3} md={1}>
                <TextField
                  size="small"
                  label="Extra"
                  value={tempRecipe.extraTime || ''}
                  onChange={e => setProperty('extraTime', e)}
                  variant="outlined"
                  fullWidth />
              </Grid>
              <Grid item xs={3} md={1}>
                <Select
                  label="Unit"
                  size="small"
                  value={tempRecipe.extraUnit || 1}
                  onChange={e => setSelectProperty('extraUnit', e)}
                  fullWidth
                >
                  <MenuItem value={1}>m</MenuItem>
                  <MenuItem value={2}>h</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  label="Author"
                  value={tempRecipe.author}
                  onChange={e => setProperty('author', e)}
                  variant="outlined"
                  fullWidth />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="small"
                  label="Source"
                  value={tempRecipe.source}
                  onChange={e => setProperty('source', e)}
                  variant="outlined"
                  fullWidth />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  value={tempRecipe.tags.map(tag => ({ title: tag } as Tag))}
                  options={currTags}
                  multiple
                  size="small"
                  renderOption={(props, option) => <li {...props}>{option.title}</li>}
                  renderInput={params => <TextField {...params} label="Tags" />}
                  isOptionEqualToValue={(option, value) => (option.inputValue ?? option.title) === (value.inputValue ?? value.title)}
                  onChange={(_, newValue) => setRecipeTags(newValue)}
                  filterSelectedOptions
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  autoHighlight
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.title;
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch checked={tempRecipe.isMainDish ?? false} onChange={e => setProperty('isMainDish', e)} />
                    }
                    label="Suggest this dish when planning"
                  />
                </FormGroup>
              </Grid>
              {!!deleteRecipe &&
                <Grid item xs={12}>
                  <Button color="error" variant="outlined" startIcon={<DeleteOutlined />} onClick={() => setDeleting(true)}>Delete Recipe</Button>
                  <Dialog
                    open={deleting}
                    onClose={() => setDeleting(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    TransitionComponent={Transition}
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Are you sure you want to delete this recipe?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        This action cannot be undone.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setDeleting(false)} autoFocus>Cancel</Button>
                      <Button color="error" onClick={deleteRecipe}>Delete</Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              }
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={SPACING}>
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
          <Grid item xs={12} md={4}>
            <Card variant="outlined" sx={{ borderColor: 'transparent' }}>
              <CardContent>
                <Typography variant="h4">Ingredients</Typography>
                <TextField margin="normal" variant="outlined" placeholder="Separate ingredients with a line break" multiline fullWidth value={tempRecipe.ingredients} onChange={e => setProperty('ingredients', e)} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card variant="outlined" sx={{ borderColor: 'transparent' }}>
              <CardContent>
                <Typography variant="h4" sx={{ flexGrow: 1 }}>Instructions</Typography>
                <TextField margin="normal" variant="outlined" placeholder="Separate instructions with a line break" multiline fullWidth value={tempRecipe.instructions} onChange={e => setProperty('instructions', e)} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined" sx={{ borderColor: 'transparent' }}>
              <CardContent>
                <Typography variant="h4" gutterBottom>Notes</Typography>
                <TextField
                  variant="outlined"
                  multiline
                  fullWidth
                  value={tempRecipe.notes}
                  onChange={e => setProperty('notes', e)}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={SPACING} >
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Card variant="outlined" sx={{ borderColor: 'transparent' }}>
              <CardContent>
                <Typography variant="h4">Linked Recipes <HelpText title="Items will be suggested as additional recipes when this recipe is added to a meal." /></Typography>
                <RecipeSelect
                  value={tempRecipe.linkedRecipeIds.map(id => ({ id, title: recipes.find(x => x.id === id)?.name ?? 'Deleted Recipe' }))}
                  recipes={recipes}
                  recipeIds={tempRecipe.linkedRecipeIds}
                  onChange={setLinkedRecipes}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card variant="outlined" sx={{ borderColor: 'transparent' }}>
              <CardContent>
                <Typography variant="h4">Sides <HelpText title="Items will be suggested as extras when this recipe is added to a meal." /></Typography>
                <ExtraSelect
                  value={tempRecipe.sides}
                  onChange={setSides}
                  recipes={recipes}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ScrollWrapper>
    </PageWrapper>
  );
}