import { arrayRemove, arrayUnion, collection, deleteField, doc, Firestore, onSnapshot, query, runTransaction, serverTimestamp, Unsubscribe, updateDoc, where } from "firebase/firestore";
import { getDate } from '../shared/dates';
import { Collections, WorkspaceCollections } from "../shared/firebase";
import { MealRepository } from './repository';

const LAST_CACHE_SYNC_KEY = 'lastSync-meals';

export interface FirestoreRecipeState {
  scale?: string;
  checkedIngredients?: { [key: number]: boolean };
  checkedInstructions?: { [key: number]: boolean };
}

export interface FirestoreMeal {
  id: string;
  name?: string;
  displayName?: string;
  recipeIds: string[];
  recipeState?: { [key: string]: FirestoreRecipeState };
  extras: string[];
  planDate?: Date;
  planOrder: number;
  addedToList?: boolean;
  updated: Date;
  dateMade?: Date;
}


export class MealFirestoreListener {
  constructor(
    private firestore: Firestore,
    private repository: MealRepository,
  ) { }

  public listen(workspaceId: string): Unsubscribe {
    const meals = collection(this.firestore, Collections.WORKSPACE, workspaceId, WorkspaceCollections.MEALS);
    const mealQuery = query(meals, where('updated', '>=', new Date(localStorage.getItem(`${LAST_CACHE_SYNC_KEY}-${workspaceId}`) || '2000-01-01')));

    return onSnapshot(mealQuery, async (snapshot) => {
      localStorage.setItem(`${LAST_CACHE_SYNC_KEY}-${workspaceId}`, new Date().toUTCString());

      const meals = snapshot.docs
        .map(doc => ({
          id: doc.id,
          workspaceId,
          ...doc.data(),
          planDate: doc.data().planDate ? new Date(doc.data().planDate.seconds * 1000) : undefined,
          dateMade: doc.data().dateMade ? new Date(doc.data().dateMade.seconds * 1000) : undefined,
        } as unknown as FirestoreMeal));

      if (!meals.length) {
        return;
      }

      await this.repository.setLocal(meals);
    });
  }
}

export class MealFirestoreClient {
  constructor(private firestore: Firestore) { }

  // TODO: Split into create/update?
  public async saveMeal(workspaceId: string, meal: FirestoreMeal): Promise<void> {
    await runTransaction(this.firestore, async transaction => {
      transaction.update(doc(this.firestore, Collections.WORKSPACE, workspaceId), { mealPlanIds: arrayUnion(meal.id) });
      transaction.set(doc(this.firestore, Collections.WORKSPACE, workspaceId, WorkspaceCollections.MEALS, meal.id), meal);
    });
  }

  public async deleteMeal(workspaceId: string, mealId: string): Promise<void> {
    await runTransaction(this.firestore, async transaction => {
      transaction.update(doc(this.firestore, Collections.WORKSPACE, workspaceId), { mealPlanIds: arrayRemove(mealId) });
      transaction.delete(doc(this.firestore, Collections.WORKSPACE, workspaceId, WorkspaceCollections.MEALS, mealId));
    });
  }

  // TODO: Move to repository?
  public async setDate(workspaceId: string, mealId: string, order: number, dayOffset?: number): Promise<void> {
    const mealDoc = doc(this.firestore, Collections.WORKSPACE, workspaceId, WorkspaceCollections.MEALS, mealId);

    if (dayOffset === undefined) {
      await updateDoc(mealDoc, { planOrder: order, updated: new Date() });
    } else if (dayOffset < 0) {
      await updateDoc(mealDoc, { planDate: deleteField(), planOrder: order, updated: new Date() });
    } else {
      await updateDoc(mealDoc, { planDate: getDate(dayOffset), planOrder: order, updated: new Date() });
    }
  }

  public async updateIngredient(workspaceId: string, mealId: string, recipeId: string, index: number, checked: boolean): Promise<void> {
    const mealDoc = doc(this.firestore, Collections.WORKSPACE, workspaceId, WorkspaceCollections.MEALS, mealId);
    await updateDoc(mealDoc, { [`recipeState.${recipeId}.checkedIngredients.${index}`]: checked, updated: serverTimestamp() });
  }

  public async updateInstruction(workspaceId: string, mealId: string, recipeId: string, index: number, checked: boolean): Promise<void> {
    const mealDoc = doc(this.firestore, Collections.WORKSPACE, workspaceId, WorkspaceCollections.MEALS, mealId);
    await updateDoc(mealDoc, { [`recipeState.${recipeId}.checkedInstructions.${index}`]: checked, updated: serverTimestamp() });
  }
}