import { MealFirestoreClient, MealFirestoreListener } from '../meals/firestore';
import { MealRepository } from '../meals/repository';
import { RecipeFirestoreClient, RecipeFirestoreListener } from '../recipes/firestore';
import { RecipeRepository } from '../recipes/repository';
import { db as LocalDb } from '../shared/database';
import { EventBusConsumer, EventBusProducer, Events } from '../shared/events';
import { db as firestore } from '../shared/firebase';
import { unwrapPromise } from '../shared/subscriptions';
import { ActiveWorkspaceFirestoreClient, WorkspaceFirestoreClient } from './firestore';
import { WorkspaceRepository } from './repository';
import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import { ListFirestoreClient, ListFirestoreListener } from '../lists/firestore';
import { ListRepository } from '../lists/repository';
import { WorkspaceView } from './workspace';

export type { WorkspaceView } from './workspace';

const eventProducer = new EventBusProducer();
const eventConsumer = new EventBusConsumer();

const recipeRepository = new RecipeRepository(
  LocalDb,
  eventProducer,
  new RecipeFirestoreClient(firestore),
);

const mealRepository = new MealRepository(
  new MealFirestoreClient(firestore),
  eventProducer,
  LocalDb,
);

const listRepository = new ListRepository(
  LocalDb,
  eventProducer,
  new ListFirestoreClient(firestore),
)

const workspaceListener = new WorkspaceFirestoreClient(
  new WorkspaceRepository(
    mealRepository,
    eventProducer,
    LocalDb,
  )
);

const recipeListener = new RecipeFirestoreListener(
  firestore,
  recipeRepository,
);

const mealListener = new MealFirestoreListener(
  firestore,
  mealRepository,
);

const listListener = new ListFirestoreListener(
  firestore,
  listRepository,
);

const activeClient = new ActiveWorkspaceFirestoreClient(new EventBusProducer());
const workspaceRepository = new WorkspaceRepository(mealRepository, eventProducer, LocalDb);

type Callback = (workspace: WorkspaceView) => void;

export function subscribeToActiveWorkspace(callback: Callback): () => void {
  return unwrapPromise(cb => subscribeInternal(cb), callback);
}

async function subscribeInternal(callback: Callback): Promise<() => void> {
  return eventConsumer.subscribe(Events.ACTIVE_WORKSPACE_CHANGED, async () => {
    const workspace = await getActiveWorkspace();

    if (workspace) {
      callback(workspace);
    }
  });
}

export function listenToWorkspace(): () => void {
  return unwrapPromise(() => listenInternal(), () => { });
}

async function listenInternal(): Promise<() => void> {
  const channel = new BroadcastChannel('listener');
  const election = createLeaderElection(channel);

  await election.awaitLeadership();

  let workspaceUnsubscribe = () => { };
  let recipeUnsubscribe = () => { };
  let mealUnsubscribe = () => { };
  let listUnsubscribe = () => { };

  const unsubscribe = await eventConsumer.subscribe(Events.ACTIVE_WORKSPACE_CHANGED, async () => {
    const workspaceId = await activeClient.getActiveId();

    workspaceUnsubscribe();
    recipeUnsubscribe();
    mealUnsubscribe();
    listUnsubscribe();

    if (workspaceId) {
      eventProducer.emit(Events.LEFTOVERS_CHANGED);
      eventProducer.emit(Events.LISTS_CHANGED);
      eventProducer.emit(Events.PLAN_CHANGED);
      eventProducer.emit(Events.RECIPES_CHANGED);
      eventProducer.emit(Events.STAPLES_CHANGED);
      eventProducer.emit(Events.WORKSPACE_CHANGED);

      workspaceUnsubscribe = workspaceListener.listen(workspaceId);
      recipeUnsubscribe = recipeListener.listen(workspaceId);
      mealUnsubscribe = mealListener.listen(workspaceId);
      listUnsubscribe = listListener.listen(workspaceId);
    }
  });

  return () => {
    workspaceUnsubscribe();
    recipeUnsubscribe();
    mealUnsubscribe();
    listUnsubscribe();
    unsubscribe();
  };
}

export async function getActiveWorkspace(): Promise<WorkspaceView | undefined> {
  var activeId = await activeClient.getActiveId();
  return activeId ? workspaceRepository.get(activeId) : undefined;
}

export function setActiveWorkspace(id: string): void {
  return activeClient.setActiveId(id);
}

export function resetActiveWorkspace(): void {
  activeClient.resetActiveId();
}

export async function getWorkspaces(): Promise<WorkspaceView[]> {
  return activeClient.getWorkspaces();
}
