import { LocalDb, Stores } from '../shared/database';
import { FirestoreLeftover, LeftoverFirestoreClient } from './firestore';
import { Leftover } from './leftover';

export class LeftoverRepository {
  constructor(
    private db: Promise<LocalDb>,
    private client: LeftoverFirestoreClient,
  ) { }

  public async getLeftovers(workspaceId: string): Promise<Leftover[]> {
    const workspace = await (await this.db).get(Stores.WORKSPACES, workspaceId);
    const leftovers = Object.values(workspace?.leftovers ?? {});
    leftovers.sort((a, b) => a.dateMade.seconds - b.dateMade.seconds);
    return leftovers.map(l => this.mapLeftoverFromDatabase(l));
  }

  public async save(workspaceId: string, leftover: Leftover): Promise<void> {
    const firebaseLeftover: FirestoreLeftover = {
      id: leftover.id,
      name: leftover.name,
      dateMade: { seconds: leftover.dateMade.getTime() / 1000 }
    };

    if (leftover.mealName) {
      firebaseLeftover.mealName = leftover.mealName;
    }

    this.client.save(workspaceId, firebaseLeftover);
  }

  public async delete(workspaceId: string, leftoverId: string): Promise<void> {
    return this.client.delete(workspaceId, leftoverId);
  }

  private mapLeftoverFromDatabase(leftover: FirestoreLeftover): Leftover {
    return {
      id: leftover.id,
      name: leftover.name,
      mealName: leftover.mealName,
      dateMade: new Date(leftover.dateMade.seconds * 1000)
    };
  }
}