import { TokenType } from "./token";

export type NodeType = TokenType | IntermediateType;

export type IntermediateType =
  | 'root'
  | 'amount'
  | 'full unit'
  | 'unit size'
  | 'unit'
  | 'full ingredient'
  ;

export type Grammar = { [Property in IntermediateType]: GrammarNode };
export type GrammarNode = GrammarOption[];
export type GrammarOption = Array<GrammarType>;
export type GrammarType = NodeType | GrammarToken;
export type GrammarToken = [NodeType, string];

const grammar: Grammar = {
  'root': [['amount', 'full unit', 'full ingredient'], ['full unit', 'full ingredient'], ['amount', 'full unit', 'preposition', 'full ingredient'], ['full unit', 'preposition', 'full ingredient'], ['amount', 'full ingredient'], ['full ingredient']],
  'amount': [['number']],
  'full unit': [['unit size', 'unit'], ['unit']],
  'unit size': [[['paren', '('], 'number', 'unit', ['paren', ')']], ['cross', 'number', 'unit'], ['number', 'unit']],
  'unit': [['unit fragment', 'whole unit'], ['whole unit']],
  'full ingredient': [['ingredient', 'comma', 'preparation'], ['preparation', 'ingredient'], ['ingredient']],
};

export function nodeOf(type: NodeType): GrammarNode {
  return grammar[type as IntermediateType] ?? [];
}