import { stripDiacritics } from "../shared/search";

export interface SearchHighlightProps {
  searchTerm: string;
  text?: string;
}

export default function SearchHighlight({ searchTerm, text }: SearchHighlightProps) {
  const term = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  if (!text) {
    return <span></span>;
  }

  if (!term) {
    return <span>{text}</span>;
  }

  const parts: string[] = getParts(text, term);

  return <span> {parts.map((part, i) =>
    <span
      key={i}
      style={stripDiacritics(part.toLowerCase()) === stripDiacritics(term.toLowerCase()) ? {
        fontWeight: 'bold',
        background: 'rgba(255, 255, 0, 0.4)'
      } : {}}>
      {part}
    </span>)
  } </span>;
}

function getParts(text: string, term: string) {
  const strippedParts = stripDiacritics(text).split(new RegExp(`(${term})`, 'gi'));

  const parts: string[] = [];
  let start = 0;
  for (const stripped of strippedParts) {
    parts.push(text.substring(start, start + stripped.length));
    start += stripped.length;
  }
  return parts;
}
