import { AddCircleOutlined } from '@mui/icons-material';
import { Autocomplete, Chip, Grid, TextField } from '@mui/material';
import { RecipeView } from '../recipes';

export interface ExtraSelectProps {
  value: string[];
  onChange: (value: string[]) => void;
  size?: 'small' | 'medium';
  recipeIds?: string[];
  recipes: RecipeView[];
}

export function ExtraSelect({ value, onChange, size = 'medium', recipeIds = [], recipes }: ExtraSelectProps) {

  const suggestedExtras = Array.from(new Set(
    recipeIds
      .map(id => recipes.find(x => x.id === id))
      .filter(x => !!x)
      .map(x => x as RecipeView)
      .flatMap(x => x.sides)
      .filter(x => !value.includes(x))
    ?? []
  ));

  return (
    <>
      <Autocomplete
        value={value}
        options={['Enter a value and press enter to submit', ...value]}
        multiple
        renderInput={params => <TextField {...params} />}
        onChange={(_, newValue) => onChange(newValue)}
        filterSelectedOptions
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        autoHighlight
        getOptionDisabled={option => option === 'Enter a value and press enter to submit'}
        filterOptions={(options, params) => params.inputValue ? [params.inputValue] : options}
        sx={{ mt: 2, mb: 1 }}
        size={size}
      />
      <Grid container spacing={1}>
        {suggestedExtras.map((extra, i) =>
          <Grid item key={`suggestion-extras-${i}`}>
            <Chip
              deleteIcon={<AddCircleOutlined />}
              label={extra}
              onDelete={() => onChange([...value, extra])}
              size={size}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}