import { parseIngredient } from "../parser/parse-ingredient";
import { LocalDb, Stores } from "../shared/database";
import { getId } from '../shared/identifiers';
import { Instruction, RecipeHistoryEntry, RecipeView, ShareRecipeOptions } from "./recipe";
import { FirestoreRecipe, RecipeFirestoreClient } from "./firestore";
import { EventBusProducer, Events } from '../shared/events';
import { prettifyFraction } from '../parser/test-helpers';
import { parseFraction } from '../parser/parse-fraction';

export class RecipeRepository {
  constructor(
    private db: Promise<LocalDb>,
    private producer: EventBusProducer,
    private client: RecipeFirestoreClient,
  ) { }

  public async setLocal(recipes: FirestoreRecipe[]): Promise<void> {
    if (!recipes.length) {
      return;
    }

    const tx = (await this.db).transaction(Stores.RECIPES, 'readwrite');
    await Promise.all([
      ...recipes.filter(r => !r.deleted).map(r => tx.store.put(r, r.id)),
      ...recipes.filter(r => r.deleted).map(r => tx.store.delete(r.id)),
      tx.done,
    ]);
    this.producer.emit(Events.RECIPES_CHANGED);
  }

  public async getRecipes(workspaceId: string): Promise<RecipeView[]> {
    const index = (await this.db).transaction(Stores.RECIPES).store.index('by-workspace');
    const recipes = await index.getAll(workspaceId);
    recipes.sort((a, b) => a.name.localeCompare(b.name));
    return recipes.map(r => this.mapRecipeFromDatabase(r));
  }

  public async save(workspaceId: string, recipe: RecipeView): Promise<void> {
    const firebaseRecipe: FirestoreRecipe = {
      id: recipe.id,
      name: recipe.name,
      ingredients: recipe.ingredients,
      instructions: recipe.instructions,
      yield: recipe.yield,
      yieldLabel: recipe.yieldLabel,
      prepTime: recipe.prepTime,
      prepUnit: recipe.prepUnit,
      cookTime: recipe.cookTime,
      cookUnit: recipe.cookUnit,
      extraTime: recipe.extraTime,
      extraUnit: recipe.extraUnit,
      notes: recipe.notes,
      author: recipe.author,
      source: recipe.source,
      temperature: recipe.temperature,
      tags: recipe.tags,
      isMainDish: recipe.isMainDish,
      history: recipe.history.map(x => ({ date: { seconds: x.date.getTime() / 1000 }, meal: x.meal, hadLeftovers: x.hadLeftovers, scale: x.scale })),
      linkedRecipeIds: recipe.linkedRecipeIds,
      sides: recipe.sides,
      updated: new Date()
    };

    this.client.saveRecipe(workspaceId, firebaseRecipe);
  }

  public async delete(workspaceId: string, recipeId: string): Promise<void> {
    return this.client.deleteRecipe(workspaceId, recipeId);
  }

  public async addHistory(workspaceId: string, recipeId: string, entry: RecipeHistoryEntry): Promise<void> {
    return this.client.addHistory(workspaceId, recipeId, entry);
  }

  public async shareRecipe(recipe: RecipeView, options: ShareRecipeOptions): Promise<string> {
    const recipeDoc: FirestoreRecipe = {
      id: getId(),
      name: recipe.name,
      ingredients: recipe.ingredients,
      instructions: recipe.instructions,
      yield: recipe.yield.toString(),
      yieldLabel: recipe.yieldLabel,
      prepTime: recipe.prepTime,
      prepUnit: recipe.prepUnit,
      cookTime: recipe.cookTime,
      cookUnit: recipe.cookUnit,
      extraTime: recipe.extraTime,
      extraUnit: recipe.extraUnit,
      notes: options.stripNotes ? '' : recipe.notes,
      author: options.stripSource ? '' : recipe.author,
      source: options.stripSource ? '' : recipe.source,
      temperature: recipe.temperature,
      tags: recipe.tags,
      isMainDish: false,
      updated: new Date(),
    };

    await this.client.shareRecipe(recipeDoc);

    return recipeDoc.id;
  }

  public async getSharedRecipe(id: string): Promise<RecipeView | undefined> {
    const recipe = await this.client.getSharedRecipe(id);
    return recipe ? this.mapRecipeFromDatabase(recipe) : undefined;
  }

  private mapRecipeFromDatabase(recipe: FirestoreRecipe): RecipeView {
    const history = (recipe.history ?? []).map(x => ({
      meal: x.meal,
      date: new Date(x.date.seconds * 1000),
      hadLeftovers: x.hadLeftovers,
      scale: x.scale,
    }));
    history.sort((a, b) => b.date.getTime() - a.date.getTime());

    return {
      id: recipe.id,
      name: recipe.name,
      parsedIngredients: (recipe.ingredients as string).split('\n').map(s => s.trim()).filter(i => !!i).map(parseIngredient),
      parsedInstructions: (recipe.instructions as string).split('\n').map(s => s.trim()).filter(i => !!i).map(this.parseInstructionLine),
      ingredients: recipe.ingredients,
      instructions: recipe.instructions,
      prepTime: this.extractNumberString(recipe.prepTime),
      prepUnit: recipe.prepUnit ?? 1,
      cookTime: this.extractNumberString(recipe.cookTime),
      cookUnit: recipe.cookUnit ?? 1,
      extraTime: this.extractNumberString(recipe.extraTime ?? 0),
      extraUnit: recipe.extraUnit ?? 1,
      yield: this.extractNumberString(recipe.yield.toString().replace(recipe.yieldLabel, '')),
      yieldLabel: recipe.yieldLabel?.trim() ?? 'servings',
      notes: recipe.notes ?? '',
      author: recipe.author ?? '',
      source: recipe.source ?? '',
      temperature: recipe.temperature ?? 0,
      isMainDish: recipe.isMainDish ?? false,
      tags: recipe.tags ?? [],
      history,
      linkedRecipeIds: recipe.linkedRecipeIds ?? [],
      sides: recipe.sides ?? [],
    };
  }

  private extractNumberString(input: string | number): string {
    if (typeof input === 'number') {
      return input ? input.toString() : '';
    }

    const res = prettifyFraction(parseFraction(input));
    return res === '0' ? '' : res;
  }

  private parseInstructionLine(line: string): Instruction {
    const trimmedLine = line.trim();
    const isHeader = trimmedLine.endsWith(':');
    if (isHeader) {
      return {
        text: trimmedLine.substring(0, trimmedLine.length - 1),
        isHeader: true,
      };
    }

    return {
      text: trimmedLine,
      isHeader: false,
    };
  }
}