import { db as localDb } from '../shared/database';
import { EventBusConsumer, EventBusProducer, Events } from '../shared/events';
import { db as firestore } from '../shared/firebase';
import { unwrapPromise } from '../shared/subscriptions';
import { ActiveWorkspaceFirestoreClient } from '../workspaces/firestore';
import { ListFirestoreClient } from './firestore';
import { ShoppingList, ShoppingListItem } from './list';
import { ListRepository } from './repository';

export type { ShoppingList, ShoppingListItem, ShoppingListItemMeal } from './list';

type Callback = (lists: ShoppingList[]) => void;

const repository = new ListRepository(
  localDb,
  new EventBusProducer(),
  new ListFirestoreClient(firestore),
);

const consumer = new EventBusConsumer();
const producer = new EventBusProducer();

const workspaceClient = new ActiveWorkspaceFirestoreClient(producer);

export function subscribeToLists(callback: Callback): () => void {
  return unwrapPromise(cb => subscribeInternal(cb), callback);
}

async function subscribeInternal(callback: Callback): Promise<() => void> {
  return consumer.subscribe(Events.LISTS_CHANGED, async () => {
    const workspaceId = await workspaceClient.getActiveId();

    if (workspaceId) {
      const lists = await repository.getLists(workspaceId);
      callback(lists);
    }
  });
}

export async function saveList(list: ShoppingList): Promise<void> {
  const workspaceId = await workspaceClient.getActiveId();

  if (workspaceId) {
    return repository.save(workspaceId, list);
  }
}

export async function addToList(items: ShoppingListItem[], listId: string = 'default') {
  const workspaceId = await workspaceClient.getActiveId();

  if (workspaceId) {
    const lists = await repository.getLists(workspaceId);
    const list = lists.find(l => l.id === listId);

    if (list) {
      list.items = [...list.items, ...items];
      await repository.save(workspaceId, list);
    } else {
      await repository.save(workspaceId, {
        id: listId,
        title: listId,
        items,
      });
    }
  }

  // TODO: Error if not found?
}