import { ArrowBackOutlined, EditOutlined } from '@mui/icons-material';
import { AppBar, Box, Fab, Grid, IconButton, Toolbar, Tooltip, Typography, Zoom, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MealView as Meal } from '../meals/meal';
import { RecipeView } from '../recipes';
import { setWakeLock } from '../shared/hooks/setWakeLock';
import { MealRecipeView } from './MealRecipeView';
import { PageWrapper } from './PageWrapper';
import { ScrollWrapper } from './ScrollWrapper';

const SPACING = 3;

interface MealViewProps {
  meal: Meal;
  recipes: RecipeView[];
  startEditing: () => void;
}

export default function MealView({ meal, recipes, startEditing }: MealViewProps) {
  const isPhone = useMediaQuery(useTheme().breakpoints.down('md'));
  const history = useHistory();

  useEffect(setWakeLock, []);

  // TODO: Pull this out
  function getExtrasString() {
    const trimmedExtras = meal.extras.filter(e => !!e.trim());
    if (trimmedExtras.length) {
      const extras = [...trimmedExtras];
      const last = extras.pop();

      if (!extras.length) {
        return 'with ' + last;
      }

      if (extras.length === 1) {
        return `with ${extras[0]} and ${last}`;
      }

      return 'with ' + extras.join(', ') + ', and ' + last;
    }

    return undefined;
  }

  return (
    <PageWrapper>
      <AppBar position="sticky" color="secondary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => history.goBack()}
            sx={{ mr: 1 }}
          >
            <ArrowBackOutlined />
          </IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            {meal.displayName}
          </Typography>
          <Box sx={{ flexGrow: 1 }}></Box>
          {!isPhone &&
            <Tooltip title="Edit Meal">
              <IconButton color="inherit" onClick={startEditing}>
                <EditOutlined />
              </IconButton>
            </Tooltip>
          }
        </Toolbar>
      </AppBar>
      {isPhone &&
        <Zoom in unmountOnExit>
          <Fab color="primary" variant="extended" sx={{ position: 'fixed', bottom: 15, right: 15, zIndex: 1050 }} onClick={startEditing}>
            <EditOutlined sx={{ mr: 1 }} />
            Edit
          </Fab>
        </Zoom>
      }
      <ScrollWrapper maxWidth="lg" padTop padFab>
        <Grid container spacing={SPACING}>
          {!!meal.planDate &&
            <Grid item xs={12}>
              planned for {meal.planDate?.toDateString()}
            </Grid>
          }
          {meal.extras.length > 0 &&
            <Grid item xs={12}>
              {getExtrasString()}
            </Grid>
          }
          {meal.recipes.map((recipe) =>
            <MealRecipeView recipe={recipe} recipes={recipes} meal={meal} key={`meal-embedded-${recipe.id}`} />
          )}
        </Grid>
      </ScrollWrapper>
    </PageWrapper>
  );
}
