import { CloseOutlined, DeleteOutlined } from '@mui/icons-material';
import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, TextField, Toolbar, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import { MealView as Meal } from '../meals/meal';
import { RecipeView } from '../recipes';
import { SearchResult } from '../shared/search';
import { ExtraSelect } from './ExtraSelect';
import { PageWrapper } from './PageWrapper';
import { RecipeSelect } from './RecipeSelect';
import { ScrollWrapper } from './ScrollWrapper';

const SPACING = 3;

interface MealEditProps {
  meal: Meal;
  recipes: RecipeView[];
  cancelEditing: () => void;
  save: (meal: Meal) => void;
  deleteMeal: () => void;
  changed: (isModified: boolean) => void;
}

export default function MealEdit({ meal, recipes, cancelEditing, save, deleteMeal, changed }: MealEditProps) {
  const [tempMeal, setTempMeal] = useState<Meal>(meal);
  const [deleting, setDeleting] = useState(false);

  function setTemp(newMeal: Meal) {
    setTempMeal(newMeal);
    const isModified = JSON.stringify(newMeal) !== JSON.stringify(meal);
    changed(isModified);
  }

  function recipeSelectionChanged(value: SearchResult[]) {
    setTemp({ ...tempMeal, recipeIds: value.map(r => r.id) });
  }

  function setTitle(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setTemp({ ...tempMeal, name: e.target.value });
  }

  function setDate(date: Date | null | undefined) {
    setTemp({ ...tempMeal, planDate: date ?? undefined });
  }

  function createMealName(meal: Meal) {
    if (!meal.recipeIds.length) {
      return '';
    }

    if (meal.recipeIds.length === 1) {
      return recipes.find(r => r.id === meal.recipeIds[0])?.name ?? '';
    }

    const recipeNames = [...meal.recipeIds].map(id => recipes.find(r => r.id === id)?.name);
    const last = recipeNames.pop();
    return recipeNames.join(', ') + (meal.recipeIds.length >= 3 ? ',' : '') + ' and ' + last;
  }

  function saveInternal(meal: Meal) {
    save({ ...meal, displayName: meal.name || createMealName(meal) });
  }

  return (
    <PageWrapper>
      <AppBar position="sticky" color="secondary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" sx={{ mr: 1 }} onClick={cancelEditing} >
            <CloseOutlined />
          </IconButton>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            Edit Meal
          </Typography>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Button onClick={() => saveInternal(tempMeal)}>Save</Button>
        </Toolbar>
      </AppBar>
      <ScrollWrapper maxWidth="lg" padTop padFab>
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={SPACING} sx={{ mb: 2 }}>
            <Grid item xs={12} md={7}>
              <TextField
                value={tempMeal.name}
                placeholder={createMealName(tempMeal)}
                label="Meal Title"
                variant="outlined"
                fullWidth
                onChange={setTitle}
                focused={!!tempMeal.recipeIds.length}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={6}>
              <DatePicker
                value={tempMeal.planDate ?? null}
                onChange={setDate}
                label="Date"
                renderInput={(params) => <TextField size="small" {...params} />}
              ></DatePicker>
            </Grid>
            <Grid item xs={12}>
              <Button color="error" variant="outlined" startIcon={<DeleteOutlined />} onClick={() => setDeleting(true)}>Delete Meal</Button>
              <Dialog
                open={deleting}
                onClose={() => setDeleting(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Are you sure you want to delete this meal?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    This action cannot be undone.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDeleting(false)} autoFocus>Cancel</Button>
                  <Button color="error" onClick={deleteMeal}>Delete</Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </Box>
        <Divider variant="middle" />
        <Grid container spacing={SPACING} mt={0}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h4">Recipes</Typography>
              <RecipeSelect
                value={tempMeal.recipeIds.map(id => ({ title: recipes.find(r => r.id === id)?.name ?? 'Deleted Recipe', id }))}
                recipes={recipes}
                recipeIds={tempMeal.recipeIds}
                onChange={recipeSelectionChanged}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="h4">Extras</Typography>
              <ExtraSelect
                value={tempMeal.extras}
                onChange={extras => setTempMeal({ ...tempMeal, extras })}
                recipes={recipes}
                recipeIds={tempMeal.recipeIds}
              />
            </Box>
          </Grid>
        </Grid>
      </ScrollWrapper>
    </PageWrapper>
  );
}