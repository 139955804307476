import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { app } from './firebase';
import { ImportedRecipe } from '../recipes/recipe';

const functions = getFunctions(app);

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

const importRecipeInternal = httpsCallable(functions, 'importRecipe');

export async function importRecipe(url: string): Promise<ImportedRecipe | null> {
  const result = await importRecipeInternal({ url });
  return result.data as ImportedRecipe | null;
}