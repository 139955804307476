import { Amount, Fraction, scaleAmount } from "./amounts";
import { parseAmounts } from "./parse-amount";

export interface Ingredient {
  minAmount: Amount[];
  maxAmount: Amount[];
  item: string;
  parenthetical?: string;
  isHeader: boolean;
}

export function parseIngredient(raw: string): Ingredient {
  const trimmed = raw.trim();

  const header = parseHeader(trimmed);

  if (header) {
    return {
      minAmount: [],
      maxAmount: [],
      item: header,
      isHeader: true,
    };
  }

  const [minAmount, maxAmount, noAmounts] = parseAmounts(trimmed);
  const [parenthetical, item] = parseParenthetical(noAmounts);
  const strippedItem = item.replace(/\s*of\s+/yi, '');

  return {
    minAmount,
    maxAmount,
    item: strippedItem,
    parenthetical,
    isHeader: false,
  };
}

function parseHeader(text: string): string | undefined {
  if (text.endsWith(':')) {
    return text.substring(0, text.length - 1);
  }
}

function parseParenthetical(noAmounts: string): [string | undefined, string] {
  const match = noAmounts.match(/(?:,|\().*/);

  if (match) {
    return [match[0], noAmounts.replace(match[0], '')];
  }

  return [undefined, noAmounts];
}

export function scaleIngredient(ingredient: Ingredient, scale: Fraction): Ingredient {
  return {
    ...ingredient,
    minAmount: scaleAmount(ingredient.minAmount, scale),
    maxAmount: scaleAmount(ingredient.maxAmount, scale),
  };
}
