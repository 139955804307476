import { AddTaskOutlined, ArrowBackOutlined, CheckOutlined, ContentCopyOutlined, CopyAllOutlined, EditOutlined, ShareOutlined } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import ClockIcon from '@mui/icons-material/ScheduleOutlined';
import TemperatureIcon from '@mui/icons-material/SpeedOutlined';
import { AppBar, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Tooltip, Typography, Zoom, useMediaQuery, useTheme } from '@mui/material';
import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import Linkify from 'react-linkify';
import { useHistory } from 'react-router-dom';
import { addToList } from '../lists';
import { scaleAmount } from '../parser/amounts';
import { parseFraction } from '../parser/parse-fraction';
import { Ingredient } from '../parser/parse-ingredient';
import { prettifyFraction } from '../parser/test-helpers';
import { RecipeView as Recipe, shareRecipe } from '../recipes';
import { setWakeLock } from '../shared/hooks/setWakeLock';
import { Scale } from '../shared/hooks/useScale';
import IngredientView from './IngredientView';
import { PageWrapper } from './PageWrapper';
import { ScrollWrapper } from './ScrollWrapper';

interface RecipeViewProps {
  recipe: Recipe;
  recipes: Recipe[];
  startEditing?: () => void;
  embedded?: boolean;
  isIngredientChecked: (index: number) => boolean;
  toggleIngredient: (index: number) => void;
  isInstructionChecked: (index: number) => boolean;
  toggleInstruction: (index: number) => void;
  standardScale: Scale[];
  customScale: Scale;
  currentScale: Scale;
  setCurrentScale: (scale: string) => void;
}

const timeUnits: { [key: number]: string } = {
  1: 'm',
  2: 'h',
};

export default function RecipeView({
  recipe,
  recipes,
  startEditing,
  embedded = false,
  isIngredientChecked,
  toggleIngredient,
  isInstructionChecked,
  toggleInstruction,
  standardScale,
  customScale,
  currentScale,
  setCurrentScale,
}: RecipeViewProps) {
  const isPhone = useMediaQuery(useTheme().breakpoints.down('md'));
  const [customScaleString, setCustomScaleString] = useState('');
  const [customScaleOpen, setCustomScaleOpen] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [sharingLink, setSharingLink] = useState('');
  const [copied, setCopied] = useState(false);

  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    if (!embedded) {
      return setWakeLock();
    }
  }, [embedded]);

  function startShare() {
    if (sharingLink) {
      share(sharingLink);
    } else {
      shareRecipe(recipe).then((link) => {
        setSharingLink(link);
        share(link);
      });
    }
  }

  function share(link: string) {
    if (navigator.share && link.startsWith('https:')) {
      navigator.share({
        text: `${recipe.name} - appetīz recipes and meal planning`,
        title: `View ${recipe.name} on appetīz`,
        url: link,
      });
    } else {
      setSharing(true);
    }
  }

  function copyLink() {
    navigator.clipboard.writeText(sharingLink)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 5000);
      });
  }

  function finalizeCustomScale() {
    setCurrentScale(customScaleString);
    setCustomScaleOpen(false);
  }

  const yieldAmount = scaleAmount([{ fraction: parseFraction(recipe.yield) }], currentScale.fraction);
  const yieldDisplay = prettifyFraction(yieldAmount[0].fraction);
  const isPluralizable = recipe.yieldLabel.charAt(recipe.yieldLabel.length - 1).match(/[a-z]/i);
  const yieldLabel = isPluralizable ? pluralize(recipe.yieldLabel, Math.ceil(yieldAmount[0].fraction[0] / yieldAmount[0].fraction[1])) : recipe.yieldLabel;

  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
    ingredient: Ingredient;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent, ingredient: Ingredient) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
          ingredient,
        }
        : null,
    );
  };

  const addIngredientToList = async () => {
    if (!contextMenu) return;

    await addToList([{
      title: contextMenu.ingredient.item.trim(),
      checked: false,
    }]);

    setContextMenu(null);
  };

  return (
    <PageWrapper>
      {!embedded &&
        <AppBar position="sticky" color="secondary" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" sx={{ mr: 1, displayPrint: 'none' }} onClick={() => history.goBack()}>
              <ArrowBackOutlined />
            </IconButton>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              {recipe.name}
            </Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Tooltip title="Share Recipe" sx={{ displayPrint: 'none' }}>
              <IconButton color="inherit" onClick={startShare}>
                <ShareOutlined />
              </IconButton>
            </Tooltip>
            <Tooltip title="Plan Recipe" sx={{ displayPrint: 'none' }}>
              <IconButton color="inherit" onClick={() => history.push('/meals/new', { recipeId: recipe.id })}>
                <AddTaskOutlined />
              </IconButton>
            </Tooltip>
            {!isPhone &&
              <Tooltip title="Edit Recipe">
                <IconButton color="inherit" onClick={startEditing}>
                  <EditOutlined />
                </IconButton>
              </Tooltip>
            }
          </Toolbar>
        </AppBar>
      }
      {isPhone && !embedded &&
        <Zoom in unmountOnExit>
          <Fab color="primary" variant="extended" sx={{ position: 'fixed', bottom: 15, right: 15, zIndex: 1050, displayPrint: 'none' }} onClick={startEditing}>
            <EditOutlined sx={{ mr: 1 }} />
            Edit
          </Fab>
        </Zoom>
      }
      <Dialog open={sharing} onClose={() => setSharing(false)} maxWidth="sm">
        <DialogTitle>Share Recipe</DialogTitle>
        <DialogContent>
          <DialogContentText>Anyone with the link can view and save this recipe. Updates made after this point will not be visible.</DialogContentText>
          <br />
          <Stack direction="row" gap={1}>
            <TextField size="small" variant="outlined" value={sharingLink} fullWidth />
            <IconButton onClick={copyLink} disabled={copied}>
              {copied ? <CheckOutlined /> : <ContentCopyOutlined />}
            </IconButton>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog open={customScaleOpen} onClose={() => setCustomScaleOpen(false)} maxWidth="xs">
        <DialogTitle>Custom Scale</DialogTitle>
        <DialogContent>
          <Stack direction="row" gap={1}>
            <TextField value={customScaleString} onChange={e => setCustomScaleString(e.target.value)} size="small" variant="outlined" fullWidth />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={finalizeCustomScale}>Set</Button>
        </DialogActions>
      </Dialog>
      <ScrollWrapper maxWidth="lg" padTop padFab={!embedded} disableGutters={embedded}>
        {/* <Grid container spacing={SPACING}>
          {!embedded && !isPhone &&
            <Grid item xs={12} md={8}>
              <Typography variant="h2" gutterBottom={!isPhone} align={isPhone ? 'center' : 'inherit'}>{recipe.name}</Typography>
            </Grid>
          }
          {isPhone ? '' : <Grid item sx={{ flexGrow: 1 }}></Grid>}
          {isPhone ? <Grid item xs={2}></Grid> : ''}
          {isPhone || embedded ? '' :
            <Fragment>
              <Grid item>
                <Button variant="outlined" startIcon={<ShareOutlined />} onClick={startShare}>Share</Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" startIcon={<AddTaskOutlined />} onClick={() => history.push('/meals/new', { recipeId: recipe.id })}>Plan</Button>
              </Grid>
              <Grid item>
                <Button variant="contained" startIcon={<EditIcon />} onClick={startEditing}>Edit</Button>
              </Grid>
            </Fragment>
          }
        </Grid> */}
        {/* TODO: Alternate format for recipe properties */}
        {/* <Grid container direction="row" wrap="nowrap" spacing={2} pb={1} mb={1} sx={{ overflowX: 'auto', width: 'calc(100% + 32px)' }}>
          {!!recipe.yield &&
            <Grid item>
              <Paper elevation={1} sx={{ padding: 2, display: 'grid', placeItems: 'center' }}>
                <Box>
                  <Typography variant="h4">{prettifyFraction(scaleAmount([{ fraction: [recipe.yield, 1] }], currentScale)[0].fraction)}</Typography>
                  <Typography variant="body2">{recipe.yieldLabel}</Typography>
                </Box>
              </Paper>
            </Grid>
          }
          {!!recipe.temperature &&
            <Grid item>
              <Paper elevation={1} sx={{ padding: 2, display: 'grid', placeItems: 'center' }}>
                <Box>
                  <Typography variant="h4">{recipe.temperature}°</Typography>
                  <Typography variant="body2">Temp</Typography>
                </Box>
              </Paper>
            </Grid>
          }
          {!!recipe.prepTime &&
            <Grid item>
              <Paper elevation={1} sx={{ padding: 2, display: 'grid', placeItems: 'center' }}>
                <Box>
                  <Typography variant="h4">{recipe.prepTime}{timeUnits[recipe.prepUnit ?? 1]}</Typography>
                  <Typography variant="body2">Prep</Typography>
                </Box>
              </Paper>
            </Grid>
          }
          {!!recipe.cookTime &&
            <Grid item>
              <Paper elevation={1} sx={{ padding: 2, display: 'grid', placeItems: 'center' }}>
                <Box>
                  <Typography variant="h4">{recipe.cookTime}{timeUnits[recipe.prepUnit ?? 1]}</Typography>
                  <Typography variant="body2">Cook</Typography>
                </Box>
              </Paper>
            </Grid>
          }
          {!!recipe.extraTime &&
            <Grid item>
              <Paper elevation={1} sx={{ padding: 2, display: 'grid', placeItems: 'center' }}>
                <Box>
                  <Typography variant="h3">{recipe.extraTime}{timeUnits[recipe.prepUnit ?? 1]}</Typography>
                  <Typography variant="body2">Extra</Typography>
                </Box>
              </Paper>
            </Grid>
          }
        </Grid> */}
        <Grid container spacing={1} mb={2}>
          {!!recipe.yield && <Grid item><Chip icon={<GroupIcon />} label={`${yieldDisplay} ${yieldLabel}`} /></Grid>}
          {!!recipe.temperature && <Grid item><Chip icon={<TemperatureIcon />} label={`${recipe.temperature}°`} /></Grid>}
          {!!recipe.prepTime && <Grid item><Chip icon={<ClockIcon />} label={`Prep: ${recipe.prepTime} ${timeUnits[recipe.prepUnit ?? 1]}`} /></Grid>}
          {!!recipe.cookTime && <Grid item><Chip icon={<ClockIcon />} label={`Cook: ${recipe.cookTime} ${timeUnits[recipe.cookUnit ?? 1]}`} /></Grid>}
          {!!recipe.extraTime && <Grid item><Chip icon={<ClockIcon />} label={`Extra: ${recipe.extraTime} ${timeUnits[recipe.extraUnit ?? 1]}`} /></Grid>}
        </Grid>
        {
          !embedded && !!recipe.tags.length &&
          <Grid container spacing={1} mb={2}>
            {recipe.tags.map(tag => (
              <Grid item key={`${recipe.id}-tag-${tag}`}>
                <Chip size="small" label={tag} onClick={() => history.push(`/tags/${tag.toLowerCase()}`)} />
              </Grid>
            ))}
          </Grid>
        }
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Menu
              open={contextMenu !== null}
              onClose={() => setContextMenu(null)}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
            >
              <MenuItem onClick={addIngredientToList}>Add '{contextMenu?.ingredient.item.trim()}' to shopping list</MenuItem>
            </Menu>
            <Box>
              <Stack direction="row" justifyContent="center" alignItems="middle">
                <Typography variant="h4" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>Ingredients</Typography>
                <IconButton onClick={() => navigator.clipboard.writeText(recipe.ingredients)} sx={{ displayPrint: 'none' }}><CopyAllOutlined /></IconButton>
              </Stack>
              <Stack direction="row" justifyContent="center" gap={1} pt={2} sx={{ displayPrint: 'none' }}>
                {standardScale.map((scale =>
                  <Chip
                    key={`scale-chip-${scale.display}`}
                    label={scale.display}
                    color={scale.isActive ? 'primary' : 'default'}
                    onClick={() => setCurrentScale(scale.display)}
                    sx={{ minWidth: '32px' }}
                  />
                ))}
                <Chip
                  color={customScale.isActive ? 'primary' : 'default'}
                  label={customScale.display}
                  onClick={() => setCustomScaleOpen(true)}
                />
              </Stack>
              <List dense>
                {recipe.parsedIngredients.map((ingredient, index) => {
                  const labelId = `ingredients-${index}`;

                  return ingredient.isHeader ?
                    (
                      <ListSubheader key={labelId}>{ingredient.item}</ListSubheader>
                    ) :
                    (
                      <ListItem key={labelId} disablePadding onContextMenu={e => handleContextMenu(e, ingredient)}>
                        <ListItemButton role={undefined} onClick={() => toggleIngredient(index)} dense>
                          <ListItemIcon sx={{ minWidth: '3em' }}>
                            <Checkbox
                              edge="start"
                              checked={isIngredientChecked(index)}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                              size="small"
                            />
                          </ListItemIcon>
                          <ListItemText
                            sx={{ textDecoration: isIngredientChecked(index) ? 'line-through' : '' }}
                            id={labelId}
                            primary={<IngredientView ingredient={ingredient} scale={currentScale.fraction} />}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                })}
              </List>
            </Box>
          </Grid >
          <Grid item xs={12} md={8}>
            <Box>
              <Stack direction="row" justifyContent="center" alignItems="middle">
                <Typography variant="h4" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>Instructions</Typography>
                <IconButton onClick={() => navigator.clipboard.writeText(recipe.instructions)} sx={{ displayPrint: 'none' }}><CopyAllOutlined /></IconButton>
              </Stack>
              <List>
                {recipe.parsedInstructions.map((instruction, index) => {
                  const labelId = `instructions-${index}`;

                  return instruction.isHeader ?
                    (
                      <ListSubheader key={labelId}>{instruction.text}</ListSubheader>
                    ) :
                    (
                      <ListItem key={labelId} disablePadding>
                        <ListItemButton role={undefined} onClick={() => toggleInstruction(index)} dense>
                          <ListItemIcon sx={{ minWidth: '3em' }}>
                            <Checkbox
                              edge="start"
                              checked={isInstructionChecked(index)}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                              size="small"
                            />
                          </ListItemIcon>
                          <ListItemText
                            sx={{ textDecoration: isInstructionChecked(index) ? 'line-through' : '' }}
                            id={labelId}
                            primary={instruction.text}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                })}
              </List>
            </Box>
          </Grid>
          {(recipe.notes || recipe.author || recipe.source || recipe.isMainDish) && <>
            <Grid item xs={12} sx={{ displayPrint: 'none' }}>
              <Divider>🐾🐾🐾</Divider>
            </Grid>
            <Grid item xs={12}>
              <Box>
                <Typography variant="h4" gutterBottom>Notes</Typography>
                {recipe.notes.split('\n').filter(l => !!l).map((line, index) =>
                  <Typography key={`note-${index}`} variant="body2">{line}</Typography>
                )}
                {(recipe.author || recipe.source) &&
                  <>
                    <Typography variant="body2">
                      Source: {recipe.author} {recipe.source &&
                        <Linkify
                          componentDecorator={(decoratedHref, decoratedText, key) =>
                            <a
                              href={decoratedHref}
                              key={key}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ textDecoration: 'none', color: theme.palette.primary.main }}
                            >{decoratedText}</a>
                          }
                          textDecorator={(urlText) => {
                            try {
                              return new URL(urlText).hostname.replace('www.', '');
                            } catch {
                              return urlText;
                            }
                          }
                          }
                        >
                          {recipe.source}
                        </Linkify>}
                    </Typography>
                  </>
                }
                {recipe.isMainDish && <Typography variant="body2" sx={{ displayPrint: 'none' }}>This recipe is marked as a main dish and will show up in suggestions.</Typography>}
              </Box>
            </Grid>
          </>}
          {!embedded && <>
            <Grid item xs={12} sx={{ displayPrint: 'none' }}>
              <Divider>🐾🐾🐾</Divider>
            </Grid>
            <Grid item xs={12} md={6} sx={{ displayPrint: 'none' }}>
              <Box>
                <Typography variant="h4" gutterBottom>Linked Recipes</Typography>
                <List dense>
                  {recipe.linkedRecipeIds.map(id =>
                    <ListItem key={`linked-recipe-${id}`} disableGutters>
                      <ListItemButton onClick={() => history.push(`/recipes/${id}`)}>
                        {recipes.find(x => x.id === id)?.name ?? 'Deleted Recipe'}
                      </ListItemButton>
                    </ListItem>
                  )}
                </List>
                {!recipe.linkedRecipeIds.length && <Typography variant="body2">No linked recipes</Typography>}
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ displayPrint: 'none' }}>
              <Box>
                <Typography variant="h4" gutterBottom>Sides</Typography>
                {recipe.sides.map((side, i) => <Typography key={`sides-${i}`} variant="body2">{side}</Typography>)}
                {!recipe.sides.length && <Typography variant="body2">No sides</Typography>}
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ displayPrint: 'none' }}>
              <Divider>🐾🐾🐾</Divider>
            </Grid>
            <Grid item xs={12} md={6} sx={{ displayPrint: 'none' }}>
              <Box>
                <Typography variant="h4" gutterBottom>History</Typography>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date Made</TableCell>
                        <TableCell>Meal Name</TableCell>
                        <TableCell>Scale</TableCell>
                        <TableCell>Leftovers</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!recipe.history.length &&
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell rowSpan={4}>No history to display</TableCell>
                        </TableRow>
                      }
                      {recipe.history.map((entry, index) => (
                        <TableRow key={`history-${index}`}>
                          <TableCell>
                            {entry.date.toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })}
                          </TableCell>
                          <TableCell>{entry.meal}</TableCell>
                          <TableCell>{prettifyFraction(parseFraction(entry.scale))}</TableCell>
                          <TableCell>{entry.hadLeftovers ? 'Yes' : 'No'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </>}
        </Grid>
      </ScrollWrapper>
    </PageWrapper>
  );
}