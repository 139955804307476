import { AddOutlined, AutoFixHighOutlined, CheckOutlined, ClearOutlined, CloseOutlined, ExpandMoreOutlined } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Checkbox, Collapse, Dialog, DialogContent, Divider, Fab, Grid, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Stack, Toolbar, Tooltip, Typography, Zoom, useMediaQuery, useTheme } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { DragDropContext, Draggable, DraggableLocation, DropResult, Droppable } from 'react-beautiful-dnd';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { ExtraSelect } from '../../components/ExtraSelect';
import { PageWrapper } from '../../components/PageWrapper';
import { RecipeSelect } from '../../components/RecipeSelect';
import { ScrollWrapper } from '../../components/ScrollWrapper';
import { GrowTransition, SlideTransition } from '../../components/Transitions';
import { ReactComponent as NoPlanIngredients } from '../../images/NoPlanIngredients.svg';
import { ShoppingList } from '../../lists';
import { saveMeal, setDate } from '../../meals';
import { MealView, ORDER_SPACING, Plan, PlanGroup } from '../../meals/meal';
import { useSuggestions } from '../../meals/suggest';
import { RecipeView } from '../../recipes';
import { getDate } from '../../shared/dates';
import { getId } from '../../shared/identifiers';
import CompleteMealDialog from './CompleteMealDialog';
import MealCreate from './MealCreate';
import MealDetail from './MealDetail';

export interface MealPlanProps {
  plan: Plan;
  recipes: RecipeView[];
  lists: ShoppingList[];
}

export default function MealPlan({ plan, recipes, lists }: MealPlanProps) {
  const [currPlan, setPlan] = useState<Plan>(plan);
  const [movedId, setMovedId] = useState<string>();

  useEffect(() => setPlan(plan), [plan]);

  const history = useHistory();
  const individualMatch = useRouteMatch<{ id: string }>('/meals/:id');
  const id = individualMatch?.params?.id;

  const planGroups = currPlan.groups;
  const setPlanGroups = (groups: PlanGroup[]) => setPlan({ groups });

  const [deletingId, setDeletingId] = useState('');

  const reorder = (list: MealView[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    // TODO: Extract logic and make reorder generic
    const previousOrder = endIndex - 1 >= 0 ? result[endIndex - 1].planOrder : 0;
    const nextOrder = result[endIndex] ? result[endIndex].planOrder : previousOrder + ORDER_SPACING;
    const thisOrder = Math.floor((previousOrder + nextOrder) / 2);

    setDate(removed.id, thisOrder);

    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source: PlanGroup, destination: PlanGroup, droppableSource: DraggableLocation, droppableDestination: DraggableLocation) => {
    const sourceClone = Array.from(source.meals);
    const destClone = Array.from(destination.meals);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    // This is weird - we need to disable transitions if the current user just moved the meal.
    setMovedId(removed.id);
    setTimeout(() => setMovedId(undefined), 0);

    const previousOrder = droppableDestination.index - 1 >= 0 ? destination.meals[droppableDestination.index - 1].planOrder : 0;
    const nextOrder = destination.meals[droppableDestination.index] ? destination.meals[droppableDestination.index].planOrder : previousOrder + ORDER_SPACING;
    const thisOrder = Math.floor((previousOrder + nextOrder) / 2);

    setDate(removed.id, thisOrder, destination.dayOffset);

    destClone.splice(droppableDestination.index, 0, removed);

    return {
      [droppableSource.droppableId]: sourceClone,
      [droppableDestination.droppableId]: destClone
    };
  };

  function onDragEnd({ source, destination }: DropResult) {
    if (!destination) {
      return;
    }

    const isSameList = source.droppableId === destination.droppableId;
    if (isSameList) {
      const groupIndex = +source.droppableId;
      const items = reorder(
        planGroups[groupIndex].meals,
        source.index,
        destination.index
      );

      setPlanGroups([
        ...planGroups.slice(0, groupIndex),
        { ...planGroups[groupIndex], meals: items },
        ...planGroups.slice(groupIndex + 1, planGroups.length)
      ]);
    } else {
      const sourceGroupIndex = +source.droppableId;
      const destinationGroupIndex = +destination.droppableId;
      const result = move(
        planGroups[sourceGroupIndex],
        planGroups[destinationGroupIndex],
        source,
        destination
      );

      const tempPlanGroups: PlanGroup[] = [
        ...planGroups.slice(0, sourceGroupIndex),
        { ...planGroups[sourceGroupIndex], meals: result[source.droppableId] },
        ...planGroups.slice(sourceGroupIndex + 1, planGroups.length)
      ];

      const finalPlanGroups: PlanGroup[] = [
        ...tempPlanGroups.slice(0, destinationGroupIndex),
        { ...tempPlanGroups[destinationGroupIndex], meals: result[destination.droppableId] },
        ...tempPlanGroups.slice(destinationGroupIndex + 1, tempPlanGroups.length)
      ];

      setPlanGroups(finalPlanGroups);
    }

  }

  function getSecondaryText(group: PlanGroup, meal: MealView) {
    if (group.dayOffset < 0 && meal.planDate) {
      return meal.planDate.toDateString();
    }

    const trimmedExtras = meal.extras.filter(e => !!e.trim());
    if (trimmedExtras.length) {
      const extras = [...trimmedExtras];
      const last = extras.pop();

      if (!extras.length) {
        return 'with ' + last;
      }

      if (extras.length === 1) {
        return `with ${extras[0]} and ${last}`;
      }

      return 'with ' + extras.join(', ') + ', and ' + last;
    }

    return undefined;
  }

  const isPhone = useMediaQuery(useTheme().breakpoints.down('md'));

  const deletingMeal = currPlan.groups.flatMap(g => g.meals).find(m => m.id === deletingId);

  const theme = useTheme();

  function getShoppedForColor(meal: MealView) {
    const mealListItems = lists.flatMap(l => l.items).filter(i => i.meal?.mealId === meal.id);
    const noItems = !(meal.recipeIds.length || meal.extras.length);
    const shoppedFor = meal.addedToList;
    const shoppingFor = mealListItems.some(i => !i.checked);

    if (noItems) {
      return theme.palette.success.main;
    }

    if (shoppingFor) {
      return theme.palette.warning.main;
    }

    if (shoppedFor) {
      return theme.palette.success.main;
    }

    return theme.palette.text.disabled;
  }

  const [suggestions, checkSuggestion, removeSuggestion, setRecipes, setExtras] = useSuggestions(plan, recipes);

  function createMealName(recipeIds: string[]) {
    if (!recipeIds.length) {
      return '';
    }

    if (recipeIds.length === 1) {
      return recipes.find(r => r.id === recipeIds[0])?.name ?? '';
    }

    const recipeNames = [...recipeIds].map(id => recipes.find(r => r.id === id)?.name);
    const last = recipeNames.pop();
    return recipeNames.join(', ') + (recipeIds.length >= 3 ? ',' : '') + ' and ' + last;
  }

  async function planSuggestions() {
    const checked = suggestions.filter(x => x.checked);

    for (const suggestion of checked) {
      await saveMeal({
        id: getId(),
        name: '',
        displayName: createMealName([suggestion.recipeId, ...suggestion.recipeIds]),
        recipeIds: [suggestion.recipeId, ...suggestion.recipeIds],
        recipes: [],
        recipeState: {},
        extras: suggestion.extras,
        planOrder: 0,
        addedToList: false,
      });

      checkSuggestion(suggestion, false);
    }

    history.push('/meals');
  }

  return (
    <PageWrapper>
      {isPhone &&
        <AppBar color="secondary" position="relative" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              Meal Plan
            </Typography>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Stack direction="row" gap={2}>
              <Tooltip title={'Suggest Recipes'}>
                <IconButton color="inherit" onClick={() => history.push('/meals/suggest')}>
                  <AutoFixHighOutlined />
                </IconButton>
              </Tooltip>
            </Stack>
          </Toolbar>
        </AppBar>
      }
      <ScrollWrapper maxWidth="sm" padTop padFab disableGutters>
        {isPhone ?
          <Fragment>
            <Zoom in unmountOnExit>
              <Fab
                color="primary" variant="extended"
                sx={{ position: 'fixed', bottom: 70, right: 15, zIndex: 1050 }}
                onClick={() => history.push('/meals/new')}
              >
                <AddOutlined sx={{ mr: 1 }} />
                Create
              </Fab>
            </Zoom>
          </Fragment>
          : ''}
        <Dialog
          open={id === 'suggest'}
          onClose={() => history.push('/meals')}
          fullScreen={isPhone}
          fullWidth
          TransitionComponent={isPhone ? SlideTransition : GrowTransition}
        >
          <AppBar position="sticky" color="secondary">
            <Toolbar>
              <IconButton edge="start" color="inherit" sx={{ mr: 1 }} onClick={() => history.push('/meals')}>
                <CloseOutlined />
              </IconButton>
              <Typography variant="h5" component="div">
                Meal Suggestions
              </Typography>
              <Box sx={{ flex: '1 1 auto' }}></Box>
              <Button variant="text" color="primary" onClick={planSuggestions} disabled={!suggestions.filter(x => x.checked).length}>Plan Selected</Button>
            </Toolbar>
          </AppBar>
          {!!suggestions.length ?
            <DialogContent sx={{ p: 0 }}>
              <TransitionGroup>
                {suggestions.filter(x => x.checked).map((suggestion) =>
                  <Collapse key={`suggestion-${suggestion.recipeId}`}>
                    <Accordion elevation={0}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreOutlined />}
                        sx={{
                          pl: 0,
                          pr: 3,
                          '.MuiAccordionSummary-content': { m: 0 },
                          '.MuiAccordionSummary-content.Mui-expanded': { m: 0 },
                        }}
                      >
                        <List dense disablePadding sx={{ width: '100%' }}>
                          <ListItem>
                            <ListItemIcon><Checkbox checked onClick={() => checkSuggestion(suggestion, false)} /></ListItemIcon>
                            <ListItemText primary={suggestion.name} secondary={suggestion.description}></ListItemText>
                          </ListItem>
                        </List>
                      </AccordionSummary>
                      <AccordionDetails sx={{ pl: 4, pt: 0 }}>
                        <Grid container justifyContent="stretch">
                          <Grid item xs={3} sm={2} mt={3}>
                            <Typography>Recipes</Typography>
                          </Grid>
                          <Grid item xs>
                            <RecipeSelect
                              recipes={recipes}
                              value={suggestion.recipeIds.map(id => ({ title: recipes.find(r => r.id === id)?.name ?? 'Deleted Recipe', id }))}
                              recipeIds={[suggestion.recipeId]}
                              hiddenRecipeIds={[suggestion.recipeId]}
                              onChange={results => setRecipes(suggestion, results.map(x => x.id))}
                              size="small"
                            />
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={3} sm={2} mt={3}>
                            <Typography>Sides</Typography>
                          </Grid>
                          <Grid item xs>
                            <ExtraSelect
                              value={suggestion.extras}
                              onChange={extras => setExtras(suggestion, extras)}
                              size="small"
                              recipes={recipes}
                              recipeIds={[suggestion.recipeId, ...suggestion.recipeIds]}
                            />
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Collapse>
                )}
              </TransitionGroup>
              {!!suggestions.filter(x => x.checked).length && !!suggestions.filter(x => !x.checked).length &&
                <Divider />
              }
              <List dense disablePadding>
                <TransitionGroup>
                  {suggestions.filter(x => !x.checked).map(suggestion =>
                    <Collapse key={`suggestion-${suggestion.recipeId}`}>
                      <ListItem>
                        <ListItemIcon><Checkbox checked={false} onClick={() => checkSuggestion(suggestion, true)} /></ListItemIcon>
                        <ListItemText primary={suggestion.name} secondary={suggestion.description}></ListItemText>
                        <ListItemSecondaryAction>
                          <Tooltip title="Skip meal suggestion">
                            <IconButton onClick={e => { e.stopPropagation(); removeSuggestion(suggestion) }}>
                              <ClearOutlined />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Collapse>
                  )}
                </TransitionGroup>
              </List>
            </DialogContent>
            :
            <DialogContent>
              <Box sx={{ textAlign: 'center', marginLeft: 1, marginRight: 1 }}>
                <NoPlanIngredients />
                <Typography gutterBottom variant="h2" component="div">
                  I got nothing.
                </Typography>
                <Typography gutterBottom variant="body2" color="text.secondary">
                  Try adding more recipes, or maybe it's time to go out.
                </Typography>
              </Box>
            </DialogContent>
          }
        </Dialog>
        <CompleteMealDialog onClose={() => setDeletingId('')} deletingMeal={deletingMeal} recipes={recipes} />
        <MealCreate recipes={recipes} active={id === 'new'} onClose={() => history.replace('/')} />
        <MealDetail
          meals={plan.groups.flatMap(g => g.meals)}
          recipes={recipes}
          active={!!id && id !== 'new' && id !== 'suggest'}
          onClose={() => history.goBack()}
        />
        <DragDropContext onDragEnd={onDragEnd}>
          {planGroups.map((group, index) =>
            <Fragment key={`group-${group.dayOffset}`}>
              <Droppable droppableId={`${index}`} isDropDisabled={group.dayOffset < -1}>
                {(provided, snapshot) => (
                  <Grid container mt={index === 0 ? 0 : 1}>
                    <Grid item xs={2} pt={1}>
                      <Link
                        style={{ textDecoration: 'none', color: 'inherit', textAlign: 'center' }}
                        to={{ pathname: '/meals/new', state: { planDate: group.dayOffset >= 0 ? getDate(group.dayOffset) : undefined } }}
                      >
                        <Typography variant="subtitle2">{group.heading}</Typography>
                        <Typography variant="h5" sx={{ fontWeight: '500' }}>{group.subHeading}</Typography>
                      </Link>
                    </Grid>
                    <Grid item xs={10}>
                      <List
                        sx={{
                          width: '100%',
                          bgcolor: snapshot.isDraggingOver ? 'secondary.dark' : '',
                          borderRadius: 1,
                          minHeight: '4.5em',
                          padding: 1,
                          transitionDuration: '0.25s'
                        }}
                        ref={provided.innerRef}
                      >
                        <TransitionGroup>
                          {group.meals.map((meal, index) =>
                            <Collapse key={meal.id} timeout={meal.id === movedId ? 0 : 'auto'}>
                              <Draggable draggableId={meal.id} index={index}>
                                {(provided, snapshot) => (
                                  <ListItem
                                    ref={provided.innerRef}
                                    style={provided.draggableProps.style}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      bgcolor: theme => theme.palette.mode === 'dark' ? '#222' : 'secondary.main',
                                      borderRadius: 1,
                                      boxShadow: snapshot.isDragging ? 3 : 0,
                                      transitionDuration: '0.25s',
                                      marginBottom: 1,
                                      borderLeftWidth: '4px',
                                      borderLeftStyle: 'solid',
                                      borderLeftColor: getShoppedForColor(meal),
                                      paddingLeft: 2,
                                    }}
                                    secondaryAction={
                                      <IconButton edge="end" color="success" onClick={e => { e.stopPropagation(); setDeletingId(meal.id); }}><CheckOutlined /></IconButton>
                                    }
                                    onClick={() => history.push(`/meals/${meal.id}`)}
                                  >
                                    <ListItemText
                                      secondaryTypographyProps={{ sx: { color: (theme) => theme.palette.grey.A400 } }}
                                      primary={meal.displayName}
                                      secondary={getSecondaryText(group, meal)}
                                    ></ListItemText>
                                  </ListItem>
                                )}
                              </Draggable>
                            </Collapse>
                          )}
                          {provided.placeholder}
                        </TransitionGroup>
                      </List>
                    </Grid>
                  </Grid>
                )}
              </Droppable>
              {group.divider && <Divider variant="inset" sx={{ mt: 1 }} />}
            </Fragment>
          )}
        </DragDropContext>
      </ScrollWrapper>
    </PageWrapper >
  );
};