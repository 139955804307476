import { EventBusConsumer, EventBusProducer, Events } from '../shared/events';
import { unwrapPromise } from '../shared/subscriptions';
import { ActiveWorkspaceFirestoreClient } from '../workspaces/firestore';
import { LeftoverFirestoreClient } from './firestore';
import { Leftover } from './leftover';
import { db as firestore } from '../shared/firebase';
import { db as localDb } from '../shared/database';
import { LeftoverRepository } from './repository';

type Callback = (recipes: Leftover[]) => void;

const consumer = new EventBusConsumer();
const producer = new EventBusProducer();

const workspaceClient = new ActiveWorkspaceFirestoreClient(producer);

const client = new LeftoverFirestoreClient(firestore);
const repository = new LeftoverRepository(localDb, client);

export function subscribeToLeftovers(callback: Callback): () => void {
  return unwrapPromise(cb => subscribeInternal(cb), callback);
}

async function subscribeInternal(callback: Callback): Promise<() => void> {
  return consumer.subscribe(Events.LEFTOVERS_CHANGED, async () => {
    const workspaceId = await workspaceClient.getActiveId();

    if (workspaceId) {
      const leftovers = await repository.getLeftovers(workspaceId);
      callback(leftovers);
    }
  });
}

export async function saveLeftover(leftover: Leftover): Promise<void> {
  const workspaceId = await workspaceClient.getActiveId();

  if (workspaceId) {
    return repository.save(workspaceId, leftover);
  }
}

export async function deleteLeftover(leftoverId: string): Promise<void> {
  const workspaceId = await workspaceClient.getActiveId();

  if (workspaceId) {
    return repository.delete(workspaceId, leftoverId);
  }
}