const url = 'Uint8ArdomValuesObj012345679BCDEFGHIJKLMNPQRSTWXYZcfghkpqvwxyz';

export function getId() {
  let id = '';
  let size = 16;
  const bytes = crypto.getRandomValues(new Uint8Array(size));
  while (0 < size--) {
    id += url[bytes[size] & 61];
  }
  return id;
}
