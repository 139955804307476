import { AstNode, AstInternalNode, AstLeafNode } from './ast';
import { Emitter } from './emitter';

export class StringEmitter implements Emitter<string> {
  public emit(node: AstNode): string {
    switch (node.type) {
      case 'root':
      case 'amount':
      case 'unit':
      case 'full unit':
        return this.emitRoot(node);
      case 'preparation':
      case 'size':
      case 'cross':
      case 'whitespace':
      case 'paren':
      case 'comma':
      case 'number':
      case 'ingredient':
      case 'unit fragment':
      case 'whole unit':
        return this.emitLeaf(node);
      case 'unit size':
        return this.emitUnitSize(node);
      case 'full ingredient':
        return this.emitFullIngredient(node);
      case 'preposition':
        return '';
    }
  }

  public default() {
    return '';
  }

  private emitRoot(node: AstInternalNode): string {
    return node.children.map(x => this.emit(x)).join(' ').replace(/\s+/, ' ').trim();
  }

  private emitLeaf(node: AstLeafNode): string {
    return node.value;
  }

  private emitUnitSize(node: AstInternalNode): string {
    return `⨯ ${node.children.filter(x => ['number', 'unit'].includes(x.type)).map(x => this.emit(x)).join(' ')}`;
  }

  private emitFullIngredient(node: AstInternalNode): string {
    return node.children
      .map(x => {
        if (x.type === 'comma') return x.value;
        return ' ' + (x as AstLeafNode).value;
      })
      .join('').replace(/\s+/, ' ').trim();
  }
}