import { Box } from '@mui/material';
import { ReactNode } from 'react';

interface PageWrapperProps {
  children?: ReactNode;
}

export function PageWrapper({ children }: PageWrapperProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', overflow: 'hidden' }}>
      {children}
    </Box>
  );
}