import { useHistory } from 'react-router';
import RecipeEdit from '../../components/RecipeEdit';
import { getId } from '../../shared/identifiers';
import { RecipeView as Recipe, saveRecipe } from '../../recipes';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow, Slide, useMediaQuery, useTheme } from '@mui/material';
import { forwardRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ImportedRecipe } from '../../recipes/recipe';

export interface RecipeCreateProps {
  recipes: Recipe[];
  tags: string[];
  active: boolean;
  onClose: () => void;
}

const GrowTransition = forwardRef(function Transition(props: any, ref) {
  return <Grow ref={ref} {...props}>{props.children}</Grow>;
});

const SlideTransition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props}>{props.children}</Slide>;
});

export default function RecipeCreate({ recipes, tags, active, onClose }: RecipeCreateProps) {
  const { state } = useLocation<{recipe: ImportedRecipe}>();

  const newRecipe: Recipe = {
    id: getId(),
    name: state?.recipe?.name ?? '',
    parsedIngredients: [],
    parsedInstructions: [],
    ingredients: state?.recipe?.ingredients?.join('\n') ?? '',
    instructions: state?.recipe?.instructions?.join('\n') ?? '',
    notes: '',
    yield: state?.recipe?.yield ?? '',
    yieldLabel: state?.recipe?.yieldLabel ?? 'servings',
    prepTime: state?.recipe?.prepTime?.toString() ?? '',
    prepUnit: state?.recipe?.prepUnit ?? 1,
    cookTime: state?.recipe?.cookTime?.toString() ?? '',
    cookUnit: state?.recipe?.cookUnit ?? 1,
    extraTime: '',
    extraUnit: 1,
    author: state?.recipe?.author ?? '',
    source: state?.recipe?.source ?? '',
    temperature: 0,
    isMainDish: false,
    tags: [],
    history: [],
    linkedRecipeIds: [],
    sides: [],
  };

  const history = useHistory();

  const [closing, setClosing] = useState(false);
  const [modified, setModified] = useState(false);

  function save(recipe: Recipe) {
    saveRecipe(recipe).then(() => history.replace(`/recipes/${recipe.id}`));
  }

  function beginCloseModal() {
    if (modified) {
      setClosing(true);
    } else {
      confirmExit();
    }
  }

  function confirmExit() {
    setModified(false);
    setClosing(false);
    onClose();
  }

  function cancelExit() {
    setClosing(false);
  }

  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <Dialog
        fullScreen={!isDesktop}
        maxWidth="xl"
        fullWidth
        open={active}
        onClose={beginCloseModal}
        TransitionComponent={isPhone ? SlideTransition : GrowTransition}
        sx={{ '.MuiDialog-paper': { height: '100%' } }}
      >
        <RecipeEdit recipe={newRecipe} recipes={recipes} tags={tags} cancelEditing={beginCloseModal} save={save} changed={setModified} />
      </Dialog>
      <Dialog
        open={closing}
        onClose={cancelExit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to stop editing?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Any changes will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelExit} autoFocus>Cancel</Button>
          <Button color="error" onClick={confirmExit}>Discard</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

