import { Firestore, doc, updateDoc } from "firebase/firestore";
import { Collections } from '../shared/firebase';

export interface FirestoreStaple {
  weight: number;
}

export type FirestoreStapleSet = { [key: string]: FirestoreStaple };

export class StapleFirestoreClient {
  constructor(private firestore: Firestore) { }

  public async updateStaples(workspaceId: string, staples: FirestoreStapleSet): Promise<void> {
    const workspaceDoc = doc(this.firestore, Collections.WORKSPACE, workspaceId);
    updateDoc(workspaceDoc, { staples });
  }
}