import { RecipeView } from '../recipes';

export interface SearchGroup {
  label: string;
  results: SearchResult[];
}

export interface SearchResult {
  id: string;
  title: string;
  subtitle?: string;
  group?: Group;
}

export interface Match {
  label: string;
  items: string[];
}

export interface Group {
  label: string;
  priority: number;
}

export function searchRecipes(searchTerm: string, recipes: RecipeView[]): SearchResult[] {
  const results: SearchResult[] = [];
  const normalizedTerm = stripDiacritics(searchTerm.toLowerCase().trim());

  if (!normalizedTerm) {
    return recipes.map(r => ({ id: r.id, title: r.name || 'Untitled Recipe', matches: [] }));
  }

  for (const recipe of recipes) {
    const matchingTags = recipe.tags.filter(t => stripDiacritics(t.toLowerCase()).includes(normalizedTerm));
    const matchingIngredients = recipe.parsedIngredients
      .filter(i => !i.isHeader)
      .filter(i => stripDiacritics(i.item.toLowerCase()).includes(normalizedTerm))
      .map(i => i.item);
    const matchingSides = recipe.sides.filter(s => stripDiacritics(s.toLowerCase()).includes(normalizedTerm));

    const titleMatches = stripDiacritics(recipe.name.toLowerCase()).includes(normalizedTerm);
    const tagsMatch = !!matchingTags.length;
    const ingredientsMatch = !!matchingIngredients.length;
    const sidesMatch = !!matchingSides.length;
    const authorMatches = stripDiacritics(recipe.author.toLowerCase()).includes(normalizedTerm);

    if (titleMatches || tagsMatch || ingredientsMatch || sidesMatch || authorMatches) {
      const matches: Match[] = [];

      if (!titleMatches && tagsMatch) {
        matches.push({
          label: 'Tags',
          items: matchingTags,
        });
      }

      if (!titleMatches && !tagsMatch && ingredientsMatch) {
        matches.push({
          label: 'Ingredients',
          items: matchingIngredients,
        });
      }

      if (!titleMatches && !tagsMatch && !ingredientsMatch && sidesMatch) {
        matches.push({
          label: 'Sides',
          items: matchingSides,
        });
      }

      if (!titleMatches && !tagsMatch && !ingredientsMatch && !sidesMatch && authorMatches) {
        matches.push({
          label: 'Author',
          items: [recipe.author],
        });
      }

      const result: SearchResult = {
        id: recipe.id,
        title: recipe.name || 'Untitled Recipe',
        subtitle: getSecondaryText(matches),
        group: getGroup(titleMatches, tagsMatch, ingredientsMatch, sidesMatch, authorMatches),
      };

      results.push(result);
    }
  }

  results.sort((a, b) => {
    if (a.group && b.group) {
      return a.group.priority - b.group.priority;
    }

    return a.title.localeCompare(b.title);
  });

  return results;
}

export function searchAndGroupRecipes(searchTerm: string, recipes: RecipeView[]): SearchGroup[] {
  return getGroups(searchRecipes(searchTerm, recipes));
}

function getGroup(titleMatches: boolean, tagsMatch: boolean, ingredientsMatch: boolean, sidesMatch: boolean, authorMatches: boolean): Group | undefined {
  if (titleMatches) {
    return {
      label: 'Title',
      priority: 1,
    };
  }

  if (tagsMatch) {
    return {
      label: 'Tags',
      priority: 2,
    };
  }

  if (ingredientsMatch) {
    return {
      label: 'Ingredients',
      priority: 3,
    };
  }

  if (sidesMatch) {
    return {
      label: 'Sides',
      priority: 4,
    };
  }

  if (authorMatches) {
    return {
      label: 'Author',
      priority: 5,
    };
  }

  return undefined;
}

function getGroups(filteredRecipes: SearchResult[]): SearchGroup[] {
  const groups = filteredRecipes.reduce((acc: { [key: string]: SearchResult[] }, curr) => {
    const isNumber = !isNaN(+(curr.title?.[0] ?? ''));
    const key = isNumber ? '#' : curr.group?.label ?? curr.title?.[0]?.toUpperCase() ?? '#';
    (acc[key] = acc[key] || []).push(curr);
    return acc;
  }, {});

  return Object.entries(groups).map(([key, value]) => ({ label: key, results: value }));
}

function getSecondaryText(matches: Match[]) {
  if (!matches.length) {
    return undefined;
  }

  return matches
    .map(match => `${match.label}: ${match.items.join(', ')}`)
    .join(', ');
}

export function stripDiacritics(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
