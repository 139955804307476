import { MealRepository } from '../meals/repository';
import { LocalDb, Stores } from '../shared/database';
import { EventBusProducer, Events } from '../shared/events';
import { FirestoreWorkspace } from './firestore';

export class WorkspaceRepository {
  constructor(
    private mealRepository: MealRepository,
    private producer: EventBusProducer,
    private db: Promise<LocalDb>,
  ) { }

  public async setWorkspace(workspace: FirestoreWorkspace) {
    const oldWorkspace =
      await this.get(workspace.id) ?? {
        id: '',
        name: '',
        mealPlanIds: [],
        leftovers: {},
      };

    const deletedMealIds = (oldWorkspace.mealPlanIds ?? []).filter(id => !workspace.mealPlanIds.includes(id));

    if (oldWorkspace.name !== workspace.name) {
      this.producer.emit(Events.WORKSPACE_CHANGED);
    }

    // TODO: Check if leftovers have changed.
    this.producer.emit(Events.LEFTOVERS_CHANGED);
    this.producer.emit(Events.STAPLES_CHANGED);

    await Promise.all([
      this.set(workspace),
      this.mealRepository.deleteLocal(deletedMealIds),
    ]);
  }

  public async get(id: string): Promise<FirestoreWorkspace | undefined> {
    return (await this.db).get(Stores.WORKSPACES, id);
  }

  private async set(workspace: FirestoreWorkspace): Promise<void> {
    await (await this.db).put(Stores.WORKSPACES, workspace, workspace.id);
  }
}