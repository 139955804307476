import { AstNode } from './ast';
import { GrammarToken, GrammarType, IntermediateType, NodeType, nodeOf } from './grammar';
import { Token, TokenType } from "./token";

export type ParserNode = ParserInternalNode | ParserLeafNode;

export interface ParserInternalNode {
  type: IntermediateType;
  children: ParserNode[];
  length: number;
}

export interface ParserLeafNode {
  type: TokenType;
  value: string;
  length: 1;
}

function map(node: ParserNode): AstNode {

  if ((node as ParserInternalNode).children) {
    const internalNode = node as ParserInternalNode;
    return { type: internalNode.type, children: internalNode.children.map(x => map(x)) };
  } else {
    const leafNode = node as ParserLeafNode;
    return { type: leafNode.type, value: leafNode.value };
  }
}

export const parse = (tokens: Token[]): AstNode | null => {
  const root = parseToken(tokens, 0, 'root');
  return root ? map(root) : null;
};

const parseToken = (tokens: Token[], current: number, grammarType: GrammarType): ParserNode | null => {
  const token = tokens[current];

  if (!token) {
    return null;
  }

  const [nodeType, requiredValue] = extractType(grammarType);
  const grammarNode = nodeOf(nodeType);

  const matchesType = token.type === nodeType || token.type === 'whitespace';
  const matchesValue = !requiredValue || requiredValue === token.value;

  if (matchesType && matchesValue) {
    return { type: nodeType as TokenType, value: token.value, length: 1 };
  }

  for (let i = 0; i < grammarNode.length; i++) {
    const grammarOption = grammarNode[i];
    const node: ParserInternalNode = { type: nodeType as IntermediateType, children: [], length: 0 };
    let valid = true;

    for (let j = 0; j < grammarOption.length; j++) {
      const grammarToken = grammarOption[j];
      const childNode = parseToken(tokens, current + node.length, grammarToken);

      if (!childNode) {
        valid = false;
        break;
      }

      node.length += childNode.length;
      node.children.push(childNode);
    }

    if (valid) {
      return node;
    }
  }

  return null;
};

function extractType(grammarType: string | GrammarToken): [NodeType, string | null] {
  return (typeof grammarType === 'string') ? [grammarType as NodeType, null] : grammarType;
}
